import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ChakraProvider,
  Flex,
  Icon,
  Spacer,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

function Delete({ isOpen, onClose, content, onDelete, type }) {
  const cancelRef = React.useRef();

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <ChakraProvider theme={theme}>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay bg="rgba(0,0,0,0.48)">
          <AlertDialogContent rounded="16px" shadow="md">
            <AlertDialogHeader fontWeight="bold">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                  overflow={'hidden'}
                >
                  Delete {content}
                </Text>
                <Spacer />
                <Box>
                  <Icon
                    as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
            </AlertDialogHeader>
            <AlertDialogBody
              color="gray.700"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
            >
              <Text>
                {type === 'knowledgeNugget' || type === 'resource'
                  ? `Are you sure? Please note that all files and notes associated with
              this item will also be deleted. You can’t undo this action
              afterwards`
                  : `Are you sure? You can’t undo this action afterwards`}
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                _focus={{ outline: 'none' }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                color="white"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
}

export default Delete;
