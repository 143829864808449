import {
  Box,
  ChakraProvider,
  Flex,
  Select,
  Spacer,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Projects from './Projects';

const ProjectFilter = ({
  isSearch,
  knowledgeNuggetData,
  knowledgeLibraryData,
  resourceData,
  nonsummaryhiveData,
  orgProjectData,
  projectData,
  upateprojectData,
  setUpdateprojectData,
  updatehivedata,
  setUpdatehivedata,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  userInfo,
}) => {
  const [filterProjectData, setFilterProjectData] = useState([]);

  const [filter, setFilter] = useState('Default');

  let projectDuplicate = [];

  function sortByName(a, b) {
    // for resource data
    if (
      a?.resource?.resource_name.toLowerCase() <
      b?.resource?.resource_name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.resource?.resource_name.toLowerCase() >
      b?.resource?.resource_name.toLowerCase()
    ) {
      return 1;
    }
    // for nonsummery data
    if (a?.filename?.toLowerCase() < b?.filename?.toLowerCase()) {
      return -1;
    }
    if (a?.filename?.toLowerCase() > b?.filename?.toLowerCase()) {
      return 1;
    }
    // for project data
    if (a?.project_name?.toLowerCase() < b?.project_name?.toLowerCase()) {
      return -1;
    }
    if (a?.project_name?.toLowerCase() > b?.project_name?.toLowerCase()) {
      return 1;
    }
    // for knowledge nugget data and knowledge filter Data
    if (
      a?.knowledge_nugget_name?.toLowerCase() <
      b?.knowledge_nugget_name?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.knowledge_nugget_name?.toLowerCase() >
      b?.knowledge_nugget_name?.toLowerCase()
    ) {
      return 1;
    }
    // else returen 0 for defaults
    return 0;
  }

  // function sortByTest(a, b) {
  //   if (
  //     a?.resource?.resource_name.toLowerCase() <
  //     b?.resource?.resource_name.toLowerCase()
  //   ) {
  //     return 1;
  //   }
  //   if (
  //     a?.resource?.resource_name.toLowerCase() >
  //     b?.resource?.resource_name.toLowerCase()
  //   ) {
  //     return -1;
  //   }
  //   // for nonsummery data
  //   if (a?.filename?.toLowerCase() < b?.filename?.toLowerCase()) {
  //     return 1;
  //   }
  //   if (a?.filename?.toLowerCase() > b?.filename?.toLowerCase()) {
  //     return -1;
  //   }
  //   // for project data
  //   if (a?.project_name?.toLowerCase() < b?.project_name?.toLowerCase()) {
  //     return 1;
  //   }
  //   if (a?.project_name?.toLowerCase() > b?.project_name?.toLowerCase()) {
  //     return -1;
  //   }
  //   // for knowledge nugget data and knowledge filter Data
  //   if (
  //     a?.knowledge_nugget_name?.toLowerCase() <
  //     b?.knowledge_nugget_name?.toLowerCase()
  //   ) {
  //     return 1;
  //   }
  //   if (
  //     a?.knowledge_nugget_name?.toLowerCase() >
  //     b?.knowledge_nugget_name?.toLowerCase()
  //   ) {
  //     return -1;
  //   }
  //   // else returen 0 for defaults
  //   return 0;
  // }

  return (
    <ChakraProvider theme={theme}>
      {projectData?.reverse()?.map(res => {
        projectDuplicate.push(res);

        return null;
      })}

      {/* {projectData?.length > 0 ? ( */}
      <Flex
        /*bg="#f0f4f8"*/ px="6"
        pt="2"
        borderTop="1px solid #E2E8F0"
        mt="10px"
      >
        <Spacer />
        <Box>
          <Stack isInline justify="space-between">
            <Box>
              <Text
                py="6px"
                px="4"
                color="gray.500"
                fontFamily="Inter"
                fontWeight="500"
                fontSize="16px"
                line-height="24px"
              >
                Sort by
              </Text>
            </Box>

            <Select
              onChange={event => {
                setFilter(event.target.value);
                switch (event.target.value) {
                  case 'Name':
                    projectDuplicate?.sort(sortByName);

                    break;
                  case 'Newest':
                    projectDuplicate?.reverse();

                    break;

                  case 'Ratings':
                    break;

                  default:
                    return projectData?.reverse();
                }

                setFilterProjectData(projectDuplicate);
              }}
              w="100.38px"
              color="gray.700"
              border="1px solid #E2E8F0"
              box-sizing="border-box"
              rounded="8px"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="14px"
            >
              <option value="Newest">Newest</option>
              <option value="Name">Name</option>
              {/* <option value="Ratings">Ratings</option> */}
            </Select>
          </Stack>
        </Box>
      </Flex>
      {/* ) : (
        ''
      )} */}

      {filter === 'Default' ? (
        <Box>
          <Projects
            projectData={projectData}
            orgProjectData={orgProjectData}
            resourceData={resourceData}
            knowledgeNuggetData={knowledgeNuggetData}
            knowledgeLibraryData={knowledgeLibraryData}
            nonsummaryhiveData={nonsummaryhiveData}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            upateprojectData={upateprojectData}
            setUpdateprojectData={setUpdateprojectData}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            userInfo={userInfo}
            isSearch={isSearch}
          />
        </Box>
      ) : (
        <Box>
          <Projects
            projectData={filterProjectData}
            orgProjectData={orgProjectData}
            resourceData={resourceData}
            knowledgeNuggetData={knowledgeNuggetData}
            knowledgeLibraryData={knowledgeLibraryData}
            nonsummaryhiveData={nonsummaryhiveData}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            upateprojectData={upateprojectData}
            setUpdateprojectData={setUpdateprojectData}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            userInfo={userInfo}
            isSearch={isSearch}
          />
        </Box>
      )}
    </ChakraProvider>
  );
};

export default ProjectFilter;
