import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Link as LinkDOM } from 'react-router-dom';
import EhLogo from '../Components/Logo/EhLogo';
import RegisterForm from '../Components/FormControllers/RegisterForm';
import Iubenda from 'react-iubenda-policy';

function SignUp() {
  const [update, setUpdate] = useState(false);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert &&  alertType === 'error') {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    } else if (alert &&  alertType === 'success') {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert,  alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2500);
  }, [update]);

  return (
    <>
      <Box
        maxH="100vh"
        h="100vh"
        overflow={'auto'}
        bg="gray.100"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
      >
        <Container
          maxW="lg"
          py={{ base: '0', sm: '2', md: '2', lg: '2', xl: '1' }}
          px={{ base: '0', sm: '8' }}
        >
          <Stack spacing={['0', '2', '2', '2']} mx={4}>
            <Stack spacing={['0', '1', '1', '1']}>
              <EhLogo />
              <Stack
                spacing={{ base: '0', sm: '2', md: '3' }}
                textAlign="center"
              >
                <Heading
                  size={useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' })}
                  fontSize={{ base: '24px', md: '36px' }}
                  color="gray.700"
                  fontWeight="bold"
                  mb={{ base: '4', sm: '0' }}
                >
                  Create account
                </Heading>
              </Stack>
            </Stack>
            <Box
              py={{
                base: '0',
                sm: '8',
                md: '8',
                lg: '4',
              }}
              px={{
                base: '6',
                sm: '10',
              }}
              bg="white"
              boxShadow={{
                base: 'none',
                sm: useColorModeValue('md', 'md-dark'),
              }}
              borderRadius={{
                base: 'md',
                sm: 'xl',
              }}
            >
              <Stack spacing={[0, 2, 2, 2, 3]}>
                <Stack spacing={[0, 2, 2, 2, 3]}>
                  <RegisterForm
                    onAlertHandle={alertHandler}
                    update={update}
                    setUpdate={setUpdate}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <HStack
            mt={[2, 3, 3, 3]}
            align="end"
            display={{ base: 'block', sm: 'flex' }}
            justify="center"
            textAlign="center"
          >
            <Text fontWeight="normal" color="gray.700" fontSize={['14px']}>
              Already Using Elephant Hive?{' '}
            </Text>
            <LinkDOM to="/">
              <Button variant="link" size="sm" color="black">
                <Text fontSize={['14px']} fontWeight="bold">
                  Log in
                </Text>
              </Button>
            </LinkDOM>
          </HStack>
          {/* terms and conditions and cookie policy */}
          <Flex
            direction={['column', 'column', 'row']}
            align={'center'}
            justify="center"
            gap={4}
            my={2}
          >
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="privacy"
                styling="nostyle"
              >
                <Text
                  fontSize={['14px']}
                  color="gray.500"
                  fontWeight="bold"
                  fontFamily="body"
                >
                  Privacy Policy
                </Text>
              </Iubenda>
            </Button>
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="terms-and-conditions"
                styling="nostyle"
              >
                <Text fontSize={['14px']} color="gray.500" fontWeight="bold">
                  Terms and Conditions
                </Text>
              </Iubenda>
            </Button>
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="cookie"
                styling="nostyle"
              >
                <Text
                  fontSize={['14px']}
                  color="gray.500"
                  fontWeight="bold"
                  fontFamily="body"
                >
                  Cookie Policy
                </Text>
              </Iubenda>
            </Button>
          </Flex>
        </Container>
      </Box>
    </>
  );
}

export default SignUp;
