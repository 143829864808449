import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, {
  /*useCallback,*/ useEffect,
  /*useMemo,*/ useState,
} from 'react';
import { BsPencil, BsSearch } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { VscArrowLeft } from 'react-icons/vsc';
import Header2 from '../Components/Header/Header2';
import Axios from 'axios';
import resourceicon from '../assests/Icons/Resource/BookBookmark.png';
import summarycover from '../assests/Elephant hive - Logo-Bottom Vector.png';
import Loading from '../Components/Loading/Loading';
import uploadicon from '../assests/Icons/nonsummary/Upload.png';
import ResourceCard from '../Components/Homepage/Components/ResourceCard';
import Delete from '../Components/LessonSummary/HeaderComps/Delete';
import axios from 'axios';
import ProjectNameedit from '../Components/Homepage/Components/ProjectComps/ProjectNameedit';
import { HiDotsVertical } from 'react-icons/hi';
import { differenceInDays } from 'date-fns';
import knowledgeNuggetIcon from '../assests/Icons/KnowledgeNugget/Hexagon.png';
import { useIntercom } from 'react-use-intercom';
import FolderIcon from '../assests/Icons/Project/Folder.png';

function ProjectViewer(props) {
  let itemId = props.match.params.id;
  const [storageUsed, setStorageUsed] = useState(0);
  const userLocalData = JSON.parse(localStorage.user);
  const [projectdata, setProjectData] = useState();
  const [haveprojdata, setHaveprojectdata] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [nonSummeryData, setNonsummerydata] = useState();
  const [resourceData, setResourceData] = useState();
  const [knowledgeNuggetData, setKnowledgeNuggetData] = useState();
  const [tagslist, setTaglist] = useState([]);
  const [searchvalue, setSearchValue] = useState('');
  const [isloading, setIsloading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [filterResourcedData, setFiltereResourcedData] = useState([]);
  const [filterNonsummerData, setFilterNonsummerData] = useState([]);
  const [filterKnowledgeNuggetData, setFilterKnowledgeNuggetData] = useState(
    []
  );
  const [filter, setFilter] = useState('Default');

  let date = new Date();
  var uniqueTags = [...new Set(tagslist)];

  // let resourceDuplicate = useMemo(() => {}, []);
  // let nonsummerDuplicate = useMemo(() => {}, []);
  // let knowledgeNuggetDuplicate = useMemo(() => {}, []);
  let resourceDuplicate = [];
  let nonsummerDuplicate = [];
  let knowledgeNuggetDuplicate = [];
  const { boot, shutdown } = useIntercom();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isOptionDrawerOpen,
    onOpen: onOptionDrawerOpen,
    onClose: onOptionDrawerClose,
  } = useDisclosure();
  const { REACT_APP_EH_BACKEND } = process.env;

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id, update]);

  useEffect(() => {
    // get project data in the first instance
    const { REACT_APP_EH_BACKEND } = process.env;
    let userLocalData = JSON.parse(localStorage.user);
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };
    Axios.get(
      `${REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}/${itemId}`,
      headers
    )
      .then(responseProject => {
        setProjectData(responseProject.data);
        setHaveprojectdata(true);
      })
      .catch(err => {
        return err;
      });
  }, [itemId, update]);

  // get knowledgge library array and resource array of the relevent user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/auth/${userLocalData._id}/info`,
    })
      .then(userResponse => {
        setUserInfo(userResponse.data);
      })
      .catch(error => {
        return error;
      });
  }, [userLocalData._id, update]);

  // when there are no elemets inside the project,
  // set the project data to null
  useEffect(() => {
    if (
      haveprojdata &&
      projectdata[0].selected_knowledeNuggets_ids.length === 0 &&
      projectdata[0].selected_nonsummary_ids.length === 0 &&
      projectdata[0].selected_resourse_ids.length === 0
    ) {
      setNonsummerydata();
      setResourceData();
      setKnowledgeNuggetData();
      setTaglist([]);
    }
  }, [haveprojdata, projectdata, update]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };
    const nonSummeryObj = {};
    const resourceObj = {};
    const knobj = {};
    // based on the reseived data fetch resource data and nonsummery data and knowledge knugget data from the stored
    // data in the database
    if (haveprojdata) {
      projectdata[0]?.selected_nonsummary_ids?.map((nonsummeryItem, index) => {
        nonSummeryObj[index] = nonsummeryItem;
        return null;
      });
      projectdata[0]?.selected_resourse_ids?.map((resourceItem, index) => {
        resourceObj[index] = resourceItem;
        return null;
      });
      projectdata[0]?.selected_knowledeNuggets_ids?.map((knItem, index) => {
        knobj[index] = knItem;
        return null;
      });
      const nonsummeryQueryString = Object.keys(nonSummeryObj)
        .map(key => '_id=' + nonSummeryObj[key])
        .join('&');
      const resourceQueryString = Object.keys(resourceObj)
        .map(key => '_id=' + resourceObj[key])
        .join('&');
      const knQueryString = Object.keys(knobj)
        .map(key => '_id=' + knobj[key])
        .join('&');

      if (
        nonsummeryQueryString !== '' ||
        resourceQueryString !== '' ||
        knQueryString !== ''
      ) {
        Axios.get(
          `${REACT_APP_EH_BACKEND}/getnonsummary?${nonsummeryQueryString}`,
          headers
        )
          .then(nonsummeryResponse => {
            setNonsummerydata(nonsummeryResponse.data);
          })
          .catch(err => {
            return err;
          });
        Axios.get(
          `${REACT_APP_EH_BACKEND}/resources?${resourceQueryString}`,
          headers
        )
          .then(resourceResponse => {
            setResourceData(resourceResponse.data);
          })
          .catch(err => {
            return err;
          });
        Axios.get(
          `${REACT_APP_EH_BACKEND}/knowledge/nuggets?${knQueryString}`,
          headers
        )
          .then(knResponse => {
            setKnowledgeNuggetData(knResponse.data);
          })
          .catch(err => {
            return err;
          });
      }
    }
  }, [haveprojdata, projectdata]);

  useEffect(() => {
    // after resiving resource data and nonsummery data from the database
    // filter resource and nonsummery tags and
    // stop the loading state
    if (resourceData || nonSummeryData || knowledgeNuggetData) {
      // filter resource tags
      nonSummeryData &&
        nonSummeryData?.map(nonsummeryItem => {
          nonsummeryItem.nonsummaryitem.tags.map(tagitem => {
            setTaglist(tags => [...tags, tagitem]);
            return null;
          });

          return null;
        });
      // filter nonsummery tags
      resourceData &&
        resourceData?.map(resourceItem => {
          resourceItem.resource.tags.map(tagitem => {
            setTaglist(tags => [...tags, tagitem.tag_name]);
            return null;
          });
          return null;
        });
      // filter kn tags
      knowledgeNuggetData &&
        knowledgeNuggetData?.map(knItem => {
          knItem?.knowledgeNugget?.tags?.map(tagitem => {
            setTaglist(tags => [...tags, tagitem?.tag_name]);
            return null;
          });
          return null;
        });
      setIsloading(false);
    } else {
      setIsloading(true);
    }
  }, [nonSummeryData, resourceData, knowledgeNuggetData]);

  // add empty message if project is created but elements are empty
  useEffect(() => {
    setTimeout(() => {
      if (
        haveprojdata === true &&
        nonSummeryData?.length === undefined &&
        resourceData?.length === undefined &&
        knowledgeNuggetData?.length === undefined
      ) {
        setIsloading(false);
      }
    }, 1000);
  }, [
    haveprojdata,
    knowledgeNuggetData?.length,
    nonSummeryData?.length,
    resourceData?.length,
    update,
  ]);

  // useCallback(() => {
  //   resourceData &&
  //     resourceData.map(res => {
  //       resourceDuplicate.push(res);

  //       return null;
  //     });
  //   nonSummeryData &&
  //     nonSummeryData?.map(res => {
  //       nonsummerDuplicate.push(res);

  //       return null;
  //     });
  //   knowledgeNuggetData &&
  //     knowledgeNuggetData?.map(res => {
  //       knowledgeNuggetDuplicate.push(res);

  //       return null;
  //     });
  // }, [
  //   knowledgeNuggetData,
  //   knowledgeNuggetDuplicate,
  //   nonSummeryData,
  //   nonsummerDuplicate,
  //   resourceData,
  //   resourceDuplicate,
  // ]);

  // onclick handler on nonsumery resource card
  const goTononsummary = async nonsummeryId => {
    props.history.push(`/nonsummary/${nonsummeryId}`);
  };
  // onclick handler on resource card
  const goToResourceOverview = resourceId => {
    if (resourceId !== null) {
      props.history.push(`/resource/overview?resource_id=${resourceId}`);
    }
  };
  // onclick handler on kn card
  const goToKnowledgeNugget = knId => {
    if (knId !== null) {
      props.history.push(`/knowledge/nugget?knowledge_nugget_id=${knId}`);
    }
  };
  // handledelete when delete key is pressed
  const handleDelete = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/projectdata/${itemId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          props.history.push('/homepage');
        }
      })
      .catch(error => {
        return error;
      });
  };

  function sortByName(a, b) {
    // for resource data
    if (
      a?.resource?.resource_name.toLowerCase() <
      b?.resource?.resource_name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.resource?.resource_name.toLowerCase() >
      b?.resource?.resource_name.toLowerCase()
    ) {
      return 1;
    }
    // for nonsummery data
    if (
      a?.nonsummaryitem?.filename?.toLowerCase() <
      b?.nonsummaryitem?.filename?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.nonsummaryitem?.filename?.toLowerCase() >
      b?.nonsummaryitem?.filename?.toLowerCase()
    ) {
      return 1;
    }
    // for knowledge nugget data
    if (
      a?.knowledgeNugget?.knowledge_nugget_name?.toLowerCase() <
      b?.knowledgeNugget?.knowledge_nugget_name?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.knowledgeNugget?.knowledge_nugget_name?.toLowerCase() >
      b?.knowledgeNugget?.knowledge_nugget_name?.toLowerCase()
    ) {
      return 1;
    }
    // else returen 0 for defaults
    return 0;
  }

  function sortByRatings(a, b) {
    let ratingA = 0;
    let ratingB = 0;
    // for resource data
    if (a?.resource?.ratings) {
      a.resource.rating.map(review => {
        ratingA += review.rating;

        return null;
      });
    }
    if (b?.resource?.ratings) {
      b.resource.rating.map(review => {
        ratingB += review.rating;

        return null;
      });
    }
    // for knowledge nugget data
    if (a?.knowledgeNugget?.ratings) {
      a?.knowledgeNugget?.ratings?.map(review => {
        ratingA += review.rating;

        return null;
      });
    }
    if (b?.knowledgeNugget?.ratings) {
      b?.knowledgeNugget?.ratings?.map(review => {
        ratingB += review.rating;

        return null;
      });
    }
    if (a?.resource?.ratings !== undefined) {
      ratingA /= a.resource.ratings.length;
      ratingB /= b.resource.ratings.length;
    }

    if (a?.knowledgeNugget?.ratings !== undefined) {
      ratingA /= a.knowledgeNugget.ratings.length;
      ratingB /= b.knowledgeNugget.ratings.length;
    }

    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  }

  resourceData?.map(res => {
    resourceDuplicate?.push(res);

    return null;
  });

  nonSummeryData?.map(res => {
    nonsummerDuplicate?.push(res);

    return null;
  });

  knowledgeNuggetData?.map(res => {
    knowledgeNuggetDuplicate?.push(res);

    return null;
  });

  return (
    <>
      {isloading ? (
        <Loading />
      ) : (
        <Box overflow={'hidden'} h="100vh">
          <Header2 storageUsed={storageUsed} />
          <Box
            overflowY={'auto'}
            scrollBehavior="auto"
            h={'calc(100vh - 72px)'}
          >
            <Flex
              border="1px"
              bgColor="gray.50"
              borderColor={'gray.200'}
              align="center"
              justify="space-between"
              flexDir="row"
            >
              <Box display="flex" alignItems="center" className="l-container">
                <Box ml="4" mr={3}>
                  <VscArrowLeft
                    size={24}
                    onClick={() => props.history.push(`/homepage`)}
                    style={{ color: '#718096', cursor: 'pointer' }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection={['column', 'column', 'column', 'row']}
                >
                  <Flex
                    bg="purple.100"
                    align="center"
                    justify="center"
                    rounded="15px"
                    p={2}
                    h="36px"
                    my={1}
                  >
                    <Image
                      w="20px"
                      h="20px"
                      src={FolderIcon}
                      alt="project icon"
                    />
                    <Text
                      fontWeight={'bold'}
                      fontFamily={'body'}
                      fontSize={{
                        base: 'sm',
                        md: 'md',
                        lg: 'lg',
                      }}
                      color="gray.900"
                      ml={2}
                    >
                      {projectdata[0].project_name}
                    </Text>
                  </Flex>
                  <Box display={['flex', 'flex', 'none', 'none']}>
                    <Text
                      fontSize={{ base: '12px', md: '16px' }}
                      fontWeight="normal"
                      fontFamily={'body'}
                      color="gray.600"
                    >
                      {`last updated: `}
                      <Text as={'span'} fontWeight="700" color="gray.900">
                        {new Date(projectdata[0].updated_at).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          }
                        )}
                      </Text>
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                mr="5"
                alignItems="center"
                className="r-container"
              >
                <Box display={['none', 'none', 'flex', 'flex']}>
                  <Text
                    fontSize="16px"
                    fontWeight="normal"
                    fontFamily={'body'}
                    color="gray.600"
                  >
                    {`last updated: `}
                    <Text as="span" fontWeight="700" color="gray.900">
                      &nbsp;{projectdata[0].updated_at.slice(0, 10)}
                    </Text>
                  </Text>
                </Box>
                <Box height={'50'} ml="5" color={'gray.200'}>
                  <Divider border="1px" orientation="vertical" />
                </Box>
                <Box display={['flex', 'flex', 'none']}>
                  <Button
                    variant={'outline'}
                    onClick={onOptionDrawerOpen}
                    ml="5"
                    px="2"
                    borderRadius={'md'}
                  >
                    <HiDotsVertical
                      size={24}
                      style={{ border: '1.5px', color: 'gray.500' }}
                    />
                  </Button>

                  <Drawer
                    isOpen={isOptionDrawerOpen}
                    onClose={onOptionDrawerClose}
                    placement="bottom"
                  >
                    <DrawerContent
                      marginBottom="0"
                      borderRadius="8px 8px 0px 0px"
                    >
                      <DrawerBody boxShadow="0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)">
                        <Flex
                          align="start"
                          flexDirection="column"
                          justify="center"
                        >
                          <Button
                            variant={'ghost'}
                            w="100%"
                            borderRadius="md"
                            onClick={onEditOpen}
                            cursor="pointer"
                            isDisabled={
                              userLocalData._id === projectdata[0].user_id
                                ? false
                                : true
                            }
                          >
                            <Flex
                              dir="row"
                              align="center"
                              width="full"
                              ml={2}
                              my={2}
                              py={2}
                              borderRadius="md"
                              _hover={{ bg: 'gray.400' }}
                              cursor="pointer"
                            >
                              <BsPencil
                                size={24}
                                style={{ color: '#718096', marginLeft: '10px' }}
                              />
                              <Text
                                ml={2}
                                fontWeight={'normal'}
                                fontSize="16px"
                                color="black"
                              >
                                Edit Project name
                              </Text>
                            </Flex>
                          </Button>
                          <Button
                            w="100%"
                            borderRadius="md"
                            onClick={onDeleteOpen}
                            cursor="pointer"
                          >
                            <Flex
                              dir="row"
                              align="center"
                              width="full"
                              ml={2}
                              my={2}
                              py={2}
                              borderRadius="md"
                              _hover={{ bg: 'gray.400' }}
                              cursor="pointer"
                            >
                              <RiDeleteBinLine
                                size={24}
                                style={{ color: 'red', marginLeft: '10px' }}
                              />
                              <Text
                                ml={2}
                                fontWeight={'normal'}
                                fontSize="16px"
                                color="black"
                              >
                                Delete Project
                              </Text>
                            </Flex>
                          </Button>
                        </Flex>
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </Box>
                <Box display={['none', 'none', 'flex']}>
                  {/* delete button */}
                  <Button
                    onClick={onDeleteOpen}
                    variant={'outline'}
                    ml="5"
                    borderRadius={'md'}
                  >
                    <RiDeleteBinLine style={{ color: 'red' }} />
                  </Button>
                  {onDeleteOpen && (
                    <Delete
                      isOpen={isDeleteOpen}
                      onClose={onDeleteClose}
                      content={projectdata[0]?.project_name}
                      onDelete={handleDelete}
                    />
                  )}
                  {/* edit name button */}
                  <Button
                    onClick={onEditOpen}
                    variant={'outline'}
                    ml="5"
                    mr="10px"
                  >
                    <Flex align={'center'} justify="center" flexDir={'row'}>
                      <BsPencil size={[18]} style={{ color: '#718096' }} />
                      <Text
                        display={['none', 'none', 'none', 'flex']}
                        ml={2}
                        fontWeight={'bold'}
                      >
                        Edit name
                      </Text>
                    </Flex>
                  </Button>
                  {onEditOpen && (
                    <ProjectNameedit
                      isOpen={isEditOpen}
                      onClose={onEditClose}
                      itemID={itemId}
                      setUpdate={setUpdate}
                      update={update}
                    />
                  )}
                </Box>
              </Box>
            </Flex>
            <Box>
              {/* search section and tags display */}
              <Flex
                mx={5}
                my={'4'}
                align={['start', 'start', 'start', 'center']}
                justify={['flex-start', 'flex-start', 'flex-start', 'start']}
                flexDir={['column', 'column', 'column', 'row']}
                overflow="hidden"
              >
                <Box mb={2} overflow="hidden">
                  <FormControl>
                    <InputGroup w={['full']} h="40px" mr={5}>
                      <Input
                        id="projsearch"
                        name="projsearch"
                        fontFamily={'body'}
                        autoComplete="off"
                        placeholder="Search in this Project"
                        type="text"
                        onChange={e => setSearchValue(e.target.value)}
                      />
                      <InputRightElement children={<BsSearch />} />
                    </InputGroup>
                  </FormControl>
                </Box>
                <Grid
                  display={['none', 'none', 'grid']}
                  alignItems={['start', 'center']}
                  templateColumns="repeat(6, 1fr)"
                  maxH={20}
                  overflow="auto"
                  mx={[0, 0, 0, 10]}
                >
                  {uniqueTags.map((tag, index) => (
                    <GridItem key={index} mr={5}>
                      <Badge
                        variant={'subtle'}
                        borderRadius="lg"
                        bgColor={'gray.500'}
                        mr="2"
                      >
                        <Text
                          fontFamily={'body'}
                          fontSize="14px"
                          color={'gray.100'}
                        >
                          {tag}
                        </Text>
                      </Badge>
                    </GridItem>
                  ))}
                </Grid>
              </Flex>
              <Flex
                mx={5}
                my={'1'}
                dir="row"
                justifyContent={['flex-end', 'space-between']}
                alignItems="center"
              >
                <Box display={['none', 'flex']}>
                  <Text
                    color="gray.600"
                    fontSize={{ base: '16px', md: '20px' }}
                    fontWeight="normal"
                  >
                    Project contents
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDir="row"
                >
                  <Text
                    py="6px"
                    px="4px"
                    color="gray.500"
                    fontFamily="body"
                    fontWeight="normal"
                    fontSize={{ base: '16px', sm: '14px', md: '16px' }}
                  >
                    Sort by
                  </Text>
                  <Select
                    onChange={event => {
                      setFilter(event.target.value);
                      switch (event.target.value) {
                        case 'Name':
                          resourceDuplicate.sort(sortByName);
                          nonsummerDuplicate.sort(sortByName);
                          knowledgeNuggetDuplicate.sort(sortByName);

                          break;

                        case 'Ratings':
                          resourceDuplicate.sort(sortByRatings);
                          knowledgeNuggetData.sort(sortByRatings);

                          break;

                        default:
                          return (
                            resourceData, nonSummeryData, knowledgeNuggetData
                          );
                      }
                      setFiltereResourcedData(resourceDuplicate);
                      setFilterNonsummerData(nonsummerDuplicate);
                      setFilterKnowledgeNuggetData(knowledgeNuggetDuplicate);
                    }}
                    w="100.38px"
                    color="gray.700"
                    border="1px solid #E2E8F0"
                    box-sizing="border-box"
                    rounded="8px"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                  >
                    <option value="Default">Default</option>
                    <option value="Name">Name</option>
                    <option value="Ratings">Ratings</option>
                  </Select>
                </Box>
              </Flex>
              <Box overflow="hidden">
                {/* search value section */}
                {searchvalue && filter === 'Default' ? (
                  <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    mx={5}
                    // spacingX="2"
                    // spacingY="2"
                    gap={['8', '8', '8']}
                  >
                    {resourceData &&
                      resourceData
                        ?.filter(item => {
                          if (
                            item.resource.resource_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="resource"
                              id={item.resource._id}
                              projecId={itemId}
                              elementDataRes={item.resource}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              resourceData={resourceData}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={resourceicon}
                              ResourceCover={summarycover}
                              textLine={item.resource.resource_name}
                              ratings={item.resource.rating}
                              reviews={item.resource.rating.length}
                              daysAgo={differenceInDays(
                                new Date(date),
                                new Date(
                                  userInfo?.user_RES_Lib?.find(
                                    element => element?.id === item?.resource?._id
                                  )?.accessdate
                                )
                              )}
                              dayAdded={differenceInDays(
                                new Date(date),
                                new Date(
                                  userInfo?.user_RES_Lib?.find(
                                    element => element?.id === item?.resource?._id
                                  )?.created_at
                                )
                              )}
                              handleProceed={() =>
                                goToResourceOverview(item.resource._id)
                              }
                            />
                          </Box>
                        ))}
                    {nonSummeryData &&
                      nonSummeryData
                        ?.filter(item => {
                          if (
                            item.nonsummaryitem.filename
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="nonsummary"
                              id={item.nonsummaryitem._id}
                              projecId={itemId}
                              elementDataNon={item.nonsummaryitem}
                              nonsummaryhiveData={nonSummeryData}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={uploadicon}
                              ResourceCover={summarycover}
                              textLine={item.nonsummaryitem.filename}
                              daysAgo={differenceInDays(
                                new Date(date),
                                new Date(item.nonsummaryitem.accessdate)
                              )}
                              dayAdded={differenceInDays(
                                new Date(date),
                                new Date(item.nonsummaryitem.created_at)
                              )}
                              handleProceed={() =>
                                goTononsummary(item.nonsummaryitem._id)
                              }
                            />
                          </Box>
                        ))}
                    {knowledgeNuggetData &&
                      knowledgeNuggetData
                        ?.filter(item => {
                          if (
                            item.knowledgeNugget.knowledge_nugget_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="knowledgeNugget"
                              id={item.knowledgeNugget._id}
                              projecId={itemId}
                              elementDataKn={item.knowledgeNugget}
                              knowledgeNuggetData={knowledgeNuggetData}
                              updateKnowledgeNuggetData={update}
                              setUpdateKnowledgeNuggetData={setUpdate}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={knowledgeNuggetIcon}
                              ResourceCover={summarycover}
                              textLine={
                                item.knowledgeNugget.knowledge_nugget_name
                              }
                              daysAgo={
                                userInfo?.user_KN_Lib.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.accessdate
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.accessdate)
                                    )
                              }
                              dayAdded={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.created_at
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.created_at)
                                    )
                              }
                              ratings={item.knowledgeNugget.ratings}
                              handleProceed={() =>
                                goToKnowledgeNugget(item.knowledgeNugget._id)
                              }
                            />
                          </Box>
                        ))}
                  </SimpleGrid>
                ) : searchvalue && filter !== 'Default' ? (
                  <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    mx={5}
                    // spacingX="2"
                    // spacingY="2"
                    gap={['8', '8', '16']}
                  >
                    {resourceData &&
                      filterResourcedData
                        ?.filter(item => {
                          if (
                            item.resource.resource_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="resource"
                              id={item.resource._id}
                              projecId={itemId}
                              elementDataRes={item.resource}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              resourceData={resourceData}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={resourceicon}
                              ResourceCover={summarycover}
                              textLine={item.resource.resource_name}
                              ratings={item.resource.rating}
                              reviews={item.resource.rating.length}
                              daysAgo={differenceInDays(
                                new Date(date),
                                new Date(
                                  userInfo?.user_RES_Lib.find(
                                    element => element?.id === item?.resource?._id
                                  )?.accessdate
                                )
                              )}
                              dayAdded={differenceInDays(
                                new Date(date),
                                new Date(
                                  userInfo?.user_RES_Lib?.find(
                                    element => element?.id === item?.resource?._id
                                  )?.created_at
                                )
                              )}
                              handleProceed={() =>
                                goToResourceOverview(item.resource._id)
                              }
                            />
                          </Box>
                        ))}
                    {nonSummeryData &&
                      filterNonsummerData
                        ?.filter(item => {
                          if (
                            item.nonsummaryitem.filename
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="nonsummary"
                              id={item.nonsummaryitem._id}
                              projecId={itemId}
                              elementDataNon={item.nonsummaryitem}
                              nonsummaryhiveData={nonSummeryData}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={uploadicon}
                              ResourceCover={summarycover}
                              textLine={item.nonsummaryitem.filename}
                              daysAgo={differenceInDays(
                                new Date(date),
                                new Date(item.nonsummaryitem.accessdate)
                              )}
                              dayAdded={differenceInDays(
                                new Date(date),
                                new Date(item.nonsummaryitem.created_at)
                              )}
                              handleProceed={() =>
                                goTononsummary(item.nonsummaryitem._id)
                              }
                            />
                          </Box>
                        ))}
                    {knowledgeNuggetData &&
                      filterKnowledgeNuggetData
                        ?.filter(item => {
                          if (
                            item.knowledgeNugget.knowledge_nugget_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="knowledgeNugget"
                              id={item.knowledgeNugget._id}
                              projecId={itemId}
                              elementDataKn={item.knowledgeNugget}
                              knowledgeNuggetData={knowledgeNuggetData}
                              updateKnowledgeNuggetData={update}
                              setUpdateKnowledgeNuggetData={setUpdate}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={knowledgeNuggetIcon}
                              ResourceCover={summarycover}
                              textLine={
                                item.knowledgeNugget.knowledge_nugget_name
                              }
                              daysAgo={
                                userInfo?.user_KN_Lib.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.accessdate
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.accessdate)
                                    )
                              }
                              dayAdded={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.created_at
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.created_at)
                                    )
                              }
                              ratings={item.knowledgeNugget.ratings}
                              handleProceed={() =>
                                goToKnowledgeNugget(item.knowledgeNugget._id)
                              }
                            />
                          </Box>
                        ))}
                  </SimpleGrid>
                ) : filter === 'Default' ? (
                  <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    mx={5}
                    gap={['2', '2', '8']}
                  >
                    {resourceData &&
                      resourceData.map((resourceItem, index) => (
                        <Box key={index}>
                          <ResourceCard
                            type="resource"
                            id={resourceItem.resource._id}
                            projecId={itemId}
                            elementDataRes={resourceItem.resource}
                            updatehivedata={update}
                            setUpdatehivedata={setUpdate}
                            resourceData={resourceData}
                            iconBg="white"
                            isproject={true}
                            IconAndcolor={resourceicon}
                            ResourceCover={summarycover}
                            textLine={resourceItem.resource.resource_name}
                            ratings={resourceItem.resource.rating}
                            reviews={resourceItem.resource.rating.length}
                            daysAgo={differenceInDays(
                              new Date(date),
                              new Date(
                                userInfo?.user_RES_Lib?.find(
                                  element =>
                                    element?.id === resourceItem?.resource?._id
                                )?.accessdate
                              )
                            )}
                            dayAdded={differenceInDays(
                              new Date(date),
                              new Date(
                                userInfo?.user_RES_Lib?.find(
                                  element =>
                                    element?.id === resourceItem?.resource?._id
                                )?.created_at
                              )
                            )}
                            handleProceed={() =>
                              goToResourceOverview(resourceItem.resource._id)
                            }
                          />
                        </Box>
                      ))}
                    {nonSummeryData &&
                      nonSummeryData.map((nonsummeryItem, index) => (
                        <Box key={index}>
                          <ResourceCard
                            type="nonsummary"
                            id={nonsummeryItem.nonsummaryitem._id}
                            projecId={itemId}
                            elementDataNon={nonsummeryItem.nonsummaryitem}
                            nonsummaryhiveData={nonSummeryData}
                            updatehivedata={update}
                            setUpdatehivedata={setUpdate}
                            iconBg="white"
                            IconAndcolor={uploadicon}
                            ResourceCover={summarycover}
                            isproject={true}
                            textLine={nonsummeryItem.nonsummaryitem.filename}
                            daysAgo={differenceInDays(
                              new Date(date),
                              new Date(nonsummeryItem.nonsummaryitem.accessdate)
                            )}
                            dayAdded={differenceInDays(
                              new Date(date),
                              new Date(nonsummeryItem.nonsummaryitem.created_at)
                            )}
                            handleProceed={() =>
                              goTononsummary(nonsummeryItem.nonsummaryitem._id)
                            }
                          />
                        </Box>
                      ))}
                    {knowledgeNuggetData &&
                      knowledgeNuggetData
                        ?.filter(item => {
                          if (
                            item.knowledgeNugget.knowledge_nugget_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="knowledgeNugget"
                              id={item.knowledgeNugget._id}
                              projecId={itemId}
                              elementDataKn={item.knowledgeNugget}
                              knowledgeNuggetData={knowledgeNuggetData}
                              updateKnowledgeNuggetData={update}
                              setUpdateKnowledgeNuggetData={setUpdate}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={knowledgeNuggetIcon}
                              ResourceCover={summarycover}
                              textLine={
                                item.knowledgeNugget.knowledge_nugget_name
                              }
                              daysAgo={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.accessdate
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.accessdate)
                                    )
                              }
                              dayAdded={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.created_at
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.created_at)
                                    )
                              }
                              ratings={item.knowledgeNugget.ratings}
                              handleProceed={() =>
                                goToKnowledgeNugget(item.knowledgeNugget._id)
                              }
                            />
                          </Box>
                        ))}
                  </SimpleGrid>
                ) : (
                  <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    mx={5}
                    // spacingX="2"
                    // spacingY="2"
                    gap={['8', '8', '16']}
                  >
                    {filterResourcedData &&
                      filterResourcedData.map((resourceItem, index) => (
                        <Box key={index}>
                          <ResourceCard
                            type="resource"
                            id={resourceItem.resource._id}
                            projecId={itemId}
                            elementDataRes={resourceItem.resource}
                            updatehivedata={update}
                            setUpdatehivedata={setUpdate}
                            resourceData={resourceData}
                            iconBg="white"
                            isproject={true}
                            IconAndcolor={resourceicon}
                            ResourceCover={summarycover}
                            textLine={resourceItem.resource.resource_name}
                            ratings={resourceItem.resource.rating}
                            reviews={resourceItem.resource.rating.length}
                            daysAgo={differenceInDays(
                              new Date(date),
                              new Date(
                                userInfo?.user_RES_Lib?.find(
                                  element =>
                                    element?.id === resourceItem?.resource?._id
                                )?.accessdate
                              )
                            )}
                            dayAdded={differenceInDays(
                              new Date(date),
                              new Date(
                                userInfo?.user_RES_Lib?.find(
                                  element =>
                                    element?.id === resourceItem?.resource?._id
                                )?.created_at
                              )
                            )}
                            handleProceed={() =>
                              goToResourceOverview(resourceItem.resource._id)
                            }
                          />
                        </Box>
                      ))}
                    {filterNonsummerData &&
                      filterNonsummerData.map((nonsummeryItem, index) => (
                        <Box key={index}>
                          <ResourceCard
                            type="nonsummary"
                            id={nonsummeryItem.nonsummaryitem._id}
                            projecId={itemId}
                            elementDataNon={nonsummeryItem.nonsummaryitem}
                            nonsummaryhiveData={nonSummeryData}
                            updatehivedata={update}
                            setUpdatehivedata={setUpdate}
                            iconBg="white"
                            IconAndcolor={uploadicon}
                            ResourceCover={summarycover}
                            isproject={true}
                            textLine={nonsummeryItem.nonsummaryitem.filename}
                            daysAgo={differenceInDays(
                              new Date(date),
                              new Date(nonsummeryItem.nonsummaryitem.accessdate)
                            )}
                            dayAdded={differenceInDays(
                              new Date(date),
                              new Date(nonsummeryItem.nonsummaryitem.created_at)
                            )}
                            handleProceed={() =>
                              goTononsummary(nonsummeryItem.nonsummaryitem._id)
                            }
                          />
                        </Box>
                      ))}
                    {knowledgeNuggetData &&
                      filterKnowledgeNuggetData
                        ?.filter(item => {
                          if (
                            item.knowledgeNugget.knowledge_nugget_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase())
                          ) {
                            return item;
                          } else {
                            return null;
                          }
                        })
                        .map((item, index) => (
                          <Box key={index}>
                            <ResourceCard
                              type="knowledgeNugget"
                              id={item.knowledgeNugget._id}
                              projecId={itemId}
                              elementDataKn={item.knowledgeNugget}
                              knowledgeNuggetData={knowledgeNuggetData}
                              updateKnowledgeNuggetData={update}
                              setUpdateKnowledgeNuggetData={setUpdate}
                              updatehivedata={update}
                              setUpdatehivedata={setUpdate}
                              iconBg="white"
                              isproject={true}
                              IconAndcolor={knowledgeNuggetIcon}
                              ResourceCover={summarycover}
                              textLine={
                                item.knowledgeNugget.knowledge_nugget_name
                              }
                              daysAgo={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element.id === item.knowledgeNugget._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.accessdate
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.accessdate)
                                    )
                              }
                              dayAdded={
                                userInfo?.user_KN_Lib?.find(
                                  element =>
                                    element?.id === item?.knowledgeNugget?._id
                                )
                                  ? differenceInDays(
                                      new Date(date),
                                      new Date(
                                        userInfo?.user_KN_Lib?.find(
                                          element =>
                                            element?.id ===
                                            item?.knowledgeNugget?._id
                                        )?.created_at
                                      )
                                    )
                                  : differenceInDays(
                                      new Date(date),
                                      new Date(item.knowledgeNugget.created_at)
                                    )
                              }
                              ratings={item.knowledgeNugget.ratings}
                              handleProceed={() =>
                                goToKnowledgeNugget(item.knowledgeNugget._id)
                              }
                            />
                          </Box>
                        ))}
                  </SimpleGrid>
                )}
                {(haveprojdata === true &&
                  projectdata[0].selected_knowledeNuggets_ids.length === 0 &&
                  projectdata[0].selected_nonsummary_ids.length === 0 &&
                  projectdata[0].selected_resourse_ids.length === 0) ||
                (haveprojdata === true &&
                  nonSummeryData?.length === undefined &&
                  resourceData?.length === undefined &&
                  knowledgeNuggetData?.length === undefined) ? (
                  <Flex align="center" justify="center">
                    <Text
                      my={32}
                      fontSize="4xl"
                      color={'gray.500'}
                      fontFamily="body"
                    >
                      Project is Empty
                    </Text>
                  </Flex>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default ProjectViewer;
