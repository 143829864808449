import {
  Badge,
  Box,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Input,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

function InputTagReccurent({
  setFieldValue, // function to set the value of the field
  handleChange, // function to handle the change of the field
  handleBlur, // function to handle the blur of the field
  values, // the values of the form on tags
  errors, // the errors of the form on tags
  suggestionTag, //suggested tag when click on the value in suggested tags array
  setSuggestionTag, // function to set the value of the suggested tag
  update, //boolean property that updated when form submitted
}) {
  const [tags, setTags] = useState(values);
  const isError = tags.find(elem => elem.length > 50) ? true : false;
  const [validate, setValidate] = useState(false);

  const removeTag = index => {
    setTags(tags.filter((_, i) => i !== index));
    setFieldValue('tags', [...tags.filter((_, i) => i !== index)]);
  };

  const addTag = event => {
    if (event.target.value !== '' && event.key === ',') {
      if (
        tags.find(
          element =>
            element.toLowerCase() ===
            event.target.value.slice(0, -1).toLowerCase()
        )
      ) {
        setValidate(true);
      } else {
        setValidate(false);
        setTags([...tags, event.target.value.slice(0, -1)]);
        setFieldValue('tags', [...tags, event.target.value.slice(0, -1)]);
      }

      event.target.value = '';
    }
    if (event.target.value !== '' && event.key === 'Enter') {
      if (
        tags.find(
          element => element.toLowerCase() === event.target.value.toLowerCase()
        )
      ) {
        setValidate(true);
      } else {
        setValidate(false);
        setTags([...tags, event.target.value]);
        setFieldValue('tags', [...tags, event.target.value]);
      }

      event.target.value = '';
    }
  };

  useEffect(() => {
    setTags(values);
    setFieldValue('tags', values);
  }, [setFieldValue, values]);

  // inserted new tags when suggested tags are clicked
  useEffect(() => {
    if (suggestionTag) {
      if (tags.find(element => element === suggestionTag)) {
        setValidate(true);
      } else {
        setValidate(false);
        setTags([...tags, suggestionTag]);
        setFieldValue('tags', [...tags, suggestionTag]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionTag]);

  return (
    <Tooltip label="Type the tag and Press , or Enter" placement="top">
      <FormControl isInvalid={isError}>
        <Box
          display={'flex'}
          border="1px"
          borderColor={'gray.200'}
          borderRadius={'md'}
          padding={'12px'}
          overflowX="auto"
        >
          <Grid
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gap={2}
          >
            {tags &&
              tags.map((tag, index) => (
                <GridItem key={index}>
                  <Badge
                    variant="solid"
                    border="1px solid #6B46C1"
                    bgColor={'purple.600'}
                    borderColor={'purple.600'}
                    display={'flex'}
                    borderRadius="full"
                    justifyContent="space-evenly"
                    alignItems="center"
                    px={2}
                  >
                    <Text
                      fontFamily={'body'}
                      fontWeight="normal"
                      fontSize={'14px'}
                    >
                      {tag}
                    </Text>
                    <CloseButton onClick={() => removeTag(index)} />
                  </Badge>
                </GridItem>
              ))}
          </Grid>

          <Input
            w={'full'}
            type="text"
            name="tags"
            id="tags"
            variant={'unstyled'}
            placeholder="Add Tag"
            _placeholder={{ fontsize: 'md' }}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyUp={event =>
              event.key === ',' || event.key === 'Enter'
                ? addTag(event) && event.preventDefault()
                : null
            }
          />
        </Box>
        <FormHelperText>
          Click on suggested tag or type the tag and press , or Enter
        </FormHelperText>
        {validate && (
          <Text color={'red'} my="2" fontSize="10" fontFamily={'body'}>
            Tag already in Hive, please add a new one
          </Text>
        )}
        {isError && (
          <FormErrorMessage>Tag length is too much.</FormErrorMessage>
        )}
      </FormControl>
    </Tooltip>
  );
}

export default InputTagReccurent;
