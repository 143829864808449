import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Link as LinkDOM } from 'react-router-dom';
import elephantHiveLogo from '../../assests/Elephant_hive_Logo1.png';

function EhLogo({ boxSize }) {
  return (
    <LinkDOM to="/">
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDir="column"
        overflow="hidden"
      >
        <Image
          boxSize={boxSize}
          objectFit="contain"
          src={elephantHiveLogo}
          alt="ElephantHive"
          w={['57px', '85.33px']}
          h={['40px', '60px']}
        />
      </Box>
    </LinkDOM>
  );
}

export default EhLogo;
