export const login = (token_key) => {
    localStorage.setItem("login_token", token_key);
}

export const user = (user_data) => {
    localStorage.setItem("user", JSON.stringify(user_data));
}

export const logout = () => {
    localStorage.removeItem("login_token");
    localStorage.removeItem("user")
}

export const isLogin = () => {
    if (localStorage.getItem("login_token")) {
        return true;
    }

    return false;
}

export const exportRefValue = (ref) => {
    localStorage.setItem("ref", ref)
}
