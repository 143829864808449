import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraProvider,
  Flex,
  Text,
  Link,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerOverlay,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { ImFileText2 } from 'react-icons/im';
import { CgListTree } from 'react-icons/cg';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { ImAttachment } from 'react-icons/im';
import { BiNote } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ModuleContext } from '../../../Context/ModuleContext';
import UploadFiles from '../HeaderComps/LessonSummaryUpContainer/UploadFiles';
import UploadedDrawer from '../HeaderComps/UploadedDrawer';
import UploadedDrawerWithFiles from '../HeaderComps/UploadedDrawerWithFiles';
import Notification from '../HeaderComps/Notification';
import AddNotesDrawer from '../HeaderComps/AddNotesDrawer';
import ListTab from './ListTab';
import ListNMapTab from './ListNMapTab';
import extendTheme from '../../../extendTheme';
import MapTab from './MapTab';
import axios from 'axios';
import {
  AlignmentType,
  BorderStyle,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
} from 'docx';
import { saveAs } from 'file-saver';
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';
import { TbGridDots } from 'react-icons/tb';

const TabController = ({
  lesson,
  resourceId,
  resourceName,
  moduleId,
  moduleName,
  lessonId,
  lessonCode,
  lessonName,
  lessonCount,
  onPreviousLesson,
  onNextLesson,
  update,
  setUpdate,
  refresh,
  setRefresh,
}) => {
  let userLocalData = JSON.parse(localStorage.user);
  const resourceIdVal = resourceId;
  const lessonData = lesson;
  const [noteCount, setNoteCount] = useState(0);
  const [mdLeftValZoom, setMdLeftZoom] = useState('305px');
  const [lgLeftValZoom, setLgLeftZoom] = useState('688px');
  const [moduleData, setModuleData] = useState();
  const [moduleArr, setModuleArr] = useState();
  const [haveUploadedFiles, setHaveUploadedFiles] = useState(false);
  const [numOfUploadedFiles, setNumOfUploadedFiles] = useState(0);
  const [pauseState, setPauseState] = useState('');
  const moduleContextData = useContext(ModuleContext);
  const userId = userLocalData._id;
  const { REACT_APP_EH_BACKEND } = process.env;

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isAddNotesOpen,
    onOpen: onAddNotesOpen,
    onClose: onAddNotesClose,
  } = useDisclosure();
  const {
    isOpen: isBottomDrawerOpen,
    onOpen: onBottomDrawerOpen,
    onClose: onBottomDrawerClose,
  } = useDisclosure();

  useEffect(() => {
    setModuleData(moduleContextData);
  }, [moduleContextData]);

  const exportRef = useRef();
  let history = useHistory();
  const modules = [];

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/resources/${resourceIdVal}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        const resourceData = resourceResponse.data[0];
        const moduleObj = {};

        resourceData.module_id.map((module_data, index) => {
          moduleObj[index] = module_data.id;
          return null;
        });

        const queryString = Object.keys(moduleObj)
          .map(key => '_id=' + moduleObj[key])
          .join('&');
        axios({
          method: 'GET',
          url: `${REACT_APP_EH_BACKEND}/modules?${queryString}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        })
          .then(moduleResponse => {
            const moduleData = moduleResponse.data;
            modules.splice(0, modules.length);
            moduleData.map(modu => {
              modu.module['lessons'] = modu.module['lesson'];
              delete modu.module['lesson'];
              modules.push(modu.module);
              return null;
            });
            setModuleArr(modules);
          })
          .catch(loginError => {
            return loginError;
          });
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, update]);

  useEffect(() => {
    moduleData?.map(module_data => {
      module_data.module.lesson.map(lesson_data => {
        if (lesson_data.code === lessonCode) {
          if (lesson_data?.uploadFiles?.length > 0) {
            lesson_data.uploadFiles?.map(upload_files => {
              if (upload_files.user_id === userId) {
                setHaveUploadedFiles(true);
              }
              return null;
            });
          }
        }
        return null;
      });
      return null;
    });
  }, [moduleData, userId, lessonCode, numOfUploadedFiles, refresh]);

  // get the uploaded file count
  useEffect(() => {
    let totalNumOfUploadedFiles = 0;
    moduleArr?.map(module_data => {
      if (module_data._id === moduleId) {
        module_data.lessons?.map(lesson_data => {
          if (lesson_data.id === lessonId) {
            lesson_data.uploadFiles?.map(file_data => {
              if (file_data.user_id === userId) {
                totalNumOfUploadedFiles += 1;
              }

              return null;
            });
          }

          return null;
        });
      }

      return null;
    });
    setNumOfUploadedFiles(totalNumOfUploadedFiles);
  }, [
    moduleArr,
    userId,
    lessonId,
    moduleId,
    numOfUploadedFiles,
    refresh,
    lessonCode,
  ]);

  //get users note count
  useEffect(() => {
    moduleArr?.map(module_data => {
      if (module_data._id === moduleId) {
        module_data.lessons.map(lesson_data => {
          if (lesson_data.code === lessonCode) {
            lesson_data.notes.length > 0
              ? lesson_data.notes
                  .filter(lesson_element => {
                    if (lesson_element.userId === userLocalData._id) {
                      return lesson_element;
                    } else if (lesson_element === []) {
                      // empty notes sections need to be counted as 0
                      setNoteCount(0);
                      return null;
                    } else {
                      return null;
                    }
                  })
                  .map((_, index) => {
                    setNoteCount((index += 1));
                    return null;
                  })
              : setNoteCount(0);
          }

          return null;
        });
      }

      return null;
    });
  }, [lessonCode, moduleArr, moduleId, userLocalData._id, refresh]);

  const goToResource = () => {
    history.push(`/resource/overview?resource_id=${resourceIdVal}`);
  };

  const goToPrevious = () => {
    const currentLessonNumber = Number(lessonId.replace(/^\D+/g, ''));
    const currentModuleIndex = moduleArr.findIndex(x => x._id === moduleId);
    if (currentLessonNumber !== 1) {
      let previousLessonId = 1;
      previousLessonId = currentLessonNumber - 1;
      let newlessonId = `lesson${previousLessonId}`;
      history.push(
        `/resource/overview/lessonsummary?resource_id=${resourceIdVal}&resource_name=${resourceName}&module_id=${moduleId}&module_name=${moduleName}&lesson_id=${newlessonId}&lesson_code=${lessonCode}&lesson_name=${lessonName}&lesson_count=${lessonCount}`
      );
      onPreviousLesson(previousLessonId);
    } else if (currentLessonNumber === 1) {
      let previousModuleIndex;
      if (currentModuleIndex === 0) {
        previousModuleIndex = moduleArr?.length - 1;
      } else {
        previousModuleIndex = currentModuleIndex - 1;
      }
      moduleId = moduleArr[previousModuleIndex]._id;
      moduleName = moduleArr[previousModuleIndex].module_name;
      let newlessonId =
        moduleArr[previousModuleIndex].lessons[
          moduleArr[previousModuleIndex].lessons.length - 1
        ].id;
      lessonCode =
        moduleArr[previousModuleIndex].lessons[
          moduleArr[previousModuleIndex].lessons.length - 1
        ].code;
      lessonCount = moduleArr[previousModuleIndex].lessons.length;
      history.push(
        `/resource/overview/lessonsummary?resource_id=${resourceIdVal}&resource_name=${resourceName}&module_id=${moduleId}&module_name=${moduleName}&lesson_id=${newlessonId}&lesson_code=${lessonCode}&lesson_name=${lessonName}&lesson_count=${lessonCount}`
      );
      onPreviousLesson(newlessonId);
    }
    setRefresh(!refresh);
  };

  const goToNext = () => {
    const currentLessonNumber = Number(lessonId.replace(/^\D+/g, ''));
    const currentModuleIndex = moduleArr.findIndex(x => x._id === moduleId);
    if (currentLessonNumber < lessonCount) {
      let nextLessonId = lessonCount;
      nextLessonId = currentLessonNumber + 1;
      let newlessonId = `lesson${nextLessonId}`;
      history.push(
        `/resource/overview/lessonsummary?resource_id=${resourceIdVal}&resource_name=${resourceName}&module_id=${moduleId}&module_name=${moduleName}&lesson_id=${newlessonId}&lesson_code=${lessonCode}&lesson_name=${lessonName}&lesson_count=${lessonCount}`
      );
      onNextLesson(newlessonId);
    } else if (currentLessonNumber === Number(lessonCount)) {
      let nextModuleIndex;
      if (currentModuleIndex < moduleArr?.length - 1) {
        nextModuleIndex = currentModuleIndex + 1;
      } else {
        nextModuleIndex = 0;
      }
      moduleId = moduleArr[nextModuleIndex]._id;
      moduleName = moduleArr[nextModuleIndex].module_name;
      let newlessonId = moduleArr[nextModuleIndex].lessons[0].id;
      lessonCode = moduleArr[nextModuleIndex].lessons[0].code;
      lessonCount = moduleArr[nextModuleIndex].lessons.length;
      history.push(
        `/resource/overview/lessonsummary?resource_id=${resourceIdVal}&resource_name=${resourceName}&module_id=${moduleId}&module_name=${moduleName}&lesson_id=${newlessonId}&lesson_code=${lessonCode}&lesson_name=${lessonName}&lesson_count=${lessonCount}`
      );
      onNextLesson(newlessonId);
    }
    setRefresh(!refresh);
  };

  const exportPDF = async values => {
    const summary = [];
    let lessonCode = '';
    let lessonName = '';
    values?.map(lesson_data => {
      lesson_data.module.lesson.map(lesson_in_data => {
        lessonCode = lesson_in_data.code;
        lessonName = lesson_in_data.name;
        summary.splice(0, summary.length);
        lesson_in_data.initialNodes.map(initial_nodes_data => {
          if (initial_nodes_data.className !== 'main-node') {
            summary.push(initial_nodes_data?.data?.label);
          }

          return null;
        });

        return null;
      });

      return null;
    });
    // create document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: `${lessonCode} - ${lessonName}`,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
            }),
            ...summary.map(summery_data => {
              return new Paragraph({
                text: summery_data,
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.START,
                bullet: {
                  level: 1,
                },
                border: {
                  bottom: {
                    color: '#000000',
                    width: 1,
                    style: BorderStyle.SINGLE,
                  },
                },
                children: [
                  new TextRun({
                    break: 1,
                  }),
                ],
              });
            }),
          ],
        },
      ],
    });
    await Packer.toBlob(doc)
      .then(blob => {
        saveAs(blob, `${lessonCode} - ${lessonName}.docx`);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <ChakraProvider theme={extendTheme}>
      <Flex
        width="100%"
        px="4"
        justifyContent="space-between"
        borderBottom="1px solid #E2E8F0"
        position="sticky"
        color="gray.500"
      >
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          h={{ lg: '70px' }}
        >
          <Breadcrumb
            fontFamily="Inter"
            fontStyle=" normal"
            fontSize={{ sm: '14px', md: '12px', lg: '16px' }}
          >
            <Flex
              py="2"
              flexDirection="row"
              justifyContent={{
                sm: 'space-between',
                md: 'left',
                lg: 'center',
              }}
              display={{ base: 'none', md: 'flex', lg: 'flex' }}
            >
              <BreadcrumbItem mr="8px">
                <Tooltip label={resourceName}>
                  <BreadcrumbLink
                    _focus={{
                      outline: 'none',
                    }}
                    as={Link}
                    to="#"
                    fontWeight="700"
                    onClick={() => goToResource()}
                  >
                    {resourceName.length > 15
                      ? `${resourceName.slice(
                          0,
                          -(resourceName.length - 12)
                        )}...`
                      : resourceName}
                    <Icon as={AiOutlineRight} />
                  </BreadcrumbLink>
                </Tooltip>
              </BreadcrumbItem>

              <BreadcrumbItem mr="8px">
                <Tooltip label={moduleName}>
                  <Text>
                    {moduleName.length > 15
                      ? `${moduleName.slice(0, -(moduleName.length - 12))}...`
                      : moduleName}
                  </Text>
                </Tooltip>
                <Icon as={AiOutlineRight} color="gray.500" />
              </BreadcrumbItem>
              <BreadcrumbItem mr="8px" color="gray.800" fontWeight="700">
                <Text
                  _focus={{
                    outline: 'none',
                  }}
                >
                  {lessonCode + ' '}
                  <Tooltip label={lessonName}>
                    {lessonName.length > 15
                      ? `${lessonName.slice(0, -(lessonName.length - 12))}...`
                      : lessonName}
                  </Tooltip>
                </Text>
              </BreadcrumbItem>
              <BreadcrumbItem />
            </Flex>
          </Breadcrumb>
          <Flex
            align={'center'}
            justify={'space-between'}
            py="2"
            px="0"
            mt="4px"
            borderRadius="5px"
            display={{ base: 'flex', md: 'none', lg: 'none' }}
          >
            <Flex
              align={'center'}
              justify="center"
              onClick={() => goToResource()}
            >
              <Icon
                as={AiOutlineLeft}
                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                ml="-2px"
              />

              <Text fontFamily={'body'} fontSize="14px">
                {resourceName}
              </Text>
            </Flex>
            <Icon as={TbGridDots} onClick={onBottomDrawerOpen} />
          </Flex>
          <Flex
            pr={{ base: '0', md: '2' }}
            // py="2"
            justifyContent="space-between"
          >
            <Box mt={{ lg: '-37px' }} cursor={'pointer'}>
              <Flex align={'center'}>
                <Icon
                  as={AiOutlineArrowLeft}
                  ml={{ base: 0, md: '-2px' }}
                  mt={'6px'}
                  fontSize="16px"
                />

                <Text
                  pt="1"
                  pl="10.5px"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize={{ base: '10px', md: '12px', lg: '16px' }}
                  onClick={() => goToPrevious()}
                >
                  Previous lesson
                </Text>
              </Flex>
            </Box>
            <Box mt={{ lg: '-37px' }} cursor={'pointer'}>
              <Flex align={'center'}>
                <Text
                  pt="1"
                  pr="10.5px"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize={{ base: '10px', md: '12px', lg: '16px' }}
                  onClick={() => goToNext()}
                >
                  Next lesson
                </Text>
                <Icon
                  as={AiOutlineArrowRight}
                  mr={{ base: 0, md: '-2px' }}
                  mt={'6px'}
                  fontSize="16px"
                />
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'flex', lg: 'flex' }}
          pl="4"
          pt="14px"
          pb="18px"
          borderLeft="1px solid #E2E8F0"
        >
          <Flex>
            {numOfUploadedFiles !== 0 ? (
              <Box mt="1" p="2" border="1px solid #E2E8F0" mr="4" rounded="5px">
                {numOfUploadedFiles !== 0 ? (
                  <Notification py="2" value={numOfUploadedFiles} />
                ) : (
                  ''
                )}
                <ImAttachment cursor="pointer" onClick={onEditOpen} />
              </Box>
            ) : (
              <Box mt="1" p="2" border="1px solid #E2E8F0" mr="4" rounded="5px">
                {/* <Notification value="1" /> */}
                <ImAttachment cursor="pointer" onClick={onUploadOpen} />
              </Box>
            )}
            {noteCount > 0 ? (
              <Box mt="1" p="2" border="1px solid #E2E8F0" mr="4" rounded="5px">
                <Notification value={noteCount} />
                <BiNote cursor="pointer" onClick={onAddNotesOpen} />
              </Box>
            ) : (
              <Box mt="1" p="2" border="1px solid #E2E8F0" mr="4" rounded="5px">
                <BiNote cursor="pointer" onClick={onAddNotesOpen} />
              </Box>
            )}
            <Box
              cursor="pointer"
              mt="1"
              p="2"
              border="1px solid #E2E8F0"
              rounded="5px"
            >
              <FiDownload onClick={() => exportPDF(lessonData)} />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <AddNotesDrawer
        lesson={lessonData}
        isOpen={isAddNotesOpen}
        onClose={onAddNotesClose}
        update={update}
        setUpdate={setUpdate}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      {haveUploadedFiles ? (
        <UploadedDrawerWithFiles
          resourceId={resourceId}
          refresh={refresh}
          setRefresh={setRefresh}
          isOpen={isEditOpen}
          onClose={onEditClose}
          isUploadOpen={isUploadOpen}
          onUploadClose={onUploadClose}
          onUploadOpen={onUploadOpen}
        />
      ) : (
        <>
          <UploadFiles
            resourceId={resourceId}
            refresh={refresh}
            setRefresh={setRefresh}
            isOpen={isUploadOpen}
            onClose={onUploadClose}
            lesId={lessonId}
            modId={moduleId}
          />
          <UploadedDrawer
            resourceId={resourceId}
            refresh={refresh}
            setRefresh={setRefresh}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        </>
      )}
      <Drawer
        placement={'bottom'}
        onClose={onBottomDrawerClose}
        isOpen={isBottomDrawerOpen}
      >
        <DrawerOverlay />
        <DrawerContent h="28vh" borderRadius="10px">
          <DrawerBody p="5">
            {haveUploadedFiles ? (
              <Flex align="center" cursor="pointer" onClick={onEditOpen}>
                <ImAttachment />
                <Text
                  p="2"
                  pl="3"
                  color="gray.600"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="16px"
                >
                  Uploads
                </Text>
              </Flex>
            ) : (
              <Flex align="center" cursor="pointer" onClick={onUploadOpen}>
                <ImAttachment />
                <Text
                  p="2"
                  pl="3"
                  color="gray.600"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="16px"
                >
                  Uploads
                </Text>
              </Flex>
            )}
            <Flex align="center" cursor="pointer" onClick={onAddNotesOpen}>
              <BiNote />
              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Notes
              </Text>
            </Flex>
            <Flex
              align="center"
              cursor="pointer"
              onClick={() => exportPDF(lessonData)}
            >
              <FiDownload />
              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Download
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box>
        <Tabs variant="soft-rounded" defaultIndex={1}>
          <TabList
            display={{ base: 'none', md: 'flex', lg: 'flex' }}
            style={{ position: 'fixed', zIndex: 3 }}
            p="0"
            mt="4"
            right={4}
            bg="#e2e8f0"
            rounded="full"
          >
            <Tab
              m="0"
              py={{ md: '2', lg: '3' }}
              px={{ md: '4', lg: '7' }}
              onClick={() => {
                setPauseState('file');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <ImFileText2 size="18" color="#718096" />
            </Tab>
            <Tab
              m="0"
              py={{ md: '2', lg: '3' }}
              px={{ md: '4', lg: '7' }}
              onClick={() => {
                setLgLeftZoom('700px');
                setMdLeftZoom('305px');
                setPauseState('list');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <CgArrowsShrinkH size="18" color="#718096" />
            </Tab>
            <Tab
              m="0"
              py={{ md: '2', lg: '3' }}
              px={{ md: '4', lg: '7' }}
              onClick={() => {
                setLgLeftZoom('238px');
                setMdLeftZoom('-5px');
                setPauseState('network');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <CgListTree size="18" color="#718096" />
            </Tab>
          </TabList>
          <TabPanels ref={exportRef} h="75vh" p="0">
            <TabPanel p="0" m="0">
              <ListTab lesson={lessonData} pauseStateVal={pauseState} />
            </TabPanel>
            <TabPanel p="0" m="0">
              <ListNMapTab
                mdLeftVal={mdLeftValZoom}
                lgLeftVal={lgLeftValZoom}
                lesson={lessonData}
                pauseStateVal={pauseState}
              />
            </TabPanel>
            <TabPanel p="0" m="0">
              <MapTab
                mdLeftVal={mdLeftValZoom}
                lgLeftVal={lgLeftValZoom}
                lesson={lessonData}
                pauseStateVal={pauseState}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ChakraProvider>
  );
};

export default TabController;
