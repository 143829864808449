import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  HStack,
  Icon,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';

function RatingModel({ isOpen, onClose, resourceUpdate, setResourceUpdate }) {
  // use state for set the clicked ratings
  const [ratedValue, setValue] = useState(0);
  // use state for set the hovering rating
  const [hover, setHover] = useState(-1);
  // use state to set the error for the ratings if any
  const [ratingErr, setRatingErr] = useState('');
  const [update, setUpdate] = useState(false);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertDescription, setAlertDescription] = useState('');
  const location = useLocation();
  const { REACT_APP_EH_BACKEND } = process.env;
  const resourceId = new URLSearchParams(location.search).get('resource_id');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  const labels = {
    1: 'Very poor',
    2: 'Poor',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  // handle toast respond resived from backend
  const alertHandler = ({ status, message, description }) => {
    setAlert(message);
    setAlertType(status);
    setAlertDescription(description);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (
      alert &&
      alertDescription &&
      alertType === 'success' &&
      toast.isActive
    ) {
      toast({
        position: toastposition,
        title: alert,
        description: alertDescription,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertDescription, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
      setAlertDescription('');
    }, 2000);
  }, [update]);

  const handleClick = async () => {
    // authenticate the input fields and pass the data to the database, closing the popup box

    // Submit the rating
    const userData = JSON.parse(localStorage.user);
    await axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/resources/${resourceId}/${userData._id}/${ratedValue}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(ratingResponse => {
        if (ratingResponse.data.status) {
          alertHandler({
            status: ratingResponse.data.status,
            message: ratingResponse.data.message,
            description: ratingResponse.data.description,
          });
        }
      })
      .catch(loginError => {
        return loginError;
      });

    setValue(0);
    setHover(-1);
    setRatingErr('');
    setUpdate(!update);
    setResourceUpdate(!resourceUpdate);
    onClose();
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        setRatingErr('');
      }}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,0.48)" />
      <ModalContent
        color="black"
        bg="white"
        overflow="auto"
        w="448px"
        px="6"
        py="4"
        rounded="16px"
        boxShadow="md"
      >
        <ModalHeader p="0" m="0">
          <Flex>
            <Text
              py="1"
              color="gray.900"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fontSize="20px"
            >
              Rate this Resource
            </Text>
            <Spacer />
            <Box py="7px" px="3">
              <Icon
                as={AiOutlineClose}
                fontSize="14px"
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  onClose();
                  setRatingErr('');
                }}
              />
            </Box>
          </Flex>
        </ModalHeader>
        <ModalBody p="0" m="0">
          <Box align="center" pt="30px">
            <Text
              pb="3"
              color="gray.600"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              h="30px"
            >
              {/* update labal text onsreen */}
              {labels[ratedValue !== null && hover !== -1 ? hover : ratedValue]}
            </Text>
            <Center /*pb="46px"*/>
              <Flex align="center">
                <Text
                  w="18px"
                  color="black"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="24px"
                >
                  {/* update rated value as a countabe value */}
                  {ratedValue !== null && hover !== -1 ? hover : ratedValue}
                </Text>

                <Stack spacing="4" pl="3">
                  <HStack
                    spacing="1"
                    onMouseLeave={() => {
                      if (ratedValue === 0) {
                        setRatingErr('Click a star');
                      }
                    }}
                  >
                    {Array.from({
                      length: 5,
                    })
                      .map((_, index) => index + 1)
                      .map(index => (
                        <Icon
                          key={index}
                          as={FaStar}
                          cursor="pointer"
                          transition="0.09s"
                          fontSize="25px"
                          color={index <= hover ? 'yellow.400' : 'gray.200'}
                          onClick={() => {
                            setValue(index);
                            setRatingErr('');
                          }}
                          onMouseOver={() => {
                            setHover(index);
                          }}
                          onMouseLeave={() => {
                            setHover(ratedValue);
                          }}
                        />
                      ))}
                  </HStack>
                </Stack>
              </Flex>
            </Center>
            <Text
              h="24px"
              color="red.500"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
            >
              {ratingErr}
            </Text>
          </Box>
          <Center pt="18px">
            <Button
              onClick={handleClick}
              disabled={ratedValue === 0 ? true : false}
              bg="yellow.400"
              rounded="4px"
              px="4"
              py="2"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fontSize="16px"
              _hover={{ bg: 'yellow.500' }}
              _active={{
                bg: 'yellow.500',
                transform: 'scale(0.98)',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              Add Rating
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RatingModel;
