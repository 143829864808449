import { Box, ChakraProvider, SimpleGrid, Text, theme } from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import differenceInDays from 'date-fns/differenceInDays';
import summarycover from '../../../assests/Logos/PannelBLogo.png';
import uploadicon from '../../../assests/Icons/nonsummary/Upload.png';
import knowledgeNuggetIcon from '../../../assests/Icons/KnowledgeNugget/Hexagon.png';
import ResourceCard from './ResourceCard';
import ResourceCover1 from '../../../assests/Logos/PannelBLogo.png';
import { useHistory } from 'react-router-dom';
import ResourceIcon from '../../../assests/Icons/Resource/BookBookmark.png';

function Resources({
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  orgProjectData,
  projectData,
  upateprojectData,
  setUpdateprojectData,
  updatehivedata,
  setUpdatehivedata,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  userInfo,
}) {
  let history = useHistory();
  const [date, setDate] = useState(new Date());
  const userLocalData = JSON.parse(localStorage.getItem('user'));

  const goToResourceOverview = async resourceId => {
    if (resourceId !== null) {
      // to get the last access time of the resource send request to backend so it will give an dateproperty
      const { REACT_APP_EH_BACKEND } = process.env;

      await axios({
        method: 'PUT',
        url: `${REACT_APP_EH_BACKEND}/resource/updateDate/${
          userLocalData._id
        }/${resourceId}/${date.toLocaleDateString()}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(dateResponse => {
          setDate(dateResponse.data.accessdate);
        })
        .catch(err => {
          return err;
        });
      history.push(`/resource/overview?resource_id=${resourceId}`);
    }
  };

  const goTononsummary = async nonSummaryId => {
    // to get the last access time of the resource send request to backend so it will give an dateproperty
    const { REACT_APP_EH_BACKEND } = process.env;

    // to get the last access time of the resource send request to backend so it will give an dateproperty
    await axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivedate/${nonSummaryId}/${date.toLocaleDateString()}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(dateResponse => {
        setDate(dateResponse.data.accessdate);
      })
      .catch(err => {
        return err;
      });
    history.push(`/nonsummary/${nonSummaryId}`);
  };

  const goToKnowledgeNugget = async knowledgeNuggetId => {
    // to get the last access time of the resource send request to backend so it will give an dateproperty
    const { REACT_APP_EH_BACKEND } = process.env;

    await axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/updateDate/${
        userLocalData._id
      }/${knowledgeNuggetId}/${date.toLocaleDateString()}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(dateResponse => {
        setDate(dateResponse.data.accessdate);
      })
      .catch(err => {
        return err;
      });
    history.push(`/knowledge/nugget?knowledge_nugget_id=${knowledgeNuggetId}`);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box px="4" /*bg="#f0f4f8"*/>
        <Text
          pb="2"
          color="gray.600"
          fontFamily="Inter"
          fontWeight="400"
          fontSize={['16px', '16px', '20px']}
        >
          My Knowledge
        </Text>
        <SimpleGrid
          columns={[2, 3, 3, 4]}
          justifyContent="space-between"
          spacingY="29px"
          gap={['2', '2', '8']}
        >
          {knowledgeNuggetData &&
            knowledgeNuggetData?.map((knowledge_nugget_data, index) => (
              <Box key={index}>
                <ResourceCard
                  type="knowledgeNugget"
                  id={knowledge_nugget_data._id}
                  orgProjectData={orgProjectData}
                  projectData={projectData}
                  resourceData={resourceData}
                  knowledgeNuggetData={knowledgeNuggetData}
                  elementDataKn={knowledge_nugget_data}
                  upateprojectData={upateprojectData}
                  setUpdateprojectData={setUpdateprojectData}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                  iconBg="white"
                  IconAndcolor={knowledgeNuggetIcon}
                  ResourceCover={summarycover}
                  textLine={knowledge_nugget_data.knowledge_nugget_name}
                  daysAgo={differenceInDays(
                    new Date(date),
                    new Date(knowledge_nugget_data.accessdate)
                  )}
                  dayAdded={differenceInDays(
                    new Date(date),
                    new Date(knowledge_nugget_data.created_at)
                  )}
                  ratings={knowledge_nugget_data.ratings}
                  handleProceed={() =>
                    goToKnowledgeNugget(knowledge_nugget_data._id)
                  }
                />
              </Box>
            ))}
          {knowledgeLibraryData &&
            knowledgeLibraryData?.map((knowledge_nugget_data, index) => (
              <Box key={index}>
                <ResourceCard
                  type="knowledgeNugget"
                  id={knowledge_nugget_data._id}
                  orgProjectData={orgProjectData}
                  projectData={projectData}
                  resourceData={resourceData}
                  knowledgeNuggetData={knowledgeNuggetData}
                  elementDataKn={knowledge_nugget_data}
                  upateprojectData={upateprojectData}
                  setUpdateprojectData={setUpdateprojectData}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                  iconBg="white"
                  IconAndcolor={knowledgeNuggetIcon}
                  ResourceCover={summarycover}
                  textLine={knowledge_nugget_data.knowledge_nugget_name}
                  daysAgo={differenceInDays(
                    new Date(date),
                    new Date(
                      userInfo?.user_KN_Lib?.find(
                        KNelement => KNelement.id === knowledge_nugget_data._id
                      )?.accessdate
                    )
                  )}
                  dayAdded={differenceInDays(
                    new Date(date),
                    new Date(
                      userInfo?.user_KN_Lib?.find(
                        KNelement => KNelement.id === knowledge_nugget_data._id
                      )?.created_at
                    )
                  )}
                  ratings={knowledge_nugget_data.ratings}
                  handleProceed={() =>
                    goToKnowledgeNugget(knowledge_nugget_data._id)
                  }
                />
              </Box>
            ))}
          {resourceData &&
            resourceData?.map((resource_data, index) => (
              <Box key={index}>
                <ResourceCard
                  type="resource"
                  id={resource_data.resource._id}
                  orgProjectData={orgProjectData}
                  projectData={projectData}
                  resourceData={resourceData}
                  elementDataRes={resource_data.resource}
                  nonsummaryhiveData={nonsummaryhiveData}
                  upateprojectData={upateprojectData}
                  setUpdateprojectData={setUpdateprojectData}
                  updatehivedata={updatehivedata}
                  setUpdatehivedata={setUpdatehivedata}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                  iconBg="white"
                  IconAndcolor={ResourceIcon}
                  ResourceCover={ResourceCover1}
                  textLine={resource_data.resource.resource_name}
                  daysAgo={differenceInDays(
                    new Date(date),
                    new Date(
                      userInfo?.user_RES_Lib.find(
                        KNelement => KNelement.id === resource_data.resource._id
                      )?.accessdate
                    )
                  )}
                  dayAdded={differenceInDays(
                    new Date(date),
                    new Date(
                      userInfo?.user_RES_Lib.find(
                        KNelement => KNelement.id === resource_data.resource._id
                      )?.created_at
                    )
                  )}
                  ratings={resource_data.resource.rating}
                  reviews={resource_data.resource.rating.length}
                  handleProceed={() =>
                    goToResourceOverview(resource_data.resource._id)
                  }
                />
              </Box>
            ))}
          {/* update on non summary data items */}
          {nonsummaryhiveData &&
            nonsummaryhiveData?.map((nonsummary_data, index) => (
              <Box key={index}>
                <ResourceCard
                  type="nonsummary"
                  id={nonsummary_data._id}
                  orgProjectData={orgProjectData}
                  projectData={projectData}
                  resourceData={resourceData}
                  nonsummaryhiveData={nonsummaryhiveData}
                  elementDataNon={nonsummary_data}
                  upateprojectData={upateprojectData}
                  setUpdateprojectData={setUpdateprojectData}
                  updatehivedata={updatehivedata}
                  setUpdatehivedata={setUpdatehivedata}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                  iconBg="white"
                  IconAndcolor={uploadicon}
                  ResourceCover={summarycover}
                  textLine={nonsummary_data.filename}
                  daysAgo={differenceInDays(
                    new Date(date),
                    new Date(nonsummary_data.accessdate)
                  )}
                  dayAdded={differenceInDays(
                    new Date(date),
                    new Date(nonsummary_data.created_at)
                  )}
                  handleProceed={() => goTononsummary(nonsummary_data._id)}
                />
              </Box>
            ))}
        </SimpleGrid>
      </Box>
    </ChakraProvider>
  );
}

export default Resources;
