import {
  Box,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import LogoB from '../../assests/Logos/PannelBLogo.png';
import Banner from './Components/Banner';
import ResourceFilter from './Components/ResourceFilter';
import SearchResultBanner from './Components/SearchResultBanner';
import NoSearchResult from './Components/NoSearchResult';
import Header2 from '../Header/Header2';
import gtag from 'ga-gtag';
import { useEffect } from 'react';
import axios from 'axios';
import ProjectFilter from './Components/ProjectFilter';

const ReturningUser = ({
  resourceData,
  onClaimResource,
  moduleData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  nonsummaryhiveData,
  updatehivedata,
  setUpdatehivedata,
  haveResourceData,
  haveNonsummeryData,
  haveKnowledgeNuggetData,
  haveProjectdata,
  projectData,
  upateprojectData,
  setUpdateprojectData,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  storageUsed,
  startTour,
  userInfo,
}) => {
  const [isSearch, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchTypeTxt, setSearchTypeTxt] = useState('');
  const [searchCourseResults, setSearchCourseResults] = useState([]);
  const [searchNonSummeryResult, setSearchNonSummeryResult] = useState([]);
  const [searchProjectResult, setSearchProjectResult] = useState([]);
  const [searchKNResult, setSearchKNResult] = useState([]);
  const [searchKNLibrary, setSearchKNLibrary] = useState([]);
  const [siblings, setSiblings] = useState([]);
  const [show, setShow] = useState(false);
  const [allResources, setAllResources] = useState();
  const [allKNuggets, setAllKNuggets] = useState();
  const [allNonSummaries, setAllNonSummaries] = useState();
  const [allMatObj, setAllMatObj] = useState();
  const [projectTagObj, setProjectTagObj] = useState();
  const tags = [];
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
  let userLocalData = JSON.parse(localStorage.user);

  useEffect(() => {
    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
  }, [REACT_APP_GA_MEASUREMENT_ID]);

  // pass the course value to parent page (homepage)
  const handleClaimResourceSubmit = values => {
    onClaimResource(values);
  };

  function phaseSearch() {
    setSearchTypeTxt(
      'Showing projects, summaries and other files containing the searched phrase'
    );
    if (searchValue !== '') {
      setSearch(true);
    } else {
      setSearch(false);
    }

    if (searchValue.trim().toLowerCase() !== '') {
      gtag('event', 'phrase_search', {
        search_value: searchValue.trim(),
        userid: `${userLocalData._id}`,
        full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
        verified: `${userLocalData.isVerified}`,
      });
      /* for normal name search */
      // search on course data
      const searchCourseResult = resourceData?.filter(res => {
        return res.resource.resource_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });

      // search on non summary data and get the final result for non summary data
      const searchNonsummeryResult = nonsummaryhiveData?.filter(res => {
        return res?.filename
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });

      // search on project data get the final result for project data
      const projectResult = projectData?.filter(res => {
        return res?.project_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });

      // search on knowledge nugget data get the final result for knowledge nugget data
      const knowledgeNuggetResult = knowledgeNuggetData?.filter(res => {
        return res?.knowledge_nugget_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });

      // search on knowledge library data get the final result for knowledge library data
      const knowledgeLibraryResult = knowledgeLibraryData?.filter(res => {
        return res?.knowledge_nugget_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });

      // search on course module data and return the course id
      const preModuleResult = moduleData?.filter(modu => {
        return modu.module.module_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase());
      });
      const postModuleResult = preModuleResult?.map(preModu => {
        return preModu.module.resource_id;
      });
      // search on course module lesson data and return the course id
      const preLessonResult = moduleData?.filter(modu => {
        return (
          modu.module.lesson.filter(les => {
            return les.name
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase());
          }).length > 0
        );
      });
      const postLessonResult = preLessonResult?.map(preLes => {
        return preLes.module.resource_id;
      });
      // combile the course module and course lesson results
      const searchModuleResult = resourceData?.filter(res => {
        return [...postModuleResult, ...postLessonResult].includes(
          res.resource._id
        );
      });
      // combine and get the final result for course data
      const fnlSearchCoursePhraseResult = [
        ...new Map(
          [...searchCourseResult, ...searchModuleResult].map(item => [
            item.resource['_id'],
            item,
          ])
        ).values(),
      ];
      /* end of normal name phrase search */

      /////////////////////////////////////////////////////////////////

      /* tag searching for phrase search */
      // search on course data
      const tag = searchValue.trim().toLowerCase();
      const searchCourseTagResult = resourceData?.filter(res => {
        return (
          res.resource.tags.filter(item => {
            return item.tag_name.toLowerCase() === tag;
          }).length > 0
        );
      });

      // search on non summary data and get the final result for non summary data
      const searchNonSummeryTagResult = nonsummaryhiveData?.filter(res => {
        return (
          res?.tags.filter(item => {
            return item.toLowerCase() === tag;
          }).length > 0
        );
      });

      // search on knowledge nugget data and get the final result for non summary data
      const searchKnowwledgeNuggetTagResult = knowledgeNuggetData?.filter(
        res => {
          return (
            res?.tags.filter(item => {
              return item.tag_name.toLowerCase() === tag;
            }).length > 0
          );
        }
      );

      // search on knowledge library data get the final result for knowledge library data
      const searchKnowwledgeLibraryTagResult = knowledgeLibraryData?.filter(
        res => {
          return (
            res?.tags.filter(item => {
              return item.tag_name.toLowerCase() === tag;
            }).length > 0
          );
        }
      );

      // search on course module data and return the course id
      const preModuleTagResult = moduleData?.filter(modu => {
        return (
          modu.module.lesson.filter(les => {
            return (
              les.tags.filter(item => {
                return item.tag_name.toLowerCase() === tag;
              }).length > 0
            );
          }).length > 0
        );
      });
      const postModuleTagResult = preModuleTagResult?.map(preModu => {
        return preModu.module.resource_id;
      });
      const searchModuleTagResult = resourceData?.filter(res => {
        return [...new Set(postModuleTagResult)].includes(res.resource._id);
      });
      // combine and get the final result for course data
      const fnlSearchCourseTagResult = [
        ...new Map(
          [...searchCourseTagResult, ...searchModuleTagResult].map(item => [
            item.resource['_id'],
            item,
          ])
        ).values(),
      ];

      // set the final distinct phrase and tag search result of course data
      setSearchCourseResults([
        ...new Map(
          [...fnlSearchCoursePhraseResult, ...fnlSearchCourseTagResult].map(
            item => [item.resource['_id'], item]
          )
        ).values(),
      ]);
      // set the final distinct phrase and tag search result of non summary data
      setSearchNonSummeryResult([
        ...new Map(
          [...searchNonsummeryResult, ...searchNonSummeryTagResult].map(
            item => [item['_id'], item]
          )
        ).values(),
      ]);
      // set the final phrase search result of project data (since it doesnt contain tags)
      setSearchProjectResult(projectResult);
      // set the final phrase search result of knowledge nugget data (since it doesnt contain tags)
      setSearchKNResult([
        ...new Map(
          [...knowledgeNuggetResult, ...searchKnowwledgeNuggetTagResult].map(
            item => [item['_id'], item]
          )
        ).values(),
      ]);
      // set the final distinct phrase and tag search result of knowledge library  data
      setSearchKNLibrary([
        ...new Map(
          [...knowledgeLibraryResult, ...searchKnowwledgeLibraryTagResult].map(
            item => [item['_id'], item]
          )
        ).values(),
      ]);
      /* end of tag phrase search */
    } else {
      setSearchCourseResults([]);
      setSearchNonSummeryResult([]);
      setSearchProjectResult([]);
      setSearchKNResult([]);
      setSearchKNLibrary([]);
    }
  }

  // get all the data ids of all projects
  useEffect(() => {
    const allRes = [];
    const allKN = [];
    const allNonS = [];
    projectData?.map(project => {
      project?.selected_resourse_ids?.forEach(element => {
        allRes.push(element);
      });
      project?.selected_knowledeNuggets_ids?.forEach(element => {
        allKN.push(element);
      });
      project?.selected_nonsummary_ids?.forEach(element => {
        allNonS.push(element);
      });
      return null;
    });

    setAllMatObj({
      allResIds: [...new Set(allRes)],
      allKNIds: [...new Set(allKN)],
      allNonSIds: [...new Set(allNonS)],
    });
  }, [projectData]);

  // make quaries with all res, kn and nons ids
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;

    const resQueryString = allMatObj?.allResIds
      ?.map(id => '_id=' + id)
      .join('&');

    const kNQueryString = allMatObj?.allKNIds?.map(id => '_id=' + id).join('&');

    const nonSQqueryString = allMatObj?.allNonSIds
      ?.map(id => '_id=' + id)
      .join('&');

    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/resources/spec?${resQueryString}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(allResourcesResponse => {
        setAllResources(allResourcesResponse.data);
      })
      .catch(loginError => {
        return loginError;
      });

    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?${kNQueryString}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(allKnowledgeNuggetResponse => {
        setAllKNuggets(allKnowledgeNuggetResponse.data);
      })
      .catch(loginError => {
        return loginError;
      });

    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhive/hive/spec?${nonSQqueryString}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(allNonSummaryResponce => {
        setAllNonSummaries(allNonSummaryResponce.data);
      })
      .catch(loginError => {
        return loginError;
      });
  }, [allMatObj]);

  const getResourceTags = (resId, allResources) => {
    const tags = [];
    const res = allResources?.find(res => res?.resource?._id === resId);
    res?.resource?.tags?.map(tagObj => {
      tags.push(tagObj?.tag_name);
      return null;
    });
    return tags;
  };

  const getKnowledgeNuggetTags = (knId, allKNuggets) => {
    const tags = [];
    const kn = allKNuggets?.find(kn => kn?._id === knId);
    kn?.tags?.map(tagObj => {
      tags.push(tagObj?.tag_name);
      return null;
    });
    return tags;
  };

  const getNonSummaryTags = (nonSId, allKNonSummaries) => {
    const tags = [];
    const nonS = allKNonSummaries?.find(nonS => nonS?._id === nonSId);
    nonS?.tags?.map(tag => {
      tags.push(tag);
      return null;
    });
    return tags;
  };

  // create the prooject object with all project ids and their tags for a user
  useEffect(() => {
    const projTagObj = {};
    projectData?.map(project => {
      project?.selected_resourse_ids?.map(resId => {
        if (projTagObj[project?._id]) {
          projTagObj[project?._id] = [
            ...projTagObj[project?._id],
            ...getResourceTags(resId, allResources),
          ];
        } else {
          projTagObj[project?._id] = [...getResourceTags(resId, allResources)];
        }
        return null;
      });

      project?.selected_knowledeNuggets_ids?.map(knId => {
        if (projTagObj[project?._id]) {
          projTagObj[project?._id] = [
            ...projTagObj[project?._id],
            ...getKnowledgeNuggetTags(knId, allKNuggets),
          ];
        } else {
          projTagObj[project?._id] = [
            ...getKnowledgeNuggetTags(knId, allKNuggets),
          ];
        }
        return null;
      });

      project?.selected_nonsummary_ids?.map(nonSId => {
        if (projTagObj[project?._id]) {
          projTagObj[project?._id] = [
            ...projTagObj[project?._id],
            ...getNonSummaryTags(nonSId, allNonSummaries),
          ];
        } else {
          projTagObj[project?._id] = [
            ...getNonSummaryTags(nonSId, allNonSummaries),
          ];
        }
        return null;
      });
      return null;
    });

    setProjectTagObj(projTagObj);
  }, [projectData, allResources, allKNuggets, allNonSummaries]);

  function tagSearch(tag, event) {
    let siblings = [];
    let s = event.target.parentNode.firstChild;

    do {
      siblings.push(s);
    } while ((s = s.nextSibling));
    setSiblings(siblings);

    for (let i in siblings) {
      if (siblings[i].textContent === tag) {
        siblings[i].style.backgroundColor = '#6B46C1';
      } else {
        siblings[i].style.backgroundColor = '#718096';
      }
    }

    setSearchTypeTxt('Showing tagged files');
    setSearch(true);
    setSearchValue(tag);

    if (tag.trim() !== '') {
      gtag('event', 'tag_search', {
        search_value: tag.trim(),
        userid: `${userLocalData._id}`,
        full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
        verified: `${userLocalData.isVerified}`,
      });
      // search on course data
      const searchCourseResult = resourceData?.filter(res => {
        return (
          res.resource.tags.filter(item => {
            return item.tag_name === tag;
          }).length > 0
        );
      });

      // search on non summary data and get the final result for non summary data
      const searchNonSummeryResult = nonsummaryhiveData?.filter(res => {
        return (
          res?.tags.filter(item => {
            return item === tag;
          }).length > 0
        );
      });

      // search on knowledge nugget data get the final result for knowledge library data
      const searchKnowwledgeNuggetResult = knowledgeNuggetData?.filter(res => {
        return (
          res?.tags.filter(item => {
            return item.tag_name === tag;
          }).length > 0
        );
      });

      // search on knowledge library data get the final result for knowledge library data
      const searchKnowwledgeLibraryResult = knowledgeLibraryData?.filter(
        res => {
          return (
            res?.tags.filter(item => {
              return item.tag_name === tag;
            }).length > 0
          );
        }
      );

      // search on course module data and return the course id
      const preModuleResult = moduleData?.filter(modu => {
        return (
          modu.module.lesson.filter(les => {
            return (
              les.tags.filter(item => {
                return item.tag_name === tag;
              }).length > 0
            );
          }).length > 0
        );
      });
      const postModuleResult = preModuleResult?.map(preModu => {
        return preModu.module.resource_id;
      });
      const searchModuleResult = resourceData?.filter(res => {
        return [...new Set(postModuleResult)].includes(res.resource._id);
      });
      // combine and get the final result for course data
      const fnlSearchCourseTagResult = [
        ...new Map(
          [...searchCourseResult, ...searchModuleResult].map(item => [
            item.resource['_id'],
            item,
          ])
        ).values(),
      ];

      // search on project data and return the project id
      const searchProjectResult = [];
      for (let projectId in projectTagObj) {
        if (projectTagObj[projectId]?.includes(tag)) {
          searchProjectResult?.push(projectId);
        }
      }
      // search on project data and return the projects
      const fnlSearchProjectTagResult = projectData?.filter(project => {
        return searchProjectResult.includes(project?._id);
      });

      // set the final distinct tag search result of course data
      setSearchCourseResults(fnlSearchCourseTagResult);
      // set the final distinct tag search result of non summary data
      setSearchNonSummeryResult(searchNonSummeryResult);
      // set the final distinct tag search result of knowledge nugget data
      setSearchKNResult(searchKnowwledgeNuggetResult);
      // set the final distinct tag search result of knowledge library data
      setSearchKNLibrary(searchKnowwledgeLibraryResult);
      // set the final tag search result of project data
      setSearchProjectResult(fnlSearchProjectTagResult);
    } else {
      setSearchCourseResults([]);
      setSearchNonSummeryResult([]);
      setSearchProjectResult([]);
    }
  }

  function handleSearchClose() {
    setSearch(false);
    setSearchValue('');

    for (let i in siblings) {
      siblings[i].style.backgroundColor = '#718096';
    }
  }

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Box
        overflow="hidden"
        display="flex"
        flexDirection="column"
        w="full"
        h="100vh"
      >
        {show ? null : isSearch &&
          searchCourseResults.length === 0 &&
          searchNonSummeryResult.length === 0 &&
          searchKNResult.length === 0 &&
          searchKNLibrary.length === 0 &&
          searchProjectResult.length === 0 ? (
          <Header2 startTour={startTour} storageUsed={storageUsed} />
        ) : (
          <Header2
            haveResourceData={haveResourceData}
            haveNonsummeryData={haveNonsummeryData}
            haveKnowledgeNuggetData={haveKnowledgeNuggetData}
            haveProjectdata={haveProjectdata}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            storageUsed={storageUsed}
            startTour={startTour}
            handleSearchClose={handleSearchClose}
          />
        )}

        <Box overflow="hidden" display="flex" flex="1" w="100vw">
          {resourceData?.map(res => {
            res.resource.tags.map(tag => {
              tags.push(tag.tag_name);

              return null;
            });

            return null;
          })}
          {moduleData?.map(module => {
            module.module.lesson.map(map => {
              map.tags.map(tag => {
                tags.push(tag.tag_name);

                return null;
              });

              return null;
            });

            return null;
          })}
          {nonsummaryhiveData?.map(res => {
            res?.tags?.map(tag => {
              tags.push(tag);

              return null;
            });

            return null;
          })}
          {knowledgeLibraryData?.map(res => {
            res?.tags?.map(tag => {
              tags?.push(tag.tag_name);

              return null;
            });

            return null;
          })}
          {knowledgeNuggetData?.map(res => {
            res?.tags?.map(tag => {
              tags.push(tag.tag_name);

              return null;
            });

            return null;
          })}
          <Box
            display={show ? 'block' : ['none', 'none', 'none', 'block']}
            position="sticky"
            top={show ? '0' : '0'}
            bg={
              isSearch &&
              searchCourseResults.length === 0 &&
              searchNonSummeryResult.length === 0 &&
              searchKNResult.length === 0 &&
              searchKNLibrary.length === 0 &&
              searchProjectResult.length === 0
                ? 'white'
                : 'gray.700'
            }
            borderColor="gray.200"
            boxSizing="border-box"
            pt="25px"
            px="6"
            w={show ? '100vw' : '388px'}
            height={show ? '100%' : 'calc(100vh - 72px)'}
            borderRight="1px solid #E2E8F0"
            overflow="hidden"
            zIndex="100"
          >
            <Box className="input section">
              {show && (
                <Flex align="center" justify="space-between" my={5}>
                  <Text
                    fontFamily={'body'}
                    color="gray.200"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    Search
                  </Text>
                  <Box>
                    <AiOutlineClose
                      style={{
                        color: 'gray',
                        border: '1.5px',
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      onClick={handleShow}
                    />
                  </Box>
                </Flex>
              )}

              <InputGroup>
                <InputRightElement
                  onClick={() => {
                    isSearch ? handleSearchClose() : phaseSearch(searchValue);
                  }}
                  cursor="pointer"
                  children={
                    isSearch ? (
                      <Icon as={AiOutlineClose} color="gray.300" />
                    ) : (
                      <Icon
                        as={AiOutlineSearch}
                        color={
                          isSearch &&
                          searchCourseResults.length === 0 &&
                          searchNonSummeryResult.length === 0 &&
                          searchKNResult.length === 0 &&
                          searchKNLibrary.length === 0 &&
                          searchProjectResult.length === 0
                            ? 'gray.500'
                            : 'gray.100'
                        }
                        fontSize="20px"
                        fontWeight="normal"
                      />
                    )
                  }
                />
                <Input
                  onChange={event => {
                    // phaseSearch(event);
                    setSearchValue(event.target.value);
                  }}
                  onKeyUp={event => event.key === 'Enter' && phaseSearch()}
                  value={searchValue}
                  h="10"
                  rounded="4px"
                  focusBorderColor="yellow.400"
                  placeholder="Search my Hive"
                  bg={
                    isSearch &&
                    searchCourseResults.length === 0 &&
                    searchNonSummeryResult.length === 0 &&
                    searchKNResult.length === 0 &&
                    searchKNLibrary.length === 0 &&
                    searchProjectResult.length === 0
                      ? 'white'
                      : 'gray.500'
                  }
                  color={
                    isSearch &&
                    searchCourseResults.length === 0 &&
                    searchNonSummeryResult.length === 0 &&
                    searchKNResult.length === 0 &&
                    searchKNLibrary.length === 0 &&
                    searchProjectResult.length === 0
                      ? 'gray.800'
                      : 'white'
                  }
                  _placeholder={{
                    color: 'gray.100',
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                />
              </InputGroup>
            </Box>
            <Box py="10">
              <Text
                color={
                  isSearch &&
                  searchCourseResults.length === 0 &&
                  searchNonSummeryResult.length === 0 &&
                  searchKNResult.length === 0 &&
                  searchKNLibrary.length === 0 &&
                  searchProjectResult.length === 0
                    ? 'gray.600'
                    : 'gray.200'
                }
                fontFamily="Inter"
                fontWeight="700"
                fontSize="14px"
              >
                {isSearch &&
                searchCourseResults.length === 0 &&
                searchNonSummeryResult.length === 0 &&
                searchKNResult.length === 0 &&
                searchKNLibrary.length === 0 &&
                searchProjectResult.length === 0
                  ? 'My Topics'
                  : 'My Knowledge Tags'}
              </Text>
              <Box py="2" h="62vh" overflowY="auto" overflowX="hidden">
                {/* Unique values of tags */}

                {[...new Set(tags)]
                  ?.splice(0, 15)
                  ?.sort()
                  ?.map((tag, index) => (
                    <Tag
                      key={index}
                      onClick={event => {
                        tagSearch(tag, event);
                      }}
                      onMouseEnter={event => {
                        if (searchValue !== tag) {
                          event.target.style.backgroundColor = '#6B46C1';
                        }
                      }}
                      onMouseLeave={event => {
                        if (searchValue !== tag) {
                          event.target.style.backgroundColor = '#718096';
                        }
                      }}
                      value={tag}
                      cursor="pointer"
                      textTransform="capitalize"
                      bg="gray.500"
                      py="1"
                      px="2"
                      mr="2"
                      mb="2"
                      rounded="100px"
                      textAlign="center"
                      color="gray.100"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="14px"
                      _hover={{ bg: '#6B46C1' }}
                    >
                      {tag}
                    </Tag>
                  ))}
              </Box>
            </Box>
            <Box position="absolute" bottom="0" left="0">
              <Image
                src={LogoB}
                alt="The Elephant Hive Logo Bottom"
                w="138.13px"
                h="99.67"
                mx="4"
                blendMode="passthrough"
                opacity="0.4"
              />
            </Box>
          </Box>

          {isSearch ? (
            <Box flex="1" overflow="auto">
              <SearchResultBanner
                searchValue={searchValue}
                searchType={searchTypeTxt}
                handleSearchClose={handleSearchClose}
              />
              {searchCourseResults.length !== 0 ||
              searchNonSummeryResult.length !== 0 ||
              searchKNResult.length !== 0 ||
              searchKNLibrary.length !== 0 ||
              searchProjectResult.length !== 0 ? (
                <Box>
                  <ProjectFilter
                    setUpdatehivedata={setUpdatehivedata}
                    updatehivedata={updatehivedata}
                    isSearch={isSearch}
                    knowledgeNuggetData={searchKNResult}
                    knowledgeLibraryData={searchKNLibrary}
                    resourceData={searchCourseResults}
                    orgProjectData={projectData}
                    projectData={searchProjectResult}
                    nonsummaryhiveData={searchNonSummeryResult}
                    upateprojectData={upateprojectData}
                    setUpdateprojectData={setUpdateprojectData}
                    updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                    setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                    userInfo={userInfo}
                  />
                  <ResourceFilter
                    setUpdatehivedata={setUpdatehivedata}
                    updatehivedata={updatehivedata}
                    knowledgeNuggetData={searchKNResult}
                    knowledgeLibraryData={searchKNLibrary}
                    resourceData={searchCourseResults}
                    orgProjectData={projectData}
                    projectData={searchProjectResult}
                    nonsummaryhiveData={searchNonSummeryResult}
                    upateprojectData={upateprojectData}
                    setUpdateprojectData={setUpdateprojectData}
                    updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                    setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                    userInfo={userInfo}
                  />
                </Box>
              ) : (
                <NoSearchResult />
              )}
            </Box>
          ) : (
            <Box flex="1" overflow="auto">
              {/* search section on medium screen */}
              <Flex
                bgColor="white"
                h="56px"
                border="1px"
                borderColor="gray.200"
                align="center"
                display={['flex', 'flex', 'flex', 'none']}
              >
                <InputGroup onClick={handleShow} ml={10} w="50%">
                  <Input h="40px" placeholder="Search my Hive" />
                  <InputRightElement
                    children={
                      <BsSearch
                        style={{
                          width: '24px',
                          height: '24px',
                          border: '1.5px',
                          color: 'gray',
                        }}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
              <Banner
                setUpdatehivedata={setUpdatehivedata}
                updatehivedata={updatehivedata}
                nonsummaryhiveData={nonsummaryhiveData}
                resourceData={resourceData}
                knowledgeNuggetData={knowledgeNuggetData}
                knowledgeLibraryData={knowledgeLibraryData}
                onClaimResourceSubmit={handleClaimResourceSubmit}
                projectData={projectData}
                upateprojectData={upateprojectData}
                setUpdateprojectData={setUpdateprojectData}
                updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                userInfo={userInfo}
                storageUsed={storageUsed}
              />
              <ProjectFilter
                setUpdatehivedata={setUpdatehivedata}
                updatehivedata={updatehivedata}
                isSearch={isSearch}
                knowledgeNuggetData={knowledgeNuggetData}
                knowledgeLibraryData={knowledgeLibraryData}
                nonsummaryhiveData={nonsummaryhiveData}
                resourceData={resourceData}
                orgProjectData={projectData}
                projectData={projectData}
                upateprojectData={upateprojectData}
                setUpdateprojectData={setUpdateprojectData}
                updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                userInfo={userInfo}
              />
              <ResourceFilter
                setUpdatehivedata={setUpdatehivedata}
                updatehivedata={updatehivedata}
                isSearch={isSearch}
                knowledgeNuggetData={knowledgeNuggetData}
                knowledgeLibraryData={knowledgeLibraryData}
                nonsummaryhiveData={nonsummaryhiveData}
                resourceData={resourceData}
                orgProjectData={projectData}
                projectData={projectData}
                upateprojectData={upateprojectData}
                setUpdateprojectData={setUpdateprojectData}
                updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                userInfo={userInfo}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReturningUser;
