import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';

function ListTab({
  lesson,
  pauseStateVal,
  editStateVal,
  editCompleteVal,
  refresh, 
  setRefresh
}) {
  const lessonData = lesson
  const pauseState = pauseStateVal
  const editState = editStateVal
  const editComplete = editCompleteVal

  return (
    <ChakraProvider theme={theme}>
      <SummaryList
        editStateVal={editState}
        editCompleteVal={editComplete}
        lesson={lessonData}
        refresh={refresh}
        setRefresh={setRefresh}
        bgw={["100vw","100vw","100%"]}
        w={["full","full","716px"]}
        al="center"
      />
      <SummaryPlayer
        pauseStateVal={pauseState}
        lesson={lessonData}
        w="100%"
      />
    </ChakraProvider>
  );
}
export default ListTab;
