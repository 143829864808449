import React, { useRef } from 'react';
import {
  Box,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  Input,
  FormHelperText,
  InputGroup,
  InputRightElement,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, ErrorMessage } from 'formik';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Axios from 'axios';
import * as yup from 'yup';

function PasswordResetForm(props) {
  const userId = props.userId;
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const onClickReveal = () => {
    onToggle();

    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };
  const initialValues = {
    password: '',
  };
  const validationSchema = yup.object({
    password: yup
      .string('invalid Password')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character'
      )
      .required('Password is Required'),
  });
  const onSubmit = async (values, actions) => {
    await Axios.put(
      `${process.env.REACT_APP_EH_BACKEND}/auth/confirmpassword/${userId}`,
      values
    )
      .then(res => {
        if (res.data.status) {
          props.onAlertHandle({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch(err => {
        return err;
      });
    actions.setSubmitting(false);
    actions.resetForm();
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form autoComplete="off">
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.errors.password && formik.touched.password}
              >
                <FormLabel htmlFor="email">
                  <Text fontSize="sm" fontWeight="normal" color="gray.900">
                    Password
                  </Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    ref={inputRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    placeholder="Enter Password"
                    _placeholder={{ color: 'gray.400' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                      onClick={() => onClickReveal()}
                      variant="link"
                      icon={isOpen ? <HiEye /> : <HiEyeOff />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  <ErrorMessage name="password" />
                </FormErrorMessage>
                <FormHelperText>
                  At least 8 characters, one digit, one uppercase, one special
                  character (#*!$){' '}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                colorScheme="yellow"
                isLoading={formik.isSubmitting}
                isFullWidth
              >
                <Text fontWeight="bold">Confirm</Text>
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default PasswordResetForm;
