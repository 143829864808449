import {
  Box,
  Center,
  ChakraProvider,
  Image,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';

function ProjectCard({ IconAndcolor, projectName, daysAgo, daysAdded }) {
  return (
    <ChakraProvider theme={theme}>
      <Box
        w={['full', 'full', 'full']}
        bg="purple.100"
        p="2"
        rounded="16px"
        boxShadow="base"
        cursor={'pointer'}
      >
        <Center
          float="left"
          p="8px"
          h="8"
          w="8"
          bg="#ffffff"
          align="center"
          rounded="12px"
          fontSize="16px"
          shadow="base"
        >
          {/* Variable icon and color */}

          <Image src={IconAndcolor} alt="Project Icon" />
        </Center>
        <Box pl="6" overflow="auto">
          <Text
            pb="1"
            color="black"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="700"
            fontSize="14px"
            noOfLines={2}
          >
            {projectName}
          </Text>
          <Text
            color="gray.700"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            noOfLines={2}
          >
            {daysAgo === 0 && daysAdded === 0 ? 'Added Today' : daysAgo === 0 ? 'Viewed Today' : `viewed ${daysAgo} days ago`}
          </Text>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default ProjectCard;
