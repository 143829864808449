import React, { useEffect } from 'react';
import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer';
import { MarkerType } from 'react-flow-renderer';
import ZoomController from '../ZoomController';
import extendTheme from '../../../extendTheme';
import './index.css';

let initialNodes = [];
let initialEdges = [];
const MindMap = ({
  lesson,
  editStateVal,
  editTagModalXVal,
  editTagModalYVal,
  mdLeftVal,
  lgLeftVal,
}) => {
  const lessonData = lesson;
  const editState = editStateVal;
  const editTagModalX = editTagModalXVal;
  const editTagModalY = editTagModalYVal;
  const snapGrid = [20, 20];
  let zoom = 0;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = params => setEdges(els => addEdge(params, els));

  useEffect(() => {
    lessonData?.map(lesson_data => {
      lesson_data.module.lesson.map(lesson_data => {
        initialNodes = lesson_data.initialNodes;
        initialEdges = lesson_data.initialEdges;

        initialNodes.map((initial_node_data, index) => {
          if (initial_node_data.className === 'main-node') {
            initial_node_data.style = {
              background: '#ECC94B',
              color: '#333',
              border: '1px solid #ECC94B',
              width: 150,
              fontSize: 'large',
              fontWeight: 'bold',
            };
            // initial_node_data.position = {
            //   x: 0,
            //   y: 200,
            // };
          } else if (initial_node_data.className === 'child-node') {
            initial_node_data.style = {
              background: '#E5E5E5',
              color: '#333',
              border: '1px solid #E5E5E5',
              width: 250,
              fontSize: 'large',
              fontWeight: 'bold',
              textAlign: 'justify',
              textjustify: 'inter-word',
            };
          } else {
            initial_node_data.style = {
              background: '#FFFFFF',
              color: '#333',
              border: '1px solid #E2E8F0',
              width: 450,
              padding: '15px',
              borderRadius: '7px',
              textAlign: 'justify',
              textjustify: 'inter-word',
            };
          }

          return null;
        });

        return null;
      });

      return null;
    });

    let result = initialEdges?.map(function (el) {
      let o = Object.assign({}, el);
      o.markerEnd = { type: MarkerType.ArrowClosed };
      return o;
    });

    setNodes(initialNodes);
    setEdges(result);
  }, [lessonData, setEdges, setNodes]);

  return (
    <ChakraProvider theme={extendTheme}>
      {editState ? (
        <Flex justifyContent="center">
          <Box
            width="100%"
            height="80vh"
            bg="black"
            position="fixed"
            zIndex={2}
            opacity={0.6}
            overflow="hidden"
            display={{ base: 'none', md: 'flex', lg: 'flex' }}
          ></Box>
          <Text
            bg="white"
            pt="5"
            pb="5"
            pl="10"
            pr="10"
            ml={editTagModalX}
            mt={Number(editTagModalY) - 200}
            borderRadius="20"
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
            position="relative"
            zIndex={3}
            display={{ base: 'none', md: 'flex', lg: 'flex' }}
          >
            Mind Map will be updated after saving changes
          </Text>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            snapToGrid={true}
            snapGrid={snapGrid}
            defaultZoom={zoom}
            style={{
              display: 'inline-block',
              paddingLeft: 3000,
              paddingTop: 1300,
              position: 'fixed',
              zIndex: 1,
            }}
          >
            <ZoomController />
          </ReactFlow>
        </Flex>
      ) : (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          snapToGrid={true}
          snapGrid={snapGrid}
          defaultZoom={zoom}
          style={{
            display: 'inline-block',
            paddingLeft: 3000,
            paddingTop: 1300,
            position: 'fixed',
            zIndex: 1,
          }}
        >
          <ZoomController />
        </ReactFlow>
      )}
    </ChakraProvider>
  );
};

export default MindMap;
