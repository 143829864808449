import React from 'react';
import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import Axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { BsGoogle } from 'react-icons/bs';
import { login, user } from '../../utils';
import { useHistory } from 'react-router-dom';
import gtag from 'ga-gtag';

function GoogleLoginButton(props) {
  const history = useHistory();
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

  const newlogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      await Axios.post(
        `${process.env.REACT_APP_EH_BACKEND}/auth/google-login`,
        {
          code,
        }
      )
        .then(res => {
          if (res.data.status) {
            props.onAlertHandle({
              status: res.data.status,
              message: res.data.message,
            });
            props.setUpdate(!props.update);
          } else {
            const tokenKey = res.data.tokenData.token;
            const userData = res.data.user;

            if (res.status === 200) {
              gtag('config', REACT_APP_GA_MEASUREMENT_ID, {
                user_id: userData._id,
              });
              gtag('event', 'login', {
                method: 'google login',
                userid: `${userData._id}`,
                full_name: `${userData.firstName} ${userData.lastName}`,
                verified: `${userData.isVerified}`,
              });
              login(tokenKey);
              user(userData);
              history.push('/homepage');
            } else {
              gtag('event', 'exception', {
                process: `loging to account by ${userData.firstName} ${userData.lastName} with user id: "${userData._id}"`,
                method: 'google login',
                description: 'error occured while loging in',
                fatal: false,
              });
              history.push('/');
            }
          }
        })
        .catch(err => {
          gtag('event', 'exception', {
            method: 'google login',
            description: err,
            fatal: false,
          });
          return err;
        });
    },
    flow: 'auth-code',
  });

  return (
    <Box>
      <Button
        w={'full'}
        colorScheme="gray"
        variant="outline"
        color="black"
        onClick={() => newlogin()}
      >
        <Flex align="center" justify="center">
          <BsGoogle />
          <Spacer />
          <Text display={['none','none','flex']} ml={[1, 6]} fontSize={["12px", "18px"]} color="gray.900" fontWeight="bold">
            Continue with Google
          </Text>
          <Text display={['flex','flex','none']} ml={[1, 6]} fontSize={["12px", "18px"]} color="gray.900" fontWeight="bold">
            Google
          </Text>
        </Flex>
      </Button>
    </Box>
  );
}

export default GoogleLoginButton;
