import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Formik, Form } from 'formik';
import { AiOutlineClose } from 'react-icons/ai';

function ClaimResource({ isOpen, onClose, onFinish }) {
  const handleSubmit = values => {
    onFinish(values);
    onClose();
  };
  const initialValues = {
    resource_claim_id: '',
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay bg="rgba(0,0,0,0.48)" />
      <ModalContent
        color="black"
        bg="white"
        overflow="auto"
        w="448px"
        px="6"
        py="4"
        rounded="16px"
        boxShadow="md"
      >
        <ModalHeader p="0" m="0">
          <Flex>
            <Text
              py="1"
              color="gray.900"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fontSize="20px"
            >
              Claim my course
            </Text>
            <Spacer />
            <Box py="7px" px="3">
              <Icon
                as={AiOutlineClose}
                fontSize="14px"
                _hover={{ cursor: 'pointer' }}
                onClick={onClose}
              />
            </Box>
          </Flex>
        </ModalHeader>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {formik => (
            <Form autoComplete="off">
              <ModalBody p="0" m="0">
                <Box pt="6" pb="6">
                  <Text
                    pb="6"
                    color="gray.700"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    To gain access to your exclusive course summary, type the
                    six digit code provided in your Welcome email.
                  </Text>
                  <Text
                    pb="2"
                    color="gray.900"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Course code
                  </Text>
                  <FormControl>
                    <InputGroup>
                      <Input
                        id="resource_claim_id"
                        name="resource_claim_id"
                        type="text"
                        p="3"
                        h="40px"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.resource_claim_id}
                        borderColor="#E2E8F0"
                        boxSizing="border-box"
                        focusBorderColor="yellow.500"
                        rounded="4px"
                        placeholder="Enter six digit code"
                        _placeholder={{
                          color: 'gray.400',
                          fontWeight: '400',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
                <Center>
                  <Button
                    type="submit"
                    bg="yellow.400"
                    rounded="4px"
                    px="4"
                    py="2"
                    _hover={{ bg: 'yellow.500' }}
                    _active={{
                      bg: 'yellow.500',
                      transform: 'scale(0.98)',
                    }}
                    _focus={{
                      outline: 'none',
                    }}
                  >
                    Access Course
                  </Button>
                </Center>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default ClaimResource;
