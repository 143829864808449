import {
  Box,
  Center,
  ChakraProvider,
  Image,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';
import NoResult from '../../../assests/Elephant hive - Logo-Bottom Vector.png';

function NoSearchResult() {
  return (
    <ChakraProvider theme={theme}>
      <Box justifyContent="center" textAlign="center">
        <Center pt="100px" align="center">
          <Center bg="gray.200" py="12" px="8" rounded="full">
            <Image src={NoResult} alt="No Search Result" w="110.51px"></Image>
          </Center>
        </Center>
        <Text
          pt="6"
          pb="4"
          color="gray.900"
          fontFamily="Inter"
          fontWeight="400"
          fontSize="24px"
        >
          We couldn’t find anything on this topic
        </Text>
        <Text
          color="gray.500"
          fontFamily="Inter"
          fontWeight="400"
          fontSize="16px"
        >
          Make sure all words are spelled correctly.
        </Text>
        <Text
          color="gray.500"
          fontFamily="Inter"
          fontWeight="400"
          fontSize="16px"
        >
          Try searching by your knowledge tags.
        </Text>
      </Box>
    </ChakraProvider>
  );
}

export default NoSearchResult;
