import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Img,
  Progress,
  Text,
} from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { logout } from '../../utils/index';
import { useHistory } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs';
import bytes from 'bytes';
import KNLIBBULD from './../../assests/Icons/Navbar/KN-LIB.png';
import { BiUserCircle } from 'react-icons/bi';
import { motion } from 'framer-motion';

function ProfileViewer({
  userLocalData,
  handleClick,
  onCreateKnowledgeNuggetOpen,
  storageUsed,
}) {
  const history = useHistory();
  const handleLogout = () => {
    logout();
    history.push('/');
  };

  const handleClose = () => {
    onCreateKnowledgeNuggetOpen();
    handleClick();
  };

  let storageSize = bytes(storageUsed);
  return (
    <Flex
      rounded="lg"
      w={['100vw', '100vw', '280px']}
      h={['calc(100vh - 64px)', 'calc(100vh - 64px)', '200px']}
      direction="column"
      overflow="hidden"
      px={4}
    >
      <Flex
        direction="row"
        // my={2}
        align={['start', 'start', 'center']}
        justify={['center', 'center', 'space-evenly']}
        mx={5}
      >
        <Avatar
          w="40px"
          h="40px"
          size="md"
          icon={
            <Box
              position="relative"
              alignItems="center"
              justifyContent="center"
              mr={5}
              mb={5}
            >
              <Center
                zIndex={1}
                position="absolute"
                top={'50%'}
                left={'50%'}
                as={motion.div}
                animate={{
                  scale: [1.4, 1.0],
                  opacity: [0, 1],

                  transition: {
                    delay: 12.4,
                  },
                }}
                initial={{
                  opacity: 0,
                }}
              >
                <AiOutlineUser style={{ border: '0.7px', color: '#718096' }} />
              </Center>
              <Center
                as={motion.div}
                position="absolute"
                top={'50%'}
                left={'50%'}
                animate={{
                  scale: [2.3, 1.4],
                  opacity: [1, 0],

                  transition: {
                    duration: 10.0,
                  },
                }}
              >
                <BiUserCircle style={{ border: '0.4px', color: '#718096' }} />
              </Center>
            </Box>
          }
        />
        <Flex ml={3} align={'center'} justify="center" flexDirection={'column'}>
          <Heading
            size="md"
            fontWeight="normal"
            fontFamily={'body'}
            fontSize="md"
            color="gray.900"
            w={'200px'}
            isTruncated
          >
            {`${userLocalData.firstName} ${userLocalData.lastName}`}
          </Heading>
          <Text
            isTruncated
            color="gray.500"
            fontSize="14px"
            fontFamily={'body'}
            w="200px"
            mt={-2}
          >
            {userLocalData.email}
          </Text>
        </Flex>
      </Flex>
      {/* storage used */}
      <Flex
        display={['none', 'none', 'flex']}
        align="center"
        flexDirection="column"
        justify="center"
      >
        <Flex>
          <Text fontSize="12px" color="gray.900" fontFamily={'body'}>
            Storage Used
          </Text>
          <Text ml={4} fontSize="12px" color="gray.900" fontFamily={'body'}>
            {storageSize}
          </Text>
          <Text ml={2} fontSize="12px" color="gray.900" fontFamily={'body'}>
            (of 1GB)
          </Text>
        </Flex>
        <Progress
          ml={[3, 0]}
          size={'sm'}
          w={'full'}
          colorScheme={'yellow'}
          value={storageUsed / (1024 * 1 * 1000000)}
          max={100}
        />
      </Flex>
      <Divider display={['none', 'none', 'flex']} orientation="horizontal" />
      <Box>
        <Flex
          _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
          my={2}
          py={2}
          direction="row"
          align={['start', 'center']}
          justify={['start']}
          // mx={['-5', '0']}
        >
          <AiOutlineUser
            style={{ color: '#718096', marginLeft: '48px' }}
            size={28}
          />
          <Text ml={3} fontSize="16px" color="gray.900" fontFamily={'body'}>
            My Profile
          </Text>
        </Flex>
        <Flex
          _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
          my={2}
          py={2}
          direction="row"
          align={['start', 'center']}
          justify={['start']}
          mx={['-5', '0']}
          display={['flex', 'flex', 'none']}
          onClick={() => history.push(`/knowledge-library`)}
        >
          <Img
            src={KNLIBBULD}
            alt="Knowlege librarys"
            style={{ color: '#718096', marginLeft: '48px' }}
          />
          {/* <AiOutlineUser
                style={{ color: '#718096', marginLeft: '48px' }}
                size={28}
              /> */}
          <Text ml={3} fontSize="16px" color="gray.900" fontFamily={'body'}>
            Knowledge Library
          </Text>
        </Flex>
        <Divider display={['flex', 'flex', 'none']} orientation="horizontal" />
        <Flex
          display={['flex', 'flex', 'none']}
          _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
          my={4}
          py={2}
          direction="row"
          align="center"
          // mx={['-5', '0']}
        >
          <BsQuestionCircle
            style={{ color: 'gray', marginLeft: '48px' }}
            size={28}
          />
          <Text
            ml={3}
            cursor="pointer"
            fontSize="16px"
            color="gray.900"
            fontFamily={'body'}
          >
            Help
          </Text>
        </Flex>
        <Flex
          onClick={handleLogout}
          _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
          my={4}
          py={2}
          direction="row"
          align="center"
          mx={['-5', '0']}
        >
          <FiLogOut style={{ color: 'gray', marginLeft: '48px' }} size={28} />
          <Text
            ml={3}
            cursor="pointer"
            fontSize="16px"
            color="gray.900"
            fontFamily={'body'}
          >
            Log out
          </Text>
        </Flex>
        <Divider display={['flex', 'flex', 'none']} orientation="horizontal" />
        <Flex
          display={['flex', 'flex', 'none']}
          align="center"
          flexDirection="column"
          justify="center"
          my={4}
        >
          <Flex>
            <Text fontSize="16px" color="gray.900" fontFamily={'body'}>
              Storage Used
            </Text>
            <Text ml={4} fontSize="16px" color="gray.900" fontFamily={'body'}>
              {storageSize}
            </Text>
            <Text ml={2} fontSize="16px" color="gray.900" fontFamily={'body'}>
              (of 1GB)
            </Text>
          </Flex>
          <Progress
            my={2}
            ml={[3, 0]}
            size={'lg'}
            w={'full'}
            colorScheme={'yellow'}
            value={storageUsed / (1024 * 1 * 1000000)}
            max={100}
          />
        </Flex>
      </Box>
      <Box position="absolute" bottom={10} right="1">
        <Button
          bg="yellow.400"
          color="gray.900"
          fontWeight="700"
          fontFamily="Inter"
          display={['flex', 'flex', 'none']}
          w={'90vw'}
          h="40px"
          mx={5}
          onClick={handleClose}
        >
          New Summary
        </Button>
      </Box>
    </Flex>
  );
}

export default ProfileViewer;
