import {
  Box,
  ChakraProvider,
  Flex,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import extendTheme from '../../extendTheme';
import axios from 'axios';

let tempNodeValueArr = [];
const SummaryList = ({
  bgw,
  w,
  al,
  editStateVal,
  editCompleteVal,
  refresh,
  setRefresh,
}) => {
  const location = useLocation();
  let mainEditState = editStateVal;
  let editComplete = editCompleteVal;
  const [update, setUpdate] = useState(false);
  const [state, setState] = useState();
  const [lessonData, setLessonData] = useState([]);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const { REACT_APP_EH_BACKEND } = process.env;
  const knowledgeNuggetId = new URLSearchParams(location.search).get(
    'knowledge_nugget_id'
  );
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  let lessonCode = '';
  let lessonName = '';
  let childNodeArr = [];
  let miniChildNodeArr = [];
  let summaryListArr = [];
  let filteredSummaryList = [];
  let filteredTopicList = [];

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [refresh]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?_id=${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(knowledgeNuggetResponse => {
        knowledgeNuggetResponse?.data.map(k_nugget_data => {
          setLessonData(k_nugget_data?.knowledgeNugget?.lesson);
          setState(k_nugget_data.knowledgeNugget.type);
          return null;
        });
      })
      .catch(loginError => {
        return loginError;
      });
  }, [knowledgeNuggetId, REACT_APP_EH_BACKEND, mainEditState, update]);

  lessonData?.map(lesson_data => {
    lessonName = lesson_data.name;
    lesson_data.initialNodes.map(initial_nodes_data => {
      if (initial_nodes_data.className !== 'main-node') {
        if (initial_nodes_data.className === 'child-node') {
          childNodeArr.push(initial_nodes_data);
        } else {
          miniChildNodeArr.push(initial_nodes_data);
        }
      }
      return null;
    });
    return null;
  });

  childNodeArr.map(child_node_data => {
    miniChildNodeArr.map(mini_child_node_data => {
      if (
        child_node_data.childNodeName === mini_child_node_data.parentNodeName
      ) {
        summaryListArr.push({
          sub_module_topic: child_node_data?.data?.label,
          sub_module_data: {
            id: mini_child_node_data.id,
            label: mini_child_node_data?.data?.label,
          },
        });
      }

      return null;
    });

    return null;
  });

  const subModules = summaryListArr.map(o => o.sub_module_data);
  filteredSummaryList = summaryListArr.filter(
    ({ sub_module_data }, index) =>
      !subModules.includes(sub_module_data.label, index + 1)
  );

  const topics = summaryListArr.map(o => o.sub_module_topic);

  filteredTopicList = summaryListArr.filter(
    ({ sub_module_topic }, index) =>
      !topics.includes(sub_module_topic, index + 1)
  );

  useEffect(() => {
    if (editComplete === 'editComplete') {
      let groupNodeResults = tempNodeValueArr.reduce(function (results, org) {
        (results[org.id] = results[org.id] || []).push(org);
        return results;
      }, {});

      const lastNodeResultArr = [];
      for (const key in groupNodeResults) {
        if (groupNodeResults.hasOwnProperty(key)) {
          lastNodeResultArr.push(
            groupNodeResults[key][groupNodeResults[key].length - 1]
          );
        }
      }

      let tempLessonData = lessonData;

      if (lastNodeResultArr.length > 0) {
        tempLessonData[0].initialNodes.map(initial_node_data => {
          lastNodeResultArr.map(last_node_data => {
            if (initial_node_data.id === last_node_data.id) {
              initial_node_data.data = last_node_data;
            }

            return null;
          });

          return null;
        });
      }

      const submitObj = {
        lesson: tempLessonData,
      };



      if (state === 'web scrapings') {
        axios({
          method: 'PUT',
          url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${knowledgeNuggetId}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
          data: submitObj,
        })
          .then(putKnowledgeNuggetResponse => {
            if (putKnowledgeNuggetResponse.status === 200) {
              alertHandler({
                status: putKnowledgeNuggetResponse.data.status,
                message: putKnowledgeNuggetResponse.data.message,
              });
              setRefresh(!refresh);
              tempNodeValueArr = []

            }
          })
          .catch(error => {
            return error;
          });
      } else {
        setRefresh(!refresh);
        setUpdate(!update);
        tempNodeValueArr = []
      }

      setRefresh(!refresh);
      setUpdate(!update);
    }

    setUpdate(!update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editComplete, mainEditState]);
  // console.log(tempNodeValueArr);
  const TextAreaUpdaterNode = ({ data, id }) => {
    const onChange = useCallback(
      evt => {
        tempNodeValueArr.push({
          id: id,
          label: evt.target.value,
        });
      },
      [id]
    );

    return (
      <Flex flexDirection="column" padding="10px" w="100%">
        <Textarea
          width="100%"
          height="200px"
          defaultValue={data}
          onChange={onChange}
        />
      </Flex>
    );
  };

  return (
    <ChakraProvider theme={extendTheme}>
      <Box bg="white" h="90vh" w={bgw} float="left" position="fixed" zIndex={3}>
        <Box
          px={{ base: '4', sm: '4', md: '4', lg: '6' }}
          bg="white"
          w={bgw}
          h="100vh"
          overflow="auto"
          pb="0"
          pt="6"
        >
          <Box bg="white" align={al}>
            <Box w={w}>
              <Flex align="center">
                <Box>
                  <AiOutlinePlayCircle color="#6B46C1" size="18px" />
                </Box>
                <Text
                  pl={{ base: '3', md: '4', lg: '6' }}
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  align="start"
                  fontSize={{
                    base: '16px',
                    sm: '20px',
                    md: '20px',
                    lg: '20px',
                  }}
                >
                  {lessonCode} {lessonName}
                </Text>
              </Flex>
              <Box
                m="0"
                pt="4"
                pb="300px"
                pl={{ base: '4', md: '6', lg: '10' }}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize={{ base: '14px', md: '14px', lg: '16px' }}
              >
                {filteredTopicList.map((topic_data, index) => (
                  <UnorderedList pb="10" textAlign="left" key={index}>
                    <Text
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize={{ sm: '14px', md: '20px', lg: '20px' }}
                    >
                      {topic_data.sub_module_topic}
                    </Text>
                    {filteredSummaryList.map((summary_data, index) => {
                      if (
                        topic_data.sub_module_topic ===
                        summary_data.sub_module_topic
                      ) {
                        return (
                          <Flex key={index}>
                            {mainEditState ? (
                              <TextAreaUpdaterNode
                                id={summary_data.sub_module_data.id}
                                data={summary_data.sub_module_data.label}
                              />
                            ) : (
                              <ListItem>
                                {summary_data.sub_module_data.label}
                              </ListItem>
                            )}
                          </Flex>
                        );
                      }

                      return null;
                    })}
                  </UnorderedList>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default SummaryList;
