import {
  ChakraProvider,
  theme,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Box,
  Spacer,
  Text,
  Flex,
  Input,
  useToast,
  Badge,
  useBreakpointValue,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { ResourceContext } from '../../../../Context/ResourceContext';
import { ModuleContext } from '../../../../Context/ModuleContext';
import Dragdrop from './DragDrop';
import axios from 'axios';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';

function UploadFiles({ isOpen, onClose, refresh, setRefresh, lesId, modId }) {
  const [resourceData, setResourceData] = useState();
  const [moduleData, setModuleData] = useState();
  const [moduleId, setModuleId] = useState();
  const [lessonId, setLessonId] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState();
  const [isTagSelected, setIsTagSelected] = useState(false);
  const [tagArray, setTagArray] = useState(['sdfds']);
  const [slectedTagArray, setSelectedTagArray] = useState([]);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [input, setInput] = useState('');
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const resourceContextData = useContext(ResourceContext);
  const moduleContextData = useContext(ModuleContext);
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const resIdVal = new URLSearchParams(location.search).get('resource_id');
  let fileNameLen = 30;
  const [uploadData, setUploadData] = useState();
  const userLocalData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setResourceData(resourceContextData);
    setModuleData(moduleContextData);
  }, [resourceContextData, moduleContextData, resIdVal]);

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  useEffect(() => {
    const tagValArr = [];
    resourceData?.map(res => {
      res.resource.tags.map(tag => {
        tagValArr.push(tag.tag_name);

        return null;
      });

      return null;
    });
    moduleData?.map(module => {
      module.module.lesson.map(map => {
        map.tags?.map(tag => {
          tagValArr.push(tag.tag_name);

          return null;
        });

        return null;
      });

      return null;
    });

    let n = 4;
    var uniq = tagValArr.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    var shuffled = uniq.sort(function () {
      return 0.5 - Math.random();
    });
    var selected = shuffled.slice(0, n);
    setTagArray(selected);
  }, [resourceData, moduleData]);

  const moduleNameAccordianClick = moduleId => {
    setModuleId(moduleId);
  };

  const lessonAccordianClick = lessonId => {
    setLessonId(lessonId);
  };

  const truncate = input =>
    input?.length > fileNameLen
      ? `${input.substring(0, fileNameLen)}...`
      : input;

  const uid = () =>
    String(Date.now().toString(32) + Math.random().toString(16)).replace(
      /\./g,
      ''
    );

  useEffect(() => {
    moduleNameAccordianClick(modId);
    lessonAccordianClick(lesId);
  }, [modId, lesId]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/modules/${modId}/${lesId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(lessonResponse => {
        // const data = []
        const uploadFileArr = [];
        lessonResponse.data?.map(module_data => {
          module_data.module.lesson.map(lesson_data => {
            lesson_data.uploadFiles?.map(upload_files => {
              var fileName = /[^/]*$/.exec(upload_files.file_source)[0];
              if (upload_files.user_id === userLocalData._id) {
                uploadFileArr.push({
                  id: upload_files.id,
                  code: lesson_data.code,
                  name: lesson_data.name,
                  file_name: fileName,
                  file_source: upload_files.file_source,
                });
              }
              return null;
            });

            return null;
          });

          return null;
        });

        setUploadData(uploadFileArr);
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const initialValues = {
    files: null,
    tags: [],
  };
  const validationSchema = yup.object({
    files: yup.mixed().required('File is Required'),
    tags: yup.mixed().required('tags required'),
  });
  const onSubmit = (values, actions) => {
    setIsSubmitting(true);
    setModuleId(modId);
    setLessonId(lesId);
    console.log(moduleId, lessonId);
    if (typeof moduleId !== 'undefined' && typeof lessonId !== 'undefined') {
      let allTagsArr = [];
      // let moduleTagArr = [];
      // moduleData.map(module_data => {
      //   if (module_data.module._id === moduleId) {
      //     module_data.module.lesson.map(lesson_data => {
      //       if(lesson_data.id === lessonId){
      //       lesson_data.tags?.map(tag_data => {
      //         moduleTagArr.push(tag_data);

      //         return null;
      //       });
      //     }

      //       return null;
      //     });
      //   }

      //   return null;
      // });
      slectedTagArray.map(tag_data => {
        allTagsArr.push({
          tag_name: tag_data,
        });

        return null;
      });

      const submitTagArray = [...allTagsArr];
      const { REACT_APP_EH_BACKEND } = process.env;
      const userLocalData = JSON.parse(localStorage.user);
      const userId = userLocalData._id;
      const fileId = uid();
      const formData = new FormData();
      formData.append('files', values.files[0]);
      formData.append('tags', JSON.stringify(submitTagArray));
      const headers = {
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      };
      axios
        .post(
          `${REACT_APP_EH_BACKEND}/modules/upload/${userId}/${moduleId}/${lessonId}/${fileId}`,
          formData,
          headers
        )
        .then(uploadResponse => {
          if (uploadResponse.status === 200) {
            alertHandler({
              status: uploadResponse.data.status,
              message: uploadResponse.data.message,
            });
            actions.setSubmitting(false);
            onClose();
            actions.resetForm();
            isOpen = false;
            setIsUploaded(false);
            setUpdate(!update);
            setFileName('');
            setSelectedTagArray([]);
            setIsTagSelected(false);
            setRefresh(!refresh);
            setIsSubmitting(false);
          } else {
            setIsUploaded(false);
            setFileName('');
            setIsTagSelected(false);
            setIsSubmitting(false);
          }
        })
        .catch(err => {
          return err;
        });
    } else {
      alert('Please select a lesson before upload');
    }
  };

  const handlethis = e => {
    e.preventDefault();
  };

  const closeClickToUpload = formik => {
    setFileName('');
    setIsUploaded(false);
    setIsSubmitting(false);
    formik.setFieldValue('files', null);
  };

  const handleTagClick = tag => {
    setIsTagSelected(true);
    let removeElTagArr = tagArray;
    removeElTagArr = removeElTagArr.filter(item => item !== tag);
    setTagArray(removeElTagArr);
    slectedTagArray.push(tag);
  };

  const handleRemoveSelectedTag = tag => {
    let removeSelectedTagArr = slectedTagArray;
    removeSelectedTagArr = removeSelectedTagArr.filter(item => item !== tag);
    setSelectedTagArray(removeSelectedTagArr);
    tagArray.push(tag);
  };

  const handleDrop = value => {
    setIsUploaded(true);
    setFileName(value[0].name);
  };

  const saveTagInput = e => {
    setInput(e.target.value);
  };
  const addNewItem = e => {
    if (e.key === 'Enter') {
      setIsTagSelected(true);
      slectedTagArray.push(input);
      setInput('');
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="rgba(0,0,0,0.48)" />
          <ModalContent
            color="black"
            bg="white"
            overflow="auto"
            w="448px"
            px="6"
            py="4"
            rounded="16px"
            boxShadow="md"
          >
            <ModalHeader p="0" m="0">
              <Flex>
                <Text
                  py="1"
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                >
                  Upload files
                </Text>
                <Spacer />
                <Box py="0px" px="3">
                  <Icon
                    as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
            </ModalHeader>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {formik => (
                <Form
                  onSubmit={handlethis}
                  autoComplete="off"
                  encType="multipart/form-data"
                >
                  <ModalBody p="0">
                    <Box>
                      <Text pt="6" pb="1">
                        Upload files
                      </Text>
                      {/* upload */}
                      {isUploaded ? (
                        <Box mb="6" align="left">
                          <Tooltip
                            hasArrow
                            label={fileName !== undefined ? fileName : ''}
                            bg="gray.800"
                            placement="top"
                          >
                            <Badge
                              bg="gray.500"
                              textTransform="initial"
                              px="2"
                              py="2px"
                              rounded="full"
                              opacity={isSubmitting ? '0.7' : '1'}
                            >
                              <Flex align="center">
                                <Text
                                  pr="2"
                                  color="gray.100"
                                  fontFamily="Inter"
                                  fontStyle="normal"
                                  fontWeight="400"
                                  fontSize="14px"
                                >
                                  {fileName !== undefined
                                    ? truncate(fileName)
                                    : ''}
                                </Text>
                                {isSubmitting ? (
                                  ''
                                ) : (
                                  <Icon
                                    as={AiOutlineClose}
                                    color="gray.600"
                                    fontSize="12px"
                                    _hover={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => closeClickToUpload(formik)}
                                  />
                                )}
                              </Flex>
                            </Badge>
                          </Tooltip>
                          {uploadData &&
                            formik.values.files &&
                            uploadData.find(
                              element =>
                                element.file_name ===
                                formik.values.files[0].name
                            ) && (
                              <Box my={2}>
                                <Text
                                  color="red.500"
                                  fontFamily="Inter"
                                  fontStyle="normal"
                                  fontWeight="400"
                                  fontSize="14px"
                                >
                                  {formik.values.files[0].name} already exists,
                                  please use a different Document
                                </Text>
                              </Box>
                            )}
                        </Box>
                      ) : (
                        <Dragdrop
                          dropFile={handleDrop}
                          setFieldValue={formik.setFieldValue}
                        />
                      )}
                      {/* tags */}
                      <Box>
                        <Text
                          pb="2"
                          color="gray.900"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="400"
                          fontSize="14px"
                        >
                          Tags
                        </Text>
                        <Tooltip
                          label="Type the tag and Press Enter"
                          placement="top"
                        >
                          {isTagSelected ? (
                            <Box
                              px="3"
                              py="2"
                              border="1px solid #E2E8F0"
                              rounded="4px"
                              mb="4"
                              overflowX="auto"
                            >
                              {slectedTagArray.map(
                                (selected_tag_data, index) => (
                                  <Badge
                                    key={index}
                                    bg="purple.600"
                                    textTransform="initial"
                                    float="left"
                                    px="2"
                                    py="2px"
                                    ml="5px"
                                    mt="5px"
                                    rounded="full"
                                    opacity={isSubmitting ? '0.7' : '1'}
                                  >
                                    <Flex align="center" color="white">
                                      <Text
                                        pr="2"
                                        fontFamily="Inter"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        fontSize="14px"
                                      >
                                        {selected_tag_data}
                                      </Text>
                                      {isSubmitting ? (
                                        ''
                                      ) : (
                                        <Icon
                                          as={AiOutlineClose}
                                          fontSize="12px"
                                          _hover={{ cursor: 'pointer' }}
                                          onClick={() =>
                                            handleRemoveSelectedTag(
                                              selected_tag_data
                                            )
                                          }
                                        />
                                      )}
                                    </Flex>
                                  </Badge>
                                )
                              )}
                              {/* </Box> */}
                              <Box float="left">
                                <Input
                                  float="left"
                                  placeholder="Add tags"
                                  py="9.5px"
                                  px="3"
                                  border="none"
                                  outline="none"
                                  focusBorderColor="none"
                                  _placeholder={{
                                    color: 'gray.400',
                                    fontWeight: '400',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                  }}
                                  value={input}
                                  onInput={addNewItem}
                                  onChange={saveTagInput}
                                  onKeyPress={addNewItem}
                                />
                              </Box>
                            </Box>
                          ) : (
                            <Input
                              placeholder="Add tags"
                              py="9.5px"
                              px="3"
                              border="1px solid #E2E8F0"
                              focusBorderColor="yellow.500"
                              _placeholder={{
                                color: 'gray.400',
                                fontWeight: '400',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                              }}
                              rounded="4px"
                              mb="4"
                              value={input}
                              onInput={addNewItem}
                              onChange={saveTagInput}
                              onKeyPress={addNewItem}
                            />
                          )}
                        </Tooltip>
                        <Flex
                          align="center"
                          color="gray.600"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="400"
                          fontSize="14px"
                        >
                          <Text>Suggested</Text>
                          <Spacer />
                          {[...new Set(tagArray)]
                            .sort()
                            .map((tag_data, index) => (
                              <Badge
                                key={index}
                                px="2"
                                py="1"
                                rounded="full"
                                textTransform="initial"
                                marginLeft="3"
                                bg="gray.200"
                                cursor="pointer"
                                onClick={() => handleTagClick(tag_data)}
                              >
                                {tag_data}
                              </Badge>
                            ))}
                          <Spacer />
                        </Flex>
                      </Box>
                    </Box>
                  </ModalBody>
                  <Box align="center" pt="4">
                    <Button
                      isDisabled={
                        (isUploaded &&
                          uploadData &&
                          formik.values.files &&
                          uploadData.find(
                            element =>
                              element.file_name === formik.values.files[0].name
                          )) ||
                        formik.values.files === null
                          ? true
                          : false
                      }
                      isLoading={isSubmitting ? true : false}
                      py="2"
                      px="4"
                      bg="yellow.400"
                      rounded="4px"
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      _hover={{ bg: 'yellow.500' }}
                      _active={{
                        bg: 'yellow.500',
                        transform: 'scale(0.98)',
                      }}
                      _focus={{
                        outline: 'none',
                      }}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Upload
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}

export default UploadFiles;
