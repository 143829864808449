import React, { useRef } from 'react';
import {
  Box,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  VStack,
  FormHelperText,
  Flex,
  Select,
} from '@chakra-ui/react';
import { Formik, Form, ErrorMessage } from 'formik';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Axios from 'axios';
import * as yup from 'yup';
import { differenceInYears } from 'date-fns';
import gtag from 'ga-gtag';

function RegisterForm(props) {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

  const onClickReveal = () => {
    onToggle();

    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    institute: '',
    course: '',
    birthDay: '',
    password: '',
  };
  const validationSchema = yup.object({
    email: yup.string().email('invalid Email Address').required(),
    firstName: yup
      .string('invalid First Name')
      .max(20, 'First Name is too long')
      .required('First Name is Required'),
    lastName: yup
      .string('invalid Last Name')
      .max(20, 'Last Name is too long')
      .required('Last Name is Required'),
    institute: yup
      .string('invalid Institute')
      .required('Institute is Required'),
    course: yup.string('invalid Course').required('Course is Required'),
    password: yup
      .string('invalid Password')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character'
      )
      .required('Password is Required'),
  });
  const onSubmit = async (values, actions) => {
    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
    await Axios.post(
      `${process.env.REACT_APP_EH_BACKEND}/auth/register`,
      values
    )
      .then(res => {
        if (res.data.status) {
          props.onAlertHandle({
            status: res.data.status,
            message: res.data.message,
          });
          if (res.data.status === 'success') {
            gtag('event', 'sign_up', {
              full_name: `${values.firstName} ${values.lastName}`,
            });
          } else {
            gtag('event', 'exception', {
              process: `creating an new account by ${values.firstName} ${values.lastName} (signing up)`,
              description: 'error occured while signing up',
              fatal: false,
            });
          }
          actions.setSubmitting(false);
          actions.resetForm();
          props.setUpdate(!props.update);
        }
      })
      .catch(err => {
        gtag('event', 'exception', {
          process: `creating an new account by ${values.firstName} ${values.lastName} (signing up)`,
          description: err,
          fatal: false,
        });
        return err;
      });
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Box pt={{ base: '6', sm: '0' }} pb={{ base: '6', sm: '0' }}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form autoComplete="off">
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.errors.email && formik.touched.email}
              >
                <FormLabel htmlFor="email">
                  {' '}
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    Email Address
                  </Text>
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  _placeholder={{ color: 'gray.400' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormErrorMessage>
                  <ErrorMessage name="email" />
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.errors.firstName && formik.touched.firstName}
              >
                <FormLabel htmlFor="firstName">
                  {' '}
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    First Name
                  </Text>
                </FormLabel>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Enter First Name"
                  _placeholder={{ color: 'gray.400' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                <FormErrorMessage>
                  <ErrorMessage name="firstName" />
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.errors.lastName && formik.touched.lastName}
              >
                <FormLabel htmlFor="lastName">
                  {' '}
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    Last Name
                  </Text>
                </FormLabel>
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Enter Last Name"
                  _placeholder={{ color: 'gray.400' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                <FormErrorMessage>
                  <ErrorMessage name="lastName" />
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.errors.institute && formik.touched.institute}
              >
                <FormLabel htmlFor="institute">
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    Business school
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Business School/University"
                  id="institute"
                  name="institute"
                  _placeholder={{ color: 'gray.400' }}
                  value={formik.values.institute}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option style={{ color: 'black' }} value={'DCU'}>
                    Dublin City University (DCU)
                  </option>
                  <option style={{ color: 'black' }} value={'CMU'}>
                    Carnegie Mellon University (CMU)
                  </option>
                  <option style={{ color: 'black' }} value={'other'}>
                    Other
                  </option>
                </Select>
                <FormErrorMessage>
                  <ErrorMessage name="institute" />
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.errors.course && formik.touched.course}
              >
                <FormLabel htmlFor="course">
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    Course
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Course"
                  id="course"
                  name="course"
                  _placeholder={{ color: 'gray.400' }}
                  value={formik.values.course}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option
                    style={{ color: 'black' }}
                    value={'MSc in Management (Business)'}
                  >
                    MSc in Management (Business)
                  </option>
                  <option
                    style={{ color: 'black' }}
                    value={'MSc in Management (Strategy)'}
                  >
                    MSc in Management (Strategy)
                  </option>
                  <option
                    style={{ color: 'black' }}
                    value={'MSc in Global Management (Digital Disruption)'}
                  >
                    MSc in Global Management (Digital Disruption)
                  </option>
                  <option
                    style={{ color: 'black' }}
                    value={'Masters in Business Administration (MBA)'}
                  >
                    Masters in Business Administration (MBA)
                  </option>
                  <option style={{ color: 'black' }} value={'Lecturer / Staff'}>
                    Lecturer / Staff
                  </option>
                  <option style={{ color: 'black' }} value={'Other'}>
                    Other
                  </option>
                </Select>
                <FormErrorMessage>
                  <ErrorMessage name="course" />
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <Flex justify="space-between">
                  <FormLabel htmlFor="birthDay">
                    {' '}
                    <Text
                      fontSize={['14px']}
                      fontWeight="normal"
                      color="gray.900"
                    >
                      Birth Date
                    </Text>
                  </FormLabel>
                  <Text fontSize={['12px']} color="blackAlpha.500">
                    Optional
                  </Text>
                </Flex>

                <Input
                  id="birthDay"
                  name="birthDay"
                  type="date"
                  placeholder="Select Birth Date"
                  _placeholder={{ color: 'gray.400' }}
                  max={new Date().toISOString().split('T')[0]}
                  min="1940-01-01"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birthDay}
                />

                <FormHelperText>Message</FormHelperText>
                <Text fontSize={'9px'} fontFamily="body" color={'gray.500'}>
                  Used only to understand our audience
                </Text>
                {differenceInYears(
                  new Date(),
                  new Date(formik.values.birthDay)
                ) <= 16 && (
                  <Text fontSize={['14px']} color="red.500">
                    You must be 16 years old to register.
                  </Text>
                )}
              </FormControl>
              <FormControl
                isInvalid={formik.errors.password && formik.touched.password}
              >
                <FormLabel htmlFor="password">
                  <Text
                    fontSize={['14px']}
                    fontWeight="normal"
                    color="gray.900"
                  >
                    Password
                  </Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    ref={inputRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    placehiolder="Enter Password"
                    _placeholder={{ color: 'gray.400' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                      onClick={() => onClickReveal()}
                      variant="link"
                      icon={isOpen ? <HiEye /> : <HiEyeOff />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  <ErrorMessage name="password" />
                </FormErrorMessage>
                <FormHelperText>
                  <Text color="gray.500" fontSize={['14px']}>
                    At least 8 characters, one digit, one uppercase, one special
                    character (#*!$){' '}
                  </Text>
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                colorScheme="yellow"
                isLoading={formik.isSubmitting}
                isFullWidth
              >
                <Text
                  fontWeight="bold"
                  fontSize={['16px', '18px']}
                  color="gray.900"
                >
                  Create account
                </Text>
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default RegisterForm;
