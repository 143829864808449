import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';

function ListTab({ lesson, pauseStateVal }) {
  const lessonData = lesson
  const pauseState = pauseStateVal

  return (
    <ChakraProvider theme={theme}>
      <SummaryList
        lesson={lessonData}
        bgw="100%"
        w="716px"
        al="center"
      />
      <SummaryPlayer
        pauseStateVal={pauseState}
        lesson={lessonData} w="100%" />
    </ChakraProvider>
  );
}
export default ListTab;
