import { Box, Center, Image, Input, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../../../assests/Icon_Button.png';

function Dragdrop(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      props.setFieldValue('files', acceptedFiles);
    },
    [props]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Box
      p={'6'}
      borderRadius={'md'}
      color="gray.200"
      border="1px"
      {...getRootProps()}
    >
      <Input {...getInputProps()} />
      <Center display={'flex'} flexDir="column">
        <Image src={uploadIcon} alt="upload icon" />
        <Box display={'flex'} flexDir="row">
          <Text fontWeight="bold" color="gray.900" fontFamily={'body'}>
            Click to upload
          </Text>
          <Text color="gray.700" fontFamily={'body'} pl={2}>
            or drag and drop
          </Text>
        </Box>
        <Text color="gray.700" fontFamily={'body'}>
          All document formats and images (JPG, PNG)
        </Text>
      </Center>
    </Box>
  );
}

export default Dragdrop;
