import {
  Badge,
  Box,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  Flex,
  Spacer,
  Text,
  theme,
  useToast,
  useDisclosure,
  useBreakpointValue,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { VscFile } from 'react-icons/vsc';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import axios from 'axios';
import Delete from './Delete';
import UploadFiles from './KNSummaryUpContainer/UploadFiles';
import Dragdrop2 from './KNSummaryUpContainer/DragDrop2';
import { AiOutlineClose } from 'react-icons/ai';

function UploadedDrawerWithFiles({
  isOpen,
  onClose,
  refresh,
  setRefresh,
  isUploadOpen,
  onUploadClose,
  onUploadOpen,
  tagD,
}) {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const location = useLocation();
  const [uploadData, setUploadData] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState();
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [reload, setReload] = useState(false);
  const knowledgeNuggetId = new URLSearchParams(location.search).get(
    'knowledge_nugget_id'
  );
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [selFileId, setSelFileId] = useState();
  const [update, setUpdate] = useState(false);
  const [selFileName, setSelFileName] = useState();
  // const [knId, setKNId] = useState();
  const userLocalData = JSON.parse(localStorage.user);
  const userId = userLocalData._id;
  let fileNameLen = 30;

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  useEffect(() => {
    // setKNId(knowledgeNuggetId);
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?_id=${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(knowledgeNuggetResponse => {
        // const data = []
        const uploadFileArr = [];

        knowledgeNuggetResponse.data?.map(nugget_data => {
          nugget_data.knowledgeNugget?.uploadFiles?.map(upload_files => {
            // lesson_data.uploadFiles?.map(upload_files => {
            var fileName = /[^/]*$/.exec(upload_files.file_source)[0];
            if (upload_files.user_id === userId) {
              uploadFileArr.push({
                id: upload_files.id,
                // code: lesson_data.code,
                // name: lesson_data.name,
                file_name: fileName,
                file_source: upload_files.file_source,
              });
            }
            //   return null;
            // });

            return null;
          });

          return null;
        });

        setUploadData(uploadFileArr);
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeNuggetId, reload, refresh]);

  const handleDelete = () => {
    // delete function when confirm delete button is clicked
    const userLocalData = JSON.parse(localStorage.user);
    const userId = userLocalData._id;
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/nuggets/file/delete/${userId}/${knowledgeNuggetId}/${selFileId}/${selFileName}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          alertHandler({
            status: responseData.data.status,
            message: responseData.data.message,
          });
          onClose();
          isOpen = false;
          setUpdate(!update);
          setReload(!reload);
          setRefresh(!refresh);
        } else {
          alertHandler({
            status: 'error',
            message: 'Failed! Please try again later.',
          });
          onClose();
          isOpen = false;
        }
      })
      .catch(error => {
        return error;
      });
  };

  const truncate = input =>
    input.length > fileNameLen
      ? `${input.substring(0, fileNameLen)}...`
      : input;

  const initialValues = {
    files: null,
    tags: [],
  };
  const validationSchema = yup.object({
    files: yup.mixed().required('File is Required'),
    tags: yup.mixed().required('tags required'),
  });

  const handlethis = e => {
    e.preventDefault();
  };

  const closeClickToUpload = () => {
    setFileName('');
    setIsUploaded(false);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form
              onSubmit={handlethis}
              autoComplete="off"
              encType="multipart/form-data"
            >
              <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement="right"
                size="sm"
              >
                <DrawerContent
                  pt="5"
                  shadow="lg"
                  position="absolute"
                  h={{ sm: '100vh', md: '85vh', lg: '84vh' }}
                  top={{
                    sm: '0px !important',
                    md: '164px !important',
                    lg: '161px !important',
                  }}
                >
                  <DrawerHeader py="1px" px="6" mb="7">
                    <Flex>
                      <Text
                        color="gray.900"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="20px"
                      >
                        Upload files
                      </Text>
                      <Spacer />
                      <Box py="0px" px="3">
                        <Icon
                          as={AiOutlineClose}
                          fontSize="14px"
                          _hover={{ cursor: 'pointer' }}
                          onClick={onClose}
                        />
                      </Box>
                    </Flex>
                  </DrawerHeader>
                  <Box p={6}>
                    {isUploaded ? (
                      <Box mb="6" align="left">
                        <Badge
                          bg="gray.500"
                          textTransform="initial"
                          px="2"
                          py="2px"
                          rounded="full"
                        >
                          <Flex align="center">
                            <Text
                              pr="2"
                              color="gray.100"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="14px"
                            >
                              {fileName}
                            </Text>
                            <Icon
                              as={AiOutlineClose}
                              color="gray.600"
                              fontSize="12px"
                              _hover={{
                                cursor: 'pointer',
                              }}
                              onClick={() => closeClickToUpload()}
                            />
                          </Flex>
                        </Badge>
                      </Box>
                    ) : (
                      <Box
                        cursor="pointer"
                        onClick={event => {
                          event.stopPropagation();
                          onUploadOpen();
                          onClose();
                        }}
                      >
                        <Dragdrop2 />
                      </Box>
                    )}
                  </Box>

                  <Flex ml={6} mb={5}>
                    <Text
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="20px"
                      onClick={onUploadOpen}
                    >
                      Files
                    </Text>
                  </Flex>
                  <DrawerBody
                    px="6"
                    pt="0"
                    pb="0"
                    borderBottom="1px solid #E2E8F0"
                  >
                    {(reload || !reload) &&
                      uploadData?.map((upload_data, index) => (
                        <Flex key={index} mb="4" cursor={'pointer'}>
                          <Badge
                            bg="gray.500"
                            textTransform="initial"
                            px="2"
                            py="2px"
                            rounded="full"
                          >
                            <Flex
                              align="center"
                              onClick={() => {
                                window.open(upload_data.file_source, '_blank');
                              }}
                            >
                              <VscFile
                                color="white"
                                fontSize="14px"
                                _hover={{ cursor: 'pointer' }}
                              />
                              <Tooltip
                                hasArrow
                                label={upload_data.file_name}
                                bg="gray.800"
                                placement="top"
                              >
                                <Text
                                  pl="1"
                                  color="gray.100"
                                  fontFamily="Inter"
                                  fontStyle="normal"
                                  fontWeight="400"
                                  fontSize="14px"
                                >
                                  {truncate(upload_data.file_name)}
                                </Text>
                              </Tooltip>
                            </Flex>
                          </Badge>
                          <Spacer />
                          <Flex align="end">
                            <RiDeleteBinLine
                              cursor="pointer"
                              onClick={() => {
                                setSelFileId(upload_data.id);
                                setSelFileName(upload_data.file_name);
                                onDeleteOpen();
                              }}
                              color="#C53030"
                              fontSize="24px"
                            />
                          </Flex>
                        </Flex>
                      ))}
                  </DrawerBody>
                  <DrawerFooter
                    px="6"
                    pt="6"
                    pb="5"
                    display="inline-table"
                  ></DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Form>
          )}
        </Formik>
      </Box>
      <UploadFiles
        refresh={refresh}
        setRefresh={setRefresh}
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        kNuggetId={knowledgeNuggetId}
        tagD={tagD}
      />
      <Delete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onDelete={handleDelete}
        content="file"
      />
    </ChakraProvider>
  );
}

export default UploadedDrawerWithFiles;
