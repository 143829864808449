import { Center, ChakraProvider, Text, theme } from '@chakra-ui/react';
import React from 'react';

function Notification({ value }) {
  return (
    <ChakraProvider theme={theme}>
      <Center
        position="absolute"
        top="3px"
        ml="15px"
        w="18px"
        h="18px"
        rounded="full"
        bg="purple.600"
        color="white"
      >
        <Text
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="700"
          fontSize="12px"
        >
          {value}
        </Text>
      </Center>
    </ChakraProvider>
  );
}

export default Notification;
