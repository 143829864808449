import React from 'react';
import {
  Box,
  Center,
  ChakraProvider,
  Text,
  theme,
  Stack,
  useBreakpointValue,
  Image,
  Flex,
} from '@chakra-ui/react';

export const BannerCard = ({
  bannerBg,
  iconBg,
  tagColor,
  IconAndcolor,
  textLine,
  tagLine,
  onClick,
  firstUser,
}) => {
  return (
    <ChakraProvider theme={theme}>
      <Stack
        spacing={useBreakpointValue({
          base: '2',
          md: '3',
          lg: '2',
        })}
      >
        <Box
          cursor="pointer"
          bg={bannerBg} // Variable color
          p="4"
          minW={
            firstUser
              ? ['full', 'full']
              : ['full', 'full', 'full']
          }
          minH={
            firstUser ? ['84px','84px','84px', '163px'] : ['58px', '81px', '81px', '150px']
          }
          rounded="16px"
          boxShadow="base"
          onClick={onClick}
        >
          <Flex
            flexDirection={
              firstUser
                ? ['row', 'column', 'column', 'column']
                : ['row', 'row', 'row', 'column']
            }
            align={
              firstUser
                ? ['center', 'start', 'start', 'start']
                : ['center', 'center', 'center', 'start']
            }
            justify={
              firstUser ? ['start'] : ['center', 'space-between', 'start']
            }
            gap={[1, 1, 3, 0]}
            mt={firstUser ? [0, 0, 0, 0] : [-2, -2, -2, 0]}
          >
            <Center
              bg={iconBg} // Variable color
              w={['32px', '40px']}
              h={['32px', '40px']}
              align="center"
              rounded="16px"
              fontSize="17px"
            >
              {/* Variable icon and color */}
              <Image src={IconAndcolor} alt="Banner Icon" />
            </Center>
            <Box>
              <Text
                pt="2"
                pb="1"
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize={['12px', '16px']}
                noOfLines={2}
              >
                {textLine}
              </Text>
              <Text
                color={tagColor} // Variable color
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                noOfLines={2}
                display={
                  firstUser ? 'block' : ['none', 'block', 'block', 'block']
                }
              >
                {tagLine}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Stack>
    </ChakraProvider>
  );
};
