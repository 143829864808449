import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
  theme,
  Tooltip,
} from '@chakra-ui/react';
import { AiFillStar } from 'react-icons/ai';
import MaterialMenu from './MaterialMenu';
import ResourceDrawer from './ResourceDrawer';

function ResourceCard({
  type, //set the card type as nonsummery, resource, knowledgeKnugget etc
  id, //id of the relevent item
  projecId, //if inside a project, the relevent project id
  orgProjectData,
  projectData, //project data passed for add to project function
  iconBg, //icon background
  IconAndcolor, //icon color
  ResourceCover, //cover photo of the resource
  textLine, //main title text
  daysAgo, //date count of the last access
  dayAdded, //date count of the creation
  ratings, //rating
  reviews, //reviews
  handleProceed, //function call to go inside the relevent card object
  resourceData, //resource data
  nonsummaryhiveData, //nonsummery data
  knowledgeNuggetData, //knowledge nugget data
  elementDataRes, // data of the relevent object (for resource data)
  elementDataNon, //data of the relevent object (for nonsummery data)
  elementDataKn, //data of the relevent object (for knowledge nugget data)
  upateprojectData, //update projet data boolean value (from homepage)
  setUpdateprojectData, //set update project data method (from homepage)
  updatehivedata, //update hive data boolean value (from homepage)
  setUpdatehivedata, //set update hive data method (from homepage)
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  isproject, //boolean for check if its in  a project
}) {
  let ratingValue = 0;
  let totalRating = 0;

  if (ratings?.length > 0) {
    ratings?.map(rating_data => {
      totalRating += Number(rating_data?.rating);

      return null;
    });
    if (Array.isArray(ratings)) {
      ratingValue = totalRating / ratings.length;
    }
  } else {
    ratingValue = ratings;
  }

  return (
    <ChakraProvider theme={theme}>
      <Box
        p="2"
        pb="1"
        minWidth={['full', 'full', 'full', 'full']}
        bg="white"
        rounded="16px"
        boxShadow="base"
        cursor={'pointer'}
        minH="300px"
      >
        <Flex
          bg={'gray.50'}
          rounded="12px"
          align={'center'}
          justify="space-between"
          flexDir={'column'}
        >
          <Flex align={'center'} justify="space-between" w={'full'}>
            <Center
              m={2}
              float={'left'}
              p="8px"
              w="32px"
              h="32px"
              bg={iconBg} // Variable color
              align="center"
              rounded="12px"
              fontSize="14px"
              boxShadow="base"
            >
              {/* Variable icon and color */}
              <Image src={IconAndcolor} alt="Resource Icon" />
            </Center>
            <Spacer />
            <Center
              cursor="pointer"
              m={2}
              align="center"
              rounded="12px"
              fontSize="22px"
              color="gray.500"
              _hover={{ bgColor: 'gray.200' }}
            >
              {/* Variable icon and color */}
              <Box display={['none', 'flex']}>
                <MaterialMenu
                  type={type}
                  id={id}
                  projecId={projecId}
                  isproject={isproject}
                  orgProjectData={orgProjectData}
                  projectData={projectData}
                  resourceData={resourceData}
                  nonsummaryhiveData={nonsummaryhiveData}
                  knowledgeNuggetData={knowledgeNuggetData}
                  elementDataRes={elementDataRes}
                  elementDataNon={elementDataNon}
                  elementDataKn={elementDataKn}
                  upateprojectData={upateprojectData}
                  updatehivedata={updatehivedata}
                  setUpdatehivedata={setUpdatehivedata}
                  setUpdateprojectData={setUpdateprojectData}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                />
              </Box>
              {/* drawer container for small screens */}
              <Box display={['flex', 'none']}>
                <ResourceDrawer
                  type={type}
                  id={id}
                  projecId={projecId}
                  isproject={isproject}
                  projectData={projectData}
                  resourceData={resourceData}
                  nonsummaryhiveData={nonsummaryhiveData}
                  knowledgeNuggetData={knowledgeNuggetData}
                  elementDataRes={elementDataRes}
                  elementDataNon={elementDataNon}
                  elementDataKn={elementDataKn}
                  upateprojectData={upateprojectData}
                  updatehivedata={updatehivedata}
                  setUpdatehivedata={setUpdatehivedata}
                  setUpdateprojectData={setUpdateprojectData}
                  updateKnowledgeNuggetData={updateKnowledgeNuggetData}
                  setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
                />
              </Box>
            </Center>
          </Flex>
          {/* Variable image */}
          <Box mb={10}>
            <Image
              src={ResourceCover}
              alt="Resource Cover"
              onClick={handleProceed}
              w={['125px']}
              h={['90px']}
              objectFit="cover"
            />
          </Box>
        </Flex>
        <Box onClick={handleProceed}>
          <Tooltip
            hasArrow
            rounded={'8px'}
            backdropFilter="blur(10px) saturate(180%)"
            bg="gray.200"
            placement="top"
            color="gray.600"
            label={textLine}
            zIndex={2}
          >
            <Text
              fontFamily="Inter"
              fontWeight="700"
              fontSize="14px"
              color="#000"
              pt="4"
              pb="1"
              isTruncated
            >
              {/* Variable topic */}
              {textLine}
            </Text>
          </Tooltip>
          <Text
            fontFamily="Inter"
            fontWeight="400"
            fontSize="14px"
            color="gray.500"
            noOfLines={2}
          >
            {/* Variable days */}
            {daysAgo === 0 && dayAdded === 0
              ? 'Added Today'
              : daysAgo === 0
              ? 'viewed Today'
              : `viewed ${daysAgo} days ago`}
          </Text>
          <Flex>
            {ratingValue ? (
              <Box>
                {Array(Math.trunc(Number(ratingValue))) // Variable ratings
                  .fill('')
                  .map((_, i) => (
                    <Icon
                      as={AiFillStar}
                      color="yellow.400"
                      borderColor={'yellow.400'}
                      border={'1.5px'}
                      fontSize="20px"
                      mr="1"
                      key={i}
                    />
                  ))}
                {Array(5 - Math.trunc(Number(ratingValue)))
                  .fill('')
                  .map((_, i) => (
                    <Icon
                      as={AiFillStar}
                      color="gray.200"
                      fontSize="20px"
                      mr="1"
                      key={i}
                    />
                  ))}
              </Box>
            ) : null}
            {reviews ? (
              <Box>
                <Text
                  p="1"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  color="gray.600"
                  noOfLines={2}
                >
                  {/* Variable reviews */}
                  {reviews} reviews
                </Text>
              </Box>
            ) : null}
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default ResourceCard;
