import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../KnowledgeNuggetMindMap/MindMap';

function MapTab({
  lesson,
  editStateVal,
  editTagModalXVal,
  editTagModalYVal,
  pauseStateVal,
  mdLeftVal,
  lgLeftVal,
}) {
  const lessonData = lesson;
  const editState = editStateVal;
  const editTagModalX = editTagModalXVal;
  const editTagModalY = editTagModalYVal;
  const pauseState = pauseStateVal;

  return (
    <ChakraProvider theme={theme}>
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        editStateVal={editState}
        lesson={lessonData}
        editTagModalXVal={editTagModalX}
        editTagModalYVal={editTagModalY}
        pauseStateVal={pauseState}
      />
      <SummaryPlayer w="100%" />
    </ChakraProvider>
  );
}

export default MapTab;
