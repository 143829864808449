import React, { useState } from 'react';
import {
  Box,
  Flex,
  Spacer,
  Button,
  Heading,
  Image,
  Stack,
  Icon,
  Avatar,
  useBreakpointValue,
  useDisclosure,
  // Img,
  Tooltip,
  Center,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import Logo from '../../assests/Logos/HeaderLogo.png';
// import BulbLogo from '../../assests/Icons/Group 1.png';
import blacklogo from '../../assests/Elephant-hive-Logo-Top.png';
import { useLocation, useHistory } from 'react-router-dom';
import ProfileViewer from './ProfileViewer';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import CreateKnowledgeNugget from '../KnowledgeNugget/CreateKnoledgeNugget';
import { AiOutlineUser } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { motion } from 'framer-motion';

function Header2({
  haveResourceData,
  haveNonsummeryData,
  haveKnowledgeNuggetData,
  haveProjectdata,
  updatehivedata,
  setUpdatehivedata,
  storageUsed,
  handleSearchClose,
}) {
  let userLocalData = JSON.parse(localStorage.user);
  const location = useLocation();
  const history = useHistory();
  const [click, setClick] = useState(false);

  const handleClick = () => {
    onMenuOpen();
    setClick(!click);
  };

  const {
    isOpen: isCreateKnowledgeNuggetOpen,
    onOpen: onCreateKnowledgeNuggetOpen,
    onClose: onCreateKnowledgeNuggetClose,
  } = useDisclosure();

  const {
    onOpen: onMenuOpen,
    isOpen: isMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();

  const coloronsizes = useBreakpointValue({
    base: 'white',
    sm: 'white',
    md: 'white',
    lg: 'gray.700',
  });
  const coloronText = useBreakpointValue({
    base: 'gray.700',
    sm: 'gray.700',
    md: 'gray.700',
    lg: 'white',
  });
  const logoOnSize = useBreakpointValue({
    base: blacklogo,
    sm: blacklogo,
    md: blacklogo,
    lg: Logo,
  });

  return (
    <Box>
      <Box
        bg="white"
        border={
          haveResourceData ||
          haveNonsummeryData ||
          haveKnowledgeNuggetData ||
          haveProjectdata
            ? 'none'
            : '1px solid #E2E8F0'
        }
        position="sticky"
        zIndex="100"
        top="0"
        w="full"
      >
        <Flex align={'center'} my={['2', '2', '0']} mx={['2', '2', '0']}>
          <Flex align={'center'} justify="space-between">
            {/* logo with name part in black */}
            <Box
              w="388px"
              py={2}
              bg={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveProjectdata)
                  ? coloronsizes
                  : 'white'
              }
              overflow="hidden"
              display={['none', 'none', 'flex']}
              alignItems="start"
              justifyContent="start"
            >
              <Stack
                isInline
                align="center"
                overflow="hidden"
                onClick={() => {
                  if (location.pathname === '/homepage') {
                    handleSearchClose();
                    history.push('/homepage');
                  }else{
                    history.push('/homepage');
                  }
                }}
                cursor={'pointer'}
                ml={4}
              >
                <Image
                  display={['none', 'none', 'flex']}
                  src={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveProjectdata)
                      ? logoOnSize
                      : blacklogo
                  }
                  alt="The Elephant Hive Logo"
                  w={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveProjectdata)
                      ? '44.2px'
                      : '54.73px'
                  }
                />
                <Heading
                  display={['none', 'flex']}
                  fontSize="22px"
                  fontWeight={'bold'}
                  letterSpacing="1px"
                  color={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveProjectdata)
                      ? coloronText
                      : 'black'
                  }
                  fontFamily="body"
                >
                  Elephant Hive
                </Heading>
              </Stack>
            </Box>
            {/* hamberger menu in mobile view */}
            <Menu onClose={onMenuClose}>
              <MenuButton as={'button'} onClick={() => handleClick()}>
                <Box
                  display={['flex', 'flex', 'none']}
                  alignItems="center"
                  justifyContent="center"
                  ml={4}
                  pt={click ? '1' : '0'}
                  cursor="pointer"
                  overflow="hidden"
                >
                  {isMenuOpen ? (
                    <GrClose size={24} />
                  ) : (
                    <AiOutlineMenu size={24} />
                  )}
                </Box>
              </MenuButton>
              <MenuList>
                {onMenuOpen && (
                  <ProfileViewer
                    onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
                    userLocalData={userLocalData}
                    handleClick={handleClick}
                    storageUsed={storageUsed}
                  />
                )}
              </MenuList>
            </Menu>
          </Flex>
          {/* spacer in middle  */}
          <Spacer />
          {/* middle elephanthive logo */}
          <Box
            display="flex"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            pt={click ? '2' : '2'}
            mr={click ? ['0'] : ['0', '0', '5', 1]}
          >
            <Image
              display={['flex', 'flex', 'none']}
              src={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveProjectdata)
                  ? logoOnSize
                  : blacklogo
              }
              alt="The Elephant Hive Logo"
              w={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveProjectdata)
                  ? '44.2px'
                  : '54.73px'
              }
              h="31.22px"
              mb={click ? ['4', '0'] : ['0']}
              onClick={() => {
                if (location.pathname === '/homepage') {
                  handleSearchClose();
                  history.push('/homepage');
                }else{
                  history.push('/homepage');
                }
              }}
            />
          </Box>

          {/* right section */}
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            py="4"
            mr={{ md: '6' }}
          >
            {/* empty button with question mark */}
            <Tooltip
              label="For Help, click on the yellow chat icon at the bottom of your screen"
              placement="bottom-start"
              color="gray.500"
              bg="gray.100"
            >
              <Button
                display={['none', 'none', 'flex']}
                color="gray.500"
                bg="gray.100"
                fontSize="18px"
                rounded="full"
                p="3"
                mr={['0', '6']}
              >
                <Icon />
              </Button>
            </Tooltip>
            <Menu onClose={onMenuClose}>
              <MenuButton as={'button'} onClick={() => handleClick()}>
                <Box
                  display={['none', 'none', 'flex']}
                  w={'50px'}
                  h={'50px'}
                  border="4px"
                  borderColor={isMenuOpen ? 'yellow.500' : 'white'}
                  rounded="full"
                  objectFit="cover"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar
                    as={motion.div}
                    colorScheme={'yellow'}
                    animate={{
                      scale: click ? [1, 1.1] : [1, 1],
                    }}
                    initial="initial"
                    icon={
                      <Box
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                        mr={5}
                        mb={5}
                      >
                        <Center
                          zIndex={1}
                          position="absolute"
                          top={'50%'}
                          left={'50%'}
                          as={motion.div}
                          animate={{
                            scale: click ? [1, 1.3] : [1.4, 1.0],
                            opacity: [0, 1],

                            transition: {
                              delay: 12.4,
                            },
                          }}
                          initial={{
                            opacity: 0,
                          }}
                        >
                          <AiOutlineUser
                            style={{ border: '0.7px', color: '#718096' }}
                          />
                        </Center>
                        <Center
                          as={motion.div}
                          position="absolute"
                          top={'50%'}
                          left={'50%'}
                          animate={{
                            scale: [2.3, 1.4],
                            opacity: [1, 0],

                            transition: {
                              duration: 10.0,
                            },
                          }}
                        >
                          <BiUserCircle
                            style={{ border: '0.4px', color: '#718096' }}
                          />
                        </Center>
                      </Box>
                    }
                    cursor={'pointer'}
                    w="42px"
                    h="42px"
                    bg={'gray.200'}
                  />
                </Box>
              </MenuButton>
              <MenuList>
                {onMenuOpen && (
                  <ProfileViewer
                    onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
                    userLocalData={userLocalData}
                    handleClick={handleClick}
                    storageUsed={storageUsed}
                  />
                )}
              </MenuList>
            </Menu>
          </Box>
          {onCreateKnowledgeNuggetOpen && (
            <CreateKnowledgeNugget
              updateKnowledgeNuggetData={updatehivedata}
              setUpdateKnowledgeNuggetData={setUpdatehivedata}
              isOpen={isCreateKnowledgeNuggetOpen}
              onClose={onCreateKnowledgeNuggetClose}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
}

export default Header2;
