import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FiPause } from 'react-icons/fi';
// import { FiSkipForward } from 'react-icons/fi';
// import { FiSkipBack } from 'react-icons/fi';
import { BsPlay } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import extendTheme from '../../extendTheme';
import useSpeechSynthesis from '../KnowledgeNugget/UseSpeech';

const summary = [];
function SummaryPlayer({ w, lesson, pauseStateVal }) {
  const history = useHistory();
  const [isPlaying, setPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [speechTxtLength, setSpeechTxtLength] = useState(0);
  const [time, setTime] = useState(0);
  const { speak, cancel } = useSpeechSynthesis();
  const lessonData = lesson;
  const pauseState = pauseStateVal;

  const unlisten = history.listen(({ action, location }) => {
    if (action === undefined) {
      cancel();
      setPlaying(false);
      setIsActive(false);
      setPlaying(false);
      setTime(0);
    }
  });

  useEffect(() => {
    cancel();
    setPlaying(false);
    setIsActive(false);
    setPlaying(false);
    setTime(0);
    unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonData]);

  useEffect(() => {
    cancel();
    setPlaying(false);
    setIsActive(false);
    setPlaying(false);
    setTime(0);
    unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseState]);

  lessonData?.map(lesson_data => {
    lesson_data.module.lesson.map(lesson_in_data => {
      summary.splice(0, summary.length);
      lesson_in_data.initialNodes.map(initial_nodes_data => {
        if (initial_nodes_data.className !== 'main-node') {
          summary.push(initial_nodes_data?.data?.label);
        }

        return null;
      });

      return null;
    });

    return null;
  });

  React.useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime(time => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const playPause = () => {
    if (isPlaying) {
      cancel();
      setPlaying(false);
      setIsActive(false);
      setTime(0);
    } else {
      let speechText = '';
      summary.map(summary_data => {
        speechText += summary_data;

        return null;
      });
      speak({
        text: speechText,
        default: true,
        lang: 'en-AU',
        localService: true,
        name: 'Karen',
        voiceURI: 'Karen',
      });
      setSpeechTxtLength(speechText.length);
      setPlaying(true);
      setIsPaused(!isPaused);
      setIsActive(true);
      setIsPaused(false);
    }
  };

  useEffect(() => {
    if (((time * 1.5) / speechTxtLength).toFixed(0) > 100) {
      setPlaying(false);
      setIsActive(false);
      setTime(0);
    }
  }, [time, speechTxtLength]);

  return (
    <ChakraProvider theme={extendTheme}>
      <Box
        borderTop="1px solid #E2E8F0"
        bg="white"
        w={{ base: '100%', sm: w, md: w, lg: w }}
        h="120px"
        position="fixed"
        zIndex={2}
        bottom="0"
        p="4"
        float="left"
      >
        <Box>
          <Slider
            aria-label="slider-ex-2"
            value={((time * 1.5) / speechTxtLength || 0).toFixed(0)}
            p="0"
          >
            <SliderTrack bg="gray.300" p="0" h="6px" rounded="2px">
              <SliderFilledTrack bg="yellow.400" />
            </SliderTrack>
            <SliderThumb
              boxShadow="base"
              boxSize="4"
              _focus={{
                outline: 'none',
              }}
            />
          </Slider>
          <Text
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
          >
            {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
            {('0' + Math.floor((time / 1000) % 60)).slice(-2)}
          </Text>
          <Center>
            <Flex pt="1">
              {/* <Button
                p="0"
                color="gray.500"
                _hover={{ color: 'gray.900' }}
                _focus={{
                  outline: 'none',
                }}
                bg="white"
                border="1px solid #E2E8F0"
              >
                <Box fontSize="20px">
                  <FiSkipBack />
                </Box>
              </Button> */}
              <Button
                mx="4"
                p="0"
                bg="purple.600"
                color="white"
                _hover={{}}
                _focus={{
                  outline: 'none',
                }}
                _active={{ bg: 'purple.500' }}
                onClick={playPause}
              >
                <Box fontSize="20px" align="center">
                  {isPlaying ? <FiPause /> : <BsPlay fontSize="25px" />}
                </Box>
              </Button>
              {/* <Button
                p="0"
                color="gray.500"
                _hover={{ color: 'gray.900' }}
                _focus={{
                  outline: 'none',
                }}
                bg="white"
                border="1px solid #E2E8F0"
              >
                <Box fontSize="20px">
                  <FiSkipForward />
                </Box>
              </Button> */}
            </Flex>
          </Center>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default SummaryPlayer;
