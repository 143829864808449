import {
  Box,
  ChakraProvider,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import extendTheme from '../../extendTheme';

const summary = [];
let lessonCode = '';
let lessonName = '';

function SummaryList({ lesson, bgw, w, al }) {
  const lessonData = lesson;

  lessonData?.map(lesson_data => {
    lesson_data.module.lesson.map(lesson_in_data => {
      lessonCode = lesson_in_data.code;
      lessonName = lesson_in_data.name;
      summary.splice(0, summary.length);
      lesson_in_data.initialNodes.map(initial_nodes_data => {
        if (initial_nodes_data.className !== 'main-node') {
          summary.push(initial_nodes_data?.data?.label);
        }

        return null;
      });

      return null;
    });

    return null;
  });

  return (
    <ChakraProvider theme={extendTheme}>
      <Box bg="white" h="90vh" w={bgw} float="left" position="fixed" zIndex={2}>
        <Box
          px={{ base: '4', sm: '4', md: '4', lg: '6' }}
          bg="white"
          w={bgw}
          h="100vh"
          overflow="auto"
          pb="0"
          pt="6"
        >
          <Box bg="white" align={al}>
            <Box w={w}>
              <Flex align="center">
                <Box>
                  <AiOutlinePlayCircle color="#6B46C1" size="18px" />
                </Box>
                <Text
                  pl={{ base: '3', md: '4', lg: '6' }}
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize={{
                    base: '16px',
                    sm: '20px',
                    md: '20px',
                    lg: '20px',
                  }}
                >
                  {lessonCode} {lessonName}
                </Text>
              </Flex>
              <Box
                m="0"
                pt="4"
                pl={{ base: '4', md: '5', lg: '10' }}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize={{ base: '14px', md: '14px', lg: '16px' }}
              >
                <UnorderedList pb="500px" textAlign="left">
                  {summary?.map(item => (
                    <ListItem key={item.length}>{item}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default SummaryList;
