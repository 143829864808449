import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logout } from "../utils/index";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
class AuthVerify extends Component {
  constructor(props) {
    super(props);
    props.history.listen(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('login_token');
      if (user) {
        const decodedJwt = parseJwt(token);
        if (decodedJwt?.exp * 1000 < Date.now() / 1000) {
          props.logOut();
          logout()
          props.history('/')
        }
      }
    });
  }
  render() {
    return <div></div>;
  }
}
export default withRouter(AuthVerify);
