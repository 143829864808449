import {
  Box,
  SimpleGrid,
  useDisclosure,
  ChakraProvider,
} from '@chakra-ui/react';
import React from 'react';
import { BannerCard } from './BannerCard';
import ClaimResource from './ClaimResource';
import extendTheme from '../../../extendTheme';
import KNImg from '../../../assests/Icons/HomePGCard/Knowledge_nugget.png';
// commented out for new pilot project ui design
/////////////////////////////////////////////////////////////////////////////////////
// import UHImg from '../../../assests/Icons/HomePGCard/Upload_to_hive.png';
// import CPImg from '../../../assests/Icons/HomePGCard/Add_project.png';
// import CCImg from '../../../assests/Icons/HomePGCard/Claim_course.png';
////////////////////////////////////////////////////////////////////////////////////////
import BulbLogo from '../../../assests/Icons/Group 1.png';
import CreateKnowledgeNugget from '../../KnowledgeNugget/CreateKnoledgeNugget';
import UploadToHive from './HiveUploadContainer/UploadToHive';
import CreateProject from './ProjectComps/CreateProject';
import AddToProject from './ProjectComps/AddToProject';
import { useHistory } from 'react-router-dom';

const Banner = ({
  onClaimResourceSubmit,
  setUpdatehivedata,
  updatehivedata,
  nonsummaryhiveData,
  resourceData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  projectData,
  // upateprojectData,
  // setUpdateprojectData,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  userInfo,
  storageUsed,
}) => {
  const {
    isOpen: isCreateKnowledgeNuggetOpen,
    onOpen: onCreateKnowledgeNuggetOpen,
    onClose: onCreateKnowledgeNuggetClose,
  } = useDisclosure();
  const {
    isOpen: isClaimResourceOpen,
    onOpen: onClaimResourceOpen,
    onClose: onClaimResourceClose,
  } = useDisclosure();
  const {
    isOpen: isUploadToHiveOpen,
    onOpen: onUploadToHiveOpen,
    onClose: onUploadToHiveClose,
  } = useDisclosure();
  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose,
  } = useDisclosure();
  const {
    isOpen: isAddToProjectOpen,
    onOpen: onAddToProjectOpen,
    onClose: onAddToProjectClose,
  } = useDisclosure();

  const handleClaimResourceFinish = values => {
    onClaimResourceSubmit(values);
  };

  const history = useHistory();

  const data = [
    {
      banner_bg: 'yellow.400',
      icon_bg: 'white',
      tag_color: 'gray.700',
      icon_and_color: KNImg,
      text_line: 'Create Your Summary',
      tag_line: 'Summarize your learning content',
      on_click: () => onCreateKnowledgeNuggetOpen(),
    },
    // comented out for new pilot project ui design
    /////////////////////////////////////////////////////////
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: UHImg,
    //   text_line: 'Upload to Hive',
    //   tag_line: 'Save learning content',
    //   on_click: () => onUploadToHiveOpen(),
    // },
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: CPImg,
    //   text_line: 'Create Project',
    //   tag_line: 'Organize your knowledge',
    //   on_click: () => onCreateProjectOpen(),
    // },
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: CCImg,
    //   text_line: 'Claim Course',
    //   tag_line: 'Access your institution course',
    //   on_click: () => onClaimResourceOpen(),
    // },
    //////////////////////////////////////////////////////////////
    {
      banner_bg: 'white',
      icon_bg: 'gray.200',
      tag_color: 'gray.500',
      icon_and_color: BulbLogo,
      text_line: 'Get Recommended Summaries',
      tag_line: 'Add ready-made summaries to your Hive',
      on_click: () => history.push('/knowledge-library'),
    },
  ];

  return (
    <ChakraProvider theme={extendTheme}>
      <Box p="4" overflow="auto" /*borderBottom="1px solid #E2E8F0"*/>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 2 }}
          spacing={['2', '2', '4', '8', '8']}
          justifyContent="space-between"
        >
          {data?.map((card_data, indexNo) => (
            <BannerCard
              key={indexNo}
              bannerBg={card_data.banner_bg}
              iconBg={card_data.icon_bg}
              tagColor={card_data.tag_color}
              IconAndcolor={card_data.icon_and_color}
              textLine={card_data.text_line}
              tagLine={card_data.tag_line}
              onClick={card_data.on_click}
              firstUser={false}
            />
          ))}
        </SimpleGrid>
        {onCreateKnowledgeNuggetOpen && (
          <CreateKnowledgeNugget
            isOpen={isCreateKnowledgeNuggetOpen}
            onClose={onCreateKnowledgeNuggetClose}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            nonsummaryhiveData={nonsummaryhiveData}
            resourceData={resourceData}
            storageUsed={storageUsed}
          />
        )}
        {onUploadToHiveOpen && (
          <UploadToHive
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            isOpen={isUploadToHiveOpen}
            onClose={onUploadToHiveClose}
            onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
            nonsummaryhiveData={nonsummaryhiveData}
            resourceData={resourceData}
            storageUsed={storageUsed}
          />
        )}
        {onCreateProjectOpen && (
          <CreateProject
            isOpen={isCreateProjectOpen}
            onClose={onCreateProjectClose}
            nonsummaryhiveData={nonsummaryhiveData}
            resourceData={resourceData}
            knowledgeNuggetData={knowledgeNuggetData}
            knowledgeLibraryData={knowledgeLibraryData}
            userInfo={userInfo}
            projectData={projectData}
          />
        )}
        {onClaimResourceOpen && (
          <ClaimResource
            isOpen={isClaimResourceOpen}
            onClose={onClaimResourceClose}
            onFinish={handleClaimResourceFinish}
          />
        )}
        {onAddToProjectOpen && (
          <AddToProject
            isOpen={isAddToProjectOpen}
            onClose={onAddToProjectClose}
          />
        )}
      </Box>
    </ChakraProvider>
  );
};

export default Banner;
