import React, { useEffect, useState } from 'react';
import { Box} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import TabController from '../Components/KnowledgeNugget/TabViews/TabController';
import Loading from '../Components/Loading/Loading';
import axios from 'axios';
import { useIntercom } from 'react-use-intercom';
import Header2 from '../Components/Header/Header2';

const KnowledgeNugget = () => {
  const location = useLocation();
  const userLocalData = JSON.parse(localStorage.getItem('user'));
  const { boot, shutdown } = useIntercom();
  const knowledgeNuggetId = new URLSearchParams(location.search).get(
    'knowledge_nugget_id'
  );
  const [storageUsed, setStorageUsed] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lessonData, setLessonData] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id, refresh]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;

    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?_id=${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(knowledgeNuggetResponse => {
        const tempKnowledgeNuggetData = [];
        knowledgeNuggetResponse.data.map(knowledge_nugget_data => {
          tempKnowledgeNuggetData.push({
            file_name: knowledge_nugget_data.knowledgeNugget.file_name,
            type: knowledge_nugget_data.knowledgeNugget.type,
            module: {
              _id: knowledge_nugget_data.knowledgeNugget._id,
              lesson: knowledge_nugget_data.knowledgeNugget.lesson,
            },
            tags: knowledge_nugget_data.knowledgeNugget.tags,
            metadata: knowledge_nugget_data.knowledgeNugget.hasOwnProperty(
              'metadata'
            )
              ? knowledge_nugget_data.knowledgeNugget.metadata
              : '',
            notes: knowledge_nugget_data.knowledgeNugget.notes,
          });

          return null;
        });
        setLessonData(tempKnowledgeNuggetData);
        setLoading(false);
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, knowledgeNuggetId]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header2 storageUsed={storageUsed} />
          <Box overflowY={'auto'} h={'calc(100vh - 72px)'}>
            <TabController
              lesson={lessonData}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default KnowledgeNugget;
