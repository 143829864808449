import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ChakraProvider,
  Flex,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import gtag from 'ga-gtag';

function numLessons(modules) {
  let numLessons = 0;
  for (let module of modules) {
    numLessons += module.lessons.length;
  }
  return numLessons;
}

function ResourceStructure({ resourceId, resourceName, modules }) {
  let history = useHistory();
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
  const userLocalData = JSON.parse(localStorage.user);

  const onClickHandler = (
    lessonId,
    lessonCode,
    lessonName,
    moduleId,
    moduleName,
    numberOfLessons
  ) => {
    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
    gtag('event', 'access_course_module_lesson', {
      course_id: `${resourceId}`,
      course_name: `${resourceName}`,
      module_id: `${moduleId}`,
      module_name: `${moduleName}`,
      lesson_id: `${lessonId}`,
      lesson_code: `${lessonCode}`,
      lesson_name: `${lessonName}`,
      userid: `${userLocalData._id}`,
      full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
      verified: `${userLocalData.isVerified}`,
    });
    history.push(
      `/resource/overview/lessonsummary?resource_id=${resourceId}&resource_name=${resourceName}&module_id=${moduleId}&module_name=${moduleName}&lesson_id=${lessonId}&lesson_code=${lessonCode}&lesson_name=${lessonName}&lesson_count=${numberOfLessons}`
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box mt="-1px" bg="white" color="black">
        <Box
          px="12"
          py="6"
          color="gray.900"
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="700"
          fontSize="18px"
          border="1px solid #E2E8F0"
        >
          <Text>Structure</Text>
        </Box>
        <Box ml={[4, 0]} mt={[4, 0]} p={{ sm: '6', md: '8', lg: '12' }}>
          <Text
            pb="20px"
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="700"
            fontSize={['14px', '16px']}
          >
            {modules.length} modules
            <Text as="span" pl="4" fontWeight="400">
              {numLessons(modules)} lessons
            </Text>
          </Text>
          <Box>
            {modules.map((module, index) => (
              <Accordion allowMultiple key={index}>
                <AccordionItem
                  my="1"
                  w={{
                    base: '100%',
                    /*sm: '365px',*/ md: '705px',
                    lg: '797px',
                  }}
                  border="1px solid #E2E8F0"
                >
                  <AccordionButton
                    py={{ base: '2', sm: '4' }}
                    px={{ base: '2', sm: '8' }}
                    color="gray.900"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontSize={['14px', '14px', '18px']}
                    _focus={{
                      outline: 'none',
                    }}
                    _expanded={{ color: 'purple.500' }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Flex
                      flexDir={['column', 'row']}
                      display={['flex', 'block']}
                    >
                      <Text fontWeight="700" flex="1" textAlign="left">
                        {module.module_name}
                        <Text
                          as="span"
                          pl="4"
                          color="gray.600"
                          fontWeight="400"
                          display={['none', 'inline']}
                          fontSize={['12px', '14px', '18px']}
                        >
                          {module.lessons.length} lessons
                        </Text>
                      </Text>
                      <Text
                        color="gray.600"
                        display={['flex', 'none']}
                        fontSize={['12px', '18px']}
                        fontWeight="400"
                      >
                        {module.lessons.length} lessons
                      </Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  {module.lessons.map(lesson => (
                    <AccordionPanel
                      key={lesson.id}
                      py="4"
                      px="14"
                      color="black"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="16px"
                      onClick={() =>
                        onClickHandler(
                          lesson.id,
                          lesson.code,
                          lesson.name,
                          module._id,
                          module.module_name,
                          module.lessons.length
                        )
                      }
                      cursor="pointer"
                    >
                      {`${lesson.code} ${lesson.name}`}
                    </AccordionPanel>
                  ))}
                  <AccordionPanel pb="4"></AccordionPanel>
                </AccordionItem>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default ResourceStructure;
