import {
  Badge,
  Box,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  Button,
  Flex,
  Spacer,
  Text,
  theme,
  useDisclosure,
  useToast,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { VscFile } from 'react-icons/vsc';
import axios from 'axios';
import { RiDeleteBinLine } from 'react-icons/ri';
import Delete from './Delete';
import { useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';

function UploadedDrawer({ isOpen, onClose, refresh, setRefresh }) {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const location = useLocation();
  const [uploadData, setUploadData] = useState();
  const moduleId = new URLSearchParams(location.search).get('module_id');
  const lessonId = new URLSearchParams(location.search).get('lesson_id');
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [reload, setReload] = useState(false);
  const [selFileId, setSelFileId] = useState();
  const [selFileName, setSelFileName] = useState();
  const userLocalData = JSON.parse(localStorage.user);
  const userId = userLocalData._id;

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [alert, alertType, toast, toastposition]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(lessonResponse => {
        // const data = []
        const uploadFileArr = [];
        lessonResponse.data?.map(module_data => {
          module_data.module.lesson.map(lesson_data => {
            lesson_data.uploadFiles?.map(upload_files => {
              var fileName = /[^/]*$/.exec(upload_files.file_source)[0];
              if (upload_files.user_id === userId) {
                uploadFileArr.push({
                  id: upload_files.id,
                  code: lesson_data.code,
                  name: lesson_data.name,
                  file_name: fileName,
                  file_source: upload_files.file_source,
                });
              }
              return null;
            });

            return null;
          });

          return null;
        });

        setUploadData(uploadFileArr);
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, lessonId, reload, refresh]);

  const handleDelete = () => {
    // delete function when confirm delete button is clicked
    const userLocalData = JSON.parse(localStorage.user);
    const userId = userLocalData._id;
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/modules/file/delete/${userId}/${moduleId}/${lessonId}/${selFileId}/${selFileName}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          alertHandler({
            status: responseData.data.status,
            message: responseData.data.message,
          });
          onClose();
          isOpen = false;
          setReload(!reload);
          setRefresh(!refresh);
        } else {
          alertHandler({
            status: 'error',
            message: 'Failed! Please try again later.',
          });
          onClose();
          isOpen = false;
        }
      })
      .catch(error => {
        return error;
      });
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerContent
            pt="5"
            shadow="lg"
            h="90vh"
            position="absolute"
            top="131px !important"
          >
            <DrawerHeader py="1px" px="6" mb="7">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                >
                  Upload files
                </Text>
                <Spacer />
                <Box py="0px" px="3">
                  <Icon
                    as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
            </DrawerHeader>
            <DrawerBody px="6" pt="0" pb="0" borderBottom="1px solid #E2E8F0">
              {uploadData?.map((upload_data, index) => (
                <Flex key={index} mb="4">
                  <Badge
                    bg="gray.500"
                    textTransform="initial"
                    px="2"
                    py="2px"
                    rounded="full"
                  >
                    <Flex align="center">
                      <VscFile
                        color="white"
                        fontSize="14px"
                        _hover={{ cursor: 'pointer' }}
                      />
                      <Text
                        pl="1"
                        color="gray.100"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                      >
                        {upload_data.file_name}
                      </Text>
                    </Flex>
                  </Badge>
                  <Spacer />
                  <Flex align="end">
                    <Text
                      pr="5"
                      color="gray.500"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="14px"
                    >
                      in {upload_data.code} {upload_data.name}
                    </Text>
                    <RiDeleteBinLine
                      cursor="pointer"
                      onClick={() => {
                        setSelFileId(upload_data.id);
                        setSelFileName(upload_data.file_name);
                        onDeleteOpen();
                      }}
                      color="#C53030"
                      fontSize="24px"
                    />
                  </Flex>
                </Flex>
              ))}
            </DrawerBody>
            <DrawerFooter px="6" pt="6" pb="5" display="inline-table">
              <Box align="right">
                <Button
                  type="submit"
                  py="5.5px"
                  px="3"
                  bg="yellow.400"
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="14px"
                  _hover={{ bg: 'yellow.500' }}
                  _active={{
                    bg: 'yellow.500',
                    transform: 'scale(0.98)',
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                >
                  Upload
                </Button>
              </Box>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
      <Delete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onDelete={handleDelete}
        content="file"
      />
    </ChakraProvider>
  );
}

export default UploadedDrawer;
