import React from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';

function SelectItem({ selected, Icon, projectName }) {
  return (
    <Box
      cursor="pointer"
      // w={["311px","324px","324px","238px"]}
      w="full"
      p="2"
      bg={selected ? 'purple.200' : 'purple.100'}
      shadow="base"
      rounded="16px"
      border={selected ? '1px solid #6B46C1' : '1px solid #E9D8FD'}
    >
      <Flex align="center">
        <Center w="8" shadow="base" rounded="12px" bg="white">
          <Image src={Icon} p="2" />
        </Center>
        <Text
          px="2"
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="700"
          fontSize="14px"
        >
          {projectName}
        </Text>
        <Spacer />
        {selected ? (
          <BsCheckCircle fontSize="20px" color="#6B46C1" bg="purple.200" />
        ) : (
          ''
        )}
      </Flex>
    </Box>
  );
}

export default SelectItem;
