import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../LessonSummaryMindMap/MindMap';
import extendTheme from '../../../extendTheme';

function ListNMapTab({ lesson, pauseStateVal, mdLeftVal, lgLeftVal }) {
  const lessonData = lesson;
  const pauseState = pauseStateVal;

  return (
    <ChakraProvider theme={extendTheme}>
      <SummaryList
        bgw={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          md: '298px',
          lg: '463px',
        }}
      />
      <SummaryPlayer
        pauseStateVal={pauseState}
        w={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          md: '298px',
          lg: '463px',
        }}
      />
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        lesson={lessonData}
      />
    </ChakraProvider>
  );
}
export default ListNMapTab;
