import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import CreateProject from './CreateProject';

function NoProjects({
  isOpen,
  onClose,
  resourceData,
  nonsummaryhiveData,
  upateprojectData,
  setUpdateprojectData,
}) {
  const cancelRef = React.useRef();
  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose,
  } = useDisclosure();

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay bg="rgba(0,0,0,0.48)">
          <AlertDialogContent rounded="16px" shadow="md">
            <AlertDialogHeader fontWeight="bold">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                  overflow={'hidden'}
                >
                  Add to Project
                </Text>
                <Spacer />
                <Box pr="10px">
                  <Icon
                    as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
            </AlertDialogHeader>
            <AlertDialogBody
              color="#2D3748"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
            >
              You don’t have any Projects yet,{' '}
              <Text
                as="span"
                textDecoration="underline"
                color="purple.500"
                cursor="pointer"
                onClick={() => {
                  onClose();
                  onCreateProjectOpen();
                }}
              >
                {' '}
                Create Project
              </Text>{' '}
              to organize your knowledge.
            </AlertDialogBody>
            <AlertDialogFooter textAlign="center">
              <Box w="50%" m="auto">
                <Button
                  px="7"
                  onClick={onClose}
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="16px"
                  bg="yellow.400"
                  rounded="4px"
                  _hover={{ bg: 'yellow.500' }}
                  _active={{
                    bg: 'yellow.500',
                    transform: 'scale(0.98)',
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                >
                  OK
                </Button>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {onCreateProjectOpen && (
        <CreateProject
          isOpen={isCreateProjectOpen}
          onClose={onCreateProjectClose}
          nonsummaryhiveData={nonsummaryhiveData}
          resourceData={resourceData}
          upateprojectData={upateprojectData}
          setUpdateprojectData={setUpdateprojectData}
        />
      )}
    </>
  );
}

export default NoProjects;
