import React, { useEffect, useState } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer';
import { MarkerType } from 'react-flow-renderer';
import ZoomController from '../ZoomController';
import './index.css';

let initialNodes = [];
let initialEdges = [];
const MindMap = ({ lesson, mdLeftVal, lgLeftVal }) => {
  const lessonData = lesson;
  const snapGrid = [100, 100];
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  let zoom = 0;
  const onConnect = params => setEdges(els => addEdge(params, els));
  const [nodeCount, setNodeCount] = useState(0);

  useEffect(() => {
    let nodeCount = 0;

    lessonData?.map(lesson_data => {
      lesson_data.module.lesson.map(lesson_data => {
        initialNodes = lesson_data.initialNodes;

        initialNodes.map(initial_node_data => {
          if (initial_node_data.className !== 'main-node') {
            nodeCount++;
          }

          return null;
        });

        return null;
      });

      return null;
    });

    setNodeCount(nodeCount);

    lessonData?.map(lesson_data => {
      lesson_data.module.lesson.map(lesson_data => {
        initialNodes = lesson_data.initialNodes;
        initialEdges = lesson_data.initialEdges;

        initialNodes.map((initial_node_data, index) => {
          if (initial_node_data.className === 'main-node') {
            initial_node_data.style = {
              background: '#ECC94B',
              color: '#333',
              border: '1px solid #ECC94B',
              width: 150,
              fontSize: 'large',
              fontWeight: 'bold',
            };

            initial_node_data.position = {
              x: 0,
              y: 200,
            };
          } else {
            initial_node_data.style = {
              background: '#FFFFFF',
              color: '#333',
              border: '1px solid #E2E8F0',
              width: 450,
              textAlign: 'justify',
              textjustify: 'inter-word',
            };
          }

          return null;
        });

        return null;
      });

      return null;
    });

    let result = initialEdges?.map(function (el) {
      let o = Object.assign({}, el);
      o.markerEnd = { type: MarkerType.ArrowClosed };
      return o;
    });

    setNodes(initialNodes);
    setEdges(result);
  }, [lessonData, setEdges, setNodes]);

  return (
    <>
      {nodeCount >= 15 ? (
        <>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodesDraggable={true}
            defaultZoom={zoom}
            snapToGrid={true}
            snapGrid={snapGrid}
            style={{
              display: 'inline-block',
              position: 'fixed',
              paddingTop: 2000,
              paddingLeft: 800,
              zIndex: 1,
            }}
          >
            <ZoomController />
          </ReactFlow>
        </>
      ) : nodeCount >= 12 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          defaultZoom={zoom}
          snapToGrid={true}
          snapGrid={snapGrid}
          style={{
            display: 'inline-block',
            position: 'fixed',
            paddingTop: 1500,
            paddingLeft: 2000,
            zIndex: 1,
          }}
        >
          <ZoomController />
        </ReactFlow>
      ) : nodeCount >= 9 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          defaultZoom={zoom}
          snapToGrid={true}
          snapGrid={snapGrid}
          style={{
            display: 'inline-block',
            position: 'fixed',
            paddingTop: 800,
            paddingLeft: 2000,
            zIndex: 1,
          }}
        >
          <ZoomController />
        </ReactFlow>
      ) : nodeCount >= 6 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          defaultZoom={zoom}
          snapToGrid={true}
          snapGrid={snapGrid}
          style={{
            display: 'inline-block',
            position: 'fixed',
            paddingTop: 500,
            paddingLeft: 2000,
            zIndex: 1,
          }}
        >
          <ZoomController
            mdLeftVal={mdLeftVal}
            lgLeftVal={lgLeftVal}
            zoom={zoom}
          />
        </ReactFlow>
      ) : nodeCount >= 3 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          defaultZoom={zoom}
          snapToGrid={true}
          snapGrid={snapGrid}
          style={{
            display: 'inline-block',
            position: 'fixed',
            paddingTop: 300,
            paddingLeft: 2000,
            zIndex: 1,
          }}
        >
          <ZoomController />
        </ReactFlow>
      ) : nodeCount >= 0 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          defaultZoom={zoom}
          snapToGrid={true}
          snapGrid={snapGrid}
          style={{
            display: 'inline-block',
            position: 'fixed',
            paddingTop: 200,
            paddingLeft: 2500,
            zIndex: 1,
          }}
        >
          <ZoomController />
        </ReactFlow>
      ) : (
        <></>
      )}
    </>
  );
};

export default MindMap;
