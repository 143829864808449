import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Axios from 'axios';
import * as yup from 'yup';
import gtag from 'ga-gtag';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

function ProjectNameedit({ isOpen, onClose, itemID, setUpdate, update }) {
  const [projectData, setProjectData] = useState();
  const [alert, setAlert] = useState(false);
  let userLocalData = JSON.parse(localStorage.user);

  const initialValues = {
    projectname: '',
  };
  const validationSchema = yup.object({
    projectname: yup.string().test('len', 'Project Name Must be less than  20 characters', val => val.length < 20).required('project name required'),
  });
  const onSubmit = async (values, actions) => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
    let userLocalData = JSON.parse(localStorage.user);

    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };
    if (
      projectData &&
      projectData?.find(project => project.project_name === values.projectname)
    ) {
      setAlert(true);
    } else {
      await Axios.put(
        `${REACT_APP_EH_BACKEND}/projectdata/${itemID}`,
        values,
        headers
      )
        .then(res => {
          if (res.status === 200) {
            gtag('event', 'edit_project_name', {
              new_project_name: `${values.projectname}`,
              project_id: `${itemID}`,
              userid: `${userLocalData._id}`,
              full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
              verified: `${userLocalData.isVerified}`,
            });
            actions.setSubmitting(false);
            actions.resetForm();
            setAlert(false);
            onClose();
            isOpen = false;
            setUpdate(!update);
          } else {
            gtag('event', 'exception', {
              process: `editing project name of project id: ${itemID} by ${userLocalData.firstName} ${userLocalData.lastName}`,
              description: `error occured while editing the project name of project id: "${itemID}" to new name as "${values.project_name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}`,
              fatal: false,
            });
            actions.setSubmitting(false);
            actions.setStatus({ msg: 'error' });
          }
        })
        .catch(err => {
          gtag('event', 'exception', {
            process: `editing project name of project id: ${itemID} by ${userLocalData.firstName} ${userLocalData.lastName}`,
            description: err,
            fatal: false,
          });
          return err;
        });
    }
  };

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(projectResponse => {
        setProjectData(projectResponse.data);
      })
      .catch(projectError => {
        return projectError;
      });
  }, [userLocalData._id]);

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight={'bold'} color="gray.900">
              Edit Name
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={1}>
              <Box>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {formik => (
                    <Form autoComplete="off">
                      <FormControl
                        isInvalid={
                          formik.errors.projectname &&
                          formik.touched.projectname
                        }
                      >
                        <FormLabel my={2} htmlFor="projectname">
                          <Text
                            fontSize="sm"
                            fontWeight="normal"
                            color="gray.900"
                            fontFamily={'body'}
                          >
                            Project name
                          </Text>
                        </FormLabel>
                        <Input
                          name="projectname"
                          id="projectname"
                          type="text"
                          placeholder="Enter new project name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.projectname}
                        />
                        <FormErrorMessage>
                          <ErrorMessage name="projectname" />
                        </FormErrorMessage>
                        {alert && (
                          <Text color="red.500" fontSize="sm">
                            Project name already exists
                          </Text>
                        )}
                      </FormControl>
                      <Button
                        my={3}
                        type="submit"
                        colorScheme="yellow"
                        isLoading={formik.isSubmitting}
                        isFullWidth
                      >
                        <Text
                          fontWeight="bold"
                          fontSize="18px"
                          color="gray.900"
                          fontFamily={'body'}
                        >
                          Update name
                        </Text>
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ProjectNameedit;
