import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../LessonSummaryMindMap/MindMap';

function MapTab({ lesson, pauseStateVal, mdLeftVal, lgLeftVal }) {
  const lessonData = lesson;
  const pauseState = pauseStateVal;

  return (
    <ChakraProvider theme={theme}>
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        lesson={lessonData}
      />
      <SummaryPlayer pauseStateVal={pauseState} w="100%" />
    </ChakraProvider>
  );
}
export default MapTab;
