import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../KnowledgeNuggetMindMap/MindMap';

function ListNMapTab({
  lesson,
  editStateVal,
  editCompleteVal,
  editTagModalXVal,
  editTagModalYVal,
  pauseStateVal,
  mdLeftVal,
  lgLeftVal,
  refresh,
  setRefresh,
}) {
  const lessonData = lesson;
  const editState = editStateVal;
  const editTagModalX = editTagModalXVal;
  const editTagModalY = editTagModalYVal;
  const pauseState = pauseStateVal;
  const editComplete = editCompleteVal;

  return (
    <ChakraProvider theme={theme}>
      <SummaryList
        editStateVal={editState}
        refresh={refresh}
        setRefresh={setRefresh}
        bgw={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          // md: '298px',
          md: '463px',
        }}
      />
      <SummaryPlayer
        w={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          // md: '298px',
          md: '463px',
        }}
      />
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        editStateVal={editState}
        editCompleteVal={editComplete}
        lesson={lessonData}
        editTagModalXVal={editTagModalX}
        editTagModalYVal={editTagModalY}
        pauseStateVal={pauseState}
      />
    </ChakraProvider>
  );
}
export default ListNMapTab;
