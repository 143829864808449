import {
  Box,
  Button,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerContent,
  // DrawerFooter,
  DrawerHeader,
  Editable,
  EditablePreview,
  EditableTextarea,
  FormControl,
  Flex,
  Spacer,
  Text,
  useDisclosure,
  FormErrorMessage,
  Icon,
} from '@chakra-ui/react';
import { Formik, Form, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import Delete from './Delete';
import axios from 'axios';
import * as yup from 'yup';
import extendTheme from '../../../extendTheme';
import { AiOutlineClose } from 'react-icons/ai';

function AddNotesDrawer({
  isOpen,
  onClose,
  update,
  setUpdate,
  refresh,
  setRefresh,
}) {
  const location = useLocation();
  let userLocalData = JSON.parse(localStorage.user);
  const { REACT_APP_EH_BACKEND } = process.env;
  const moduleId = new URLSearchParams(location.search).get('module_id');
  const lessonId = new URLSearchParams(location.search).get('lesson_id');
  const [noteData, setNoteData] = useState([]);
  const [noteDataLength, setNoteDataLength] = useState();
  const [noteId, setNoteId] = useState();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(lessonResponse => {
        const data = [];
        lessonResponse.data?.map(lesson_data => {
          lesson_data.module.lesson.map(lesson_in_data => {
            lesson_in_data.notes.map(note_data => {
              data.push({
                id: note_data.id,
                note: note_data.note,
                userId: note_data?.userId,
                timestamp: note_data.timestamp,
              });

              return null;
            });

            return null;
          });

          return null;
        });
        setNoteData(data);
        setNoteDataLength(data.length);
      })
      .catch(loginError => {
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, refresh]);

  const handleSubmit = async (values, actions) => {
    await axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      data: {
        id: (noteDataLength + 1).toString(),
        note: values.note_text,
        userId: userLocalData._id,
        timestamp: new Date().toLocaleDateString(),
      },
    })
      .then(putNoteResponse => {
        if (putNoteResponse.status === 200) {
          actions.setSubmitting(false);
          actions.resetForm();
          setUpdate(!update);
          setRefresh(!refresh);
          axios({
            method: 'GET',
            url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          })
            .then(lessonResponse => {
              const data = [];
              lessonResponse.data?.map(lesson_data => {
                lesson_data.module.lesson.map(lesson_in_data => {
                  lesson_in_data.notes.map(note_data => {
                    data.push({
                      id: note_data.id,
                      note: note_data.note,
                      userId: note_data?.userId,
                      timestamp: note_data.timestamp,
                    });

                    return null;
                  });

                  return null;
                });

                return null;
              });
              setNoteData(data);
              setNoteDataLength(data.length);
            })
            .catch(loginError => {
              return loginError;
            });
        }
      })
      .catch(loginError => {
        return loginError;
      });
  };

  const handleDelete = () => {
    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}/${noteId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(putNoteResponse => {
        if (putNoteResponse.status === 200) {
          setUpdate(!update);
          setRefresh(!refresh);
          axios({
            method: 'GET',
            url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          })
            .then(lessonResponse => {
              const data = [];
              lessonResponse.data?.map(lesson_data => {
                lesson_data.module.lesson.map(lesson_in_data => {
                  lesson_in_data.notes.map(note_data => {
                    data.push({
                      id: note_data.id,
                      note: note_data.note,
                      userId: note_data?.userId,
                      timestamp: note_data.timestamp,
                    });

                    return null;
                  });

                  return null;
                });

                return null;
              });
              setNoteData(data);
              setNoteDataLength(data.length);
            })
            .catch(loginError => {
              return loginError;
            });
        }
      })
      .catch(loginError => {
        return loginError;
      });
  };

  const onIdSetup = value => {
    setNoteId(value.toString());
  };
  const initialValues = {
    note_text: '',
  };

  const validationSchema = yup.object({
    note_text: yup.string().required('Note Required'),
  });

  return (
    <ChakraProvider theme={extendTheme}>
      <Box>
        <Drawer isOpen={isOpen} onClose={onClose} size="sm" placement="right">
          <DrawerContent
            pt="5"
            pb="70px"
            shadow="lg"
            position="absolute"
            h={{ sm: '100vh', md: '85vh', lg: '80vh' }}
            top={{
              sm: '0px !important',
              md: '164px !important',
              lg: '150px !important',
            }}
          >
            <DrawerHeader py="1px" px="6" mb="7">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                >
                  Notes
                </Text>
                <Spacer />
                <Box py="0px" px="3">
                  <Icon
                    as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
              <Box pt="6" /*pb="5"*/ fontWeight="normal" fontSize="16px">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {formik => (
                    <Form>
                      <Box mb="4" maxH="300px">
                        <Editable
                          placeholder="Hello"
                          value={formik.values.note_text}
                        >
                          <EditablePreview
                            border="1px solid #E2E8F0"
                            px="3"
                            py="2"
                            minH="80px"
                            maxH="260px"
                            w="100%"
                            rounded="6px"
                            color="rgba(0, 0, 0, 0.36)"
                          />
                          <FormControl
                            isInvalid={
                              formik.errors.note_text &&
                              formik.touched.note_text
                            }
                          >
                            <EditableTextarea
                              id="note_text"
                              name="note_text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.note_text}
                              px="3"
                              py="2"
                              minH="80px"
                              w={'full'}
                              maxH="260px"
                              rounded="6px"
                              focusBorderColor="yellow.500"
                              _focus={{
                                border: '1px solid #D69E2E',
                                m: '0',
                                outline: 'none',
                              }}
                              _active={{ outline: 'none' }}
                            />
                            <FormErrorMessage>
                              <ErrorMessage name="note_text" />
                            </FormErrorMessage>
                          </FormControl>
                        </Editable>
                      </Box>
                      <Box align="left">
                        <Button
                          type="submit"
                          py="5.5px"
                          px="3"
                          bg="yellow.400"
                          color="gray.900"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="700"
                          isLoading={formik.isSubmitting}
                          fontSize="14px"
                          _hover={{ bg: 'yellow.500' }}
                          _active={{
                            bg: 'yellow.500',
                            transform: 'scale(0.98)',
                          }}
                          _focus={{
                            outline: 'none',
                          }}
                        >
                          Add note
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </DrawerHeader>
            <DrawerBody
              px="6"
              pt="0"
              pb="0"
              borderBottom="1px solid #E2E8F0"
              overflowY="auto"
            >
              {noteData
                .filter(noteElement => {
                  if (noteElement.userId === userLocalData._id) {
                    return noteElement;
                  } else {
                    return null;
                  }
                })
                .map((note_data, index) => (
                  <Box mb="4" key={index}>
                    <Flex mb="9.5px" align="end">
                      <Text
                        color="gray.500"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                      >
                        {new Date(note_data?.timestamp).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          }
                        )}
                      </Text>
                      <Spacer />
                      <RiDeleteBinLine
                        cursor="pointer"
                        onClick={onDeleteOpen}
                        onMouseUp={() => onIdSetup(note_data.id)}
                        color="#C53030"
                        fontSize="24px"
                        _hover={{ cursor: 'pointer' }}
                      />
                    </Flex>
                    <Text
                      color="gray.700"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="14px"
                    >
                      {note_data.note}
                    </Text>
                  </Box>
                ))}
            </DrawerBody>
            {/* changed to handle the issues from apple devices */}
            {/* <DrawerFooter
              px="6"
              pt="6"
              pb="5"
              display="inline-table"
              mb={{ base: '100px', md: 0 }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Box mb="4" maxH="300px">
                      <Editable
                        placeholder="Hello"
                        value={formik.values.note_text}
                      >
                        <EditablePreview
                          border="1px solid #E2E8F0"
                          px="3"
                          py="2"
                          minH="80px"
                          maxH="260px"
                          w="100%"
                          rounded="6px"
                          color="rgba(0, 0, 0, 0.36)"
                        />
                        <FormControl
                          isInvalid={
                            formik.errors.note_text && formik.touched.note_text
                          }
                        >
                          <EditableTextarea
                            id="note_text"
                            name="note_text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.note_text}
                            px="3"
                            py="2"
                            minH="80px"
                            w={'full'}
                            maxH="260px"
                            rounded="6px"
                            focusBorderColor="yellow.500"
                            _focus={{
                              border: '1px solid #D69E2E',
                              m: '0',
                              outline: 'none',
                            }}
                            _active={{ outline: 'none' }}
                          />
                          <FormErrorMessage>
                            <ErrorMessage name="note_text" />
                          </FormErrorMessage>
                        </FormControl>
                      </Editable>
                    </Box>
                    <Box align="left">
                      <Button
                        type="submit"
                        py="5.5px"
                        px="3"
                        bg="yellow.400"
                        color="gray.900"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="700"
                        isLoading={formik.isSubmitting}
                        fontSize="14px"
                        _hover={{ bg: 'yellow.500' }}
                        _active={{
                          bg: 'yellow.500',
                          transform: 'scale(0.98)',
                        }}
                        _focus={{
                          outline: 'none',
                        }}
                      >
                        Add note
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </Box>
      <Delete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onDelete={handleDelete}
        content="note"
      />
    </ChakraProvider>
  );
}

export default AddNotesDrawer;
