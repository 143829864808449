import { Box, ChakraProvider, Flex, Icon, Text, theme } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';

function SearchResultBanner({ searchValue, searchType, handleSearchClose }) {
  return (
    <ChakraProvider theme={theme}>
      <Box p="6" /*borderBottom="1px solid #E2E8F0"*/>
        <Flex align="center">
          <Box pb="2" pr="3">
            <Icon
              as={AiOutlineArrowLeft}
              onClick={handleSearchClose}
              fontSize="30px"
              color="gray.500"
              fontWeight="normal"
              cursor="pointer"
            />
          </Box>
          <Text
            color="gray.500"
            fontFamily="Inter"
            fontWeight="700"
            fontSize="24px"
            pb="2"
          >
            Results for
            <Text as="span" color="gray.800">
              {` ‘${searchValue === ' ' ? searchValue : searchValue.trim()}’ `}
            </Text>
          </Text>
        </Flex>
        <Text
          color="gray.500"
          fontFamily="Inter"
          fontWeight="400"
          fontSize="16px"
        >
          {/* Showing projects, summaries and other files containing the searched
          phrase{' '} */}
          {searchType}
        </Text>
        <Text></Text>
      </Box>
    </ChakraProvider>
  );
}

export default SearchResultBanner;
