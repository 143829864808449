import {
  Box,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  Input,
} from '@chakra-ui/react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import React from 'react';
import Axios from 'axios';

function ForgotPasswordForm(props) {
  const initialValues = {
    email: '',
    redirectURL: `${process.env.REACT_APP_EH_FRONTEND}/passwordreset`,
  };
  const validationSchema = yup.object({
    email: yup.string().email('invalid Email Address').required(),
  });
  const onSubmit = async (values, actions) => {
    await Axios.post(
      `${process.env.REACT_APP_EH_BACKEND}/auth/forgot-password`,
      values
    )
      .then(res => {
        if (res.data.status) {
          props.onAlertHandle({
            status: res.data.status,
            message: res.data.message,
          });
          actions.setSubmitting(false);
          actions.resetForm();
          props.setUpdate(!props.update);
        }
      })
      .catch(err => {
        return err;
      });
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form autoComplete="off">
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.errors.email && formik.touched.email}
              >
                <FormLabel htmlFor="email">
                  {' '}
                  <Text fontSize="sm" fontWeight="normal" color="gray.900">
                    Email Address
                  </Text>
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  _placeholder={{ color: 'gray.400' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormErrorMessage>
                  <ErrorMessage name="email" />
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                colorScheme="yellow"
                isLoading={formik.isSubmitting}
                isFullWidth
              >
                <Text fontWeight="bold">Submit</Text>
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ForgotPasswordForm;
