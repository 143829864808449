import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Link as LinkDOM, useHistory } from 'react-router-dom';
import EhLogo from '../Components/Logo/EhLogo';
import PasswordResetForm from '../Components/FormControllers/PasswordResetForm';
import Iubenda from 'react-iubenda-policy';

function PasswordReset(props) {
  const userId = props.match.params.id;
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const history = useHistory();
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();

  useEffect(() => {
    if (alert && alertType === 'error') {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
      history.push('/');
    }
    if (alert && alertType === 'success') {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
      history.push('/');
    }
  }, [alert, alertType, history, toast, toastposition]);

  return (
    <>
      <Box
        h="100vh"
        bg="gray.100"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        overflow={'hidden'}
        textAlign="center"
        fontSize="xl"
      >
        <Container
          maxW="lg"
          py={{ base: '12', md: '24' }}
          px={{ base: '0', sm: '8' }}
        >
          <Stack spacing="8" mx={2}>
            <Stack spacing="6">
              <EhLogo />
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                  Set your new password
                </Heading>
              </Stack>
            </Stack>
            <Box
              py={{
                base: '6',
                sm: '8',
              }}
              px={{
                base: '6',
                sm: '10',
              }}
              bg="white"
              boxShadow={{
                base: 'none',
                sm: useColorModeValue('md', 'md-dark'),
              }}
              borderRadius={{
                base: 'md',
                sm: 'xl',
              }}
            >
              <Stack spacing={6}>
                <Stack spacing={5}>
                  <PasswordResetForm
                    userId={userId}
                    onAlertHandle={alertHandler}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <HStack mt={5} align="center" justify="center">
            <Text fontWeight="normal" color="gray.700" fontSize="16px">
              Not an Elephant Hive user?{' '}
            </Text>
            <LinkDOM to="/signup">
              <Button variant="link" size="sm" color="black">
                <Text fontWeight="bold">Create account</Text>
              </Button>
            </LinkDOM>
          </HStack>
        </Container>
        <Spacer />
        <Box>
          <Flex
            align={['center', 'center', 'center', 'end']}
            justify={['center', 'center', 'center', 'end']}
            mb={5}
            mr={[0, 0, 0, 10]}
          >
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="terms-and-conditions"
                styling="nostyle"
              >
                <Text
                  fontSize={['12px', '16px']}
                  color="gray.500"
                  fontWeight="bold"
                >
                  Read terms and conditions
                </Text>
              </Iubenda>
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default PasswordReset;
