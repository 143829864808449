import {
  Center,
  ChakraProvider,
  CircularProgress,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';

function Loading({ text }) {
  return (
    <ChakraProvider theme={theme}>
      <Center mt="150px">
        <Stack textAlign="center">
          <Text
            color="gray.900"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="700"
            fontSize="14px"
            pb="6"
          >
            {text ? text : 'Loading your knowledge'}
          </Text>
          <Center>
            <CircularProgress isIndeterminate color="yellow.400" />
          </Center>
        </Stack>
      </Center>
    </ChakraProvider>
  );
}

export default Loading;
