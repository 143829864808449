import { Box, Text, Flex, Image, Input, Spacer } from '@chakra-ui/react';
import React from 'react';
import ArrowLeft from '../../../assests/Icons/KnowledgeLibrary/ArrowLeft.png';
import ArrowLineLeft from '../../../assests/Icons/KnowledgeLibrary/ArrowLineLeft.png';
import ArrowRight from '../../../assests/Icons/KnowledgeLibrary/ArrowRight.png';
import ArrowLineRight from '../../../assests/Icons/KnowledgeLibrary/ArrowLineRight.png';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {pageNumbers.length !== 1 ? (
        <Box display={'block'}>
          <Flex align="center" justify={'center'} mt='2'>
            <Box
              w="4"
              mr="2"
              cursor="pointer"
              onClick={() => {
                paginate(1);
              }}
            >
              <Image src={ArrowLineLeft} alt="ArrowLineLeft" />
            </Box>
            <Box
              w="4"
              mr="2"
              cursor="pointer"
              onClick={() => {
                if (currentPage !== 1) {
                  paginate(currentPage - 1);
                }
              }}
            >
              <Image src={ArrowLeft} alt="ArrowLeft" />
            </Box>
            <Flex>
              {pageNumbers.map(number => (
                <Box
                  key={number}
                  cursor="pointer"
                  mr="2"
                  px="3"
                  py="1.5"
                  bg={number === currentPage ? 'gray.200' : 'transparent'}
                  color={number === currentPage ? 'gray.600' : 'gray.400'}
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="12px"
                  rounded="lg"
                  alignItems="center"
                  _hover={{ color: 'gray.600', bg: 'gray.200' }}
                  onClick={() => {
                    paginate(number);
                  }}
                >
                  <Text>{number}</Text>
                </Box>
              ))}
            </Flex>
            <Box
              w="4"
              mr="2"
              cursor="pointer"
              onClick={() => {
                if (currentPage !== pageNumbers.length) {
                  paginate(currentPage + 1);
                }
              }}
            >
              <Image src={ArrowRight} alt="ArrowRight" />
            </Box>
            <Box
              w="4"
              cursor="pointer"
              onClick={() => {
                paginate(pageNumbers.length);
              }}
            >
              <Image src={ArrowLineRight} alt="ArrowLineRight" />
            </Box>
          </Flex>
          <Flex align={'end'} justify={'end'}>
            <Spacer />
            <Flex right="6" align="center">
              <Text
                mr="4"
                color="gray.500"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="500"
                fontSize="16px"
              >
                Page
              </Text>
              <Input
                placeholder={currentPage}
                type="number"
                w="89px"
                py="2"
                px="3"
                color="gray.600"
                border="1px solid #E2E8F0"
                _placeholder={{ color: 'gray.400' }}
                rounded="sm"
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    const pageValue = parseInt(event.target.value);
                    if (pageValue <= pageNumbers.length) {
                      paginate(pageValue);
                    }
                  }
                }}
              />
            </Flex>
          </Flex>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default Pagination;
