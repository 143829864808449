import React from 'react';
import { Box, Center, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';

function SelectItem({
  selected,
  Icon,
  name,
  setFieldValue,
  value,
  id,
  index,
  data,
  type,
}) {
  return (
    <Box
      cursor="pointer"
      overflow="hidden"
      // w={['311px', '332px', '332px', '238px']}
      w={'full'}
      p="2"
      bg={selected ? 'yellow.50' : 'white'}
      shadow="base"
      rounded="16px"
      m={2}
      border={selected ? '1px solid #ECC94B' : '1px solid #fff'}
      onClick={
        selected
          ? name.includes('.')
            ? () =>
                setFieldValue('selected_nonsummary_ids', [
                  ...value.filter(item => item !== id),
                ])
            : type
            ? () =>
                setFieldValue('selected_knowledeNuggets_ids', [
                  ...value.filter(item => item !== id),
                ])
            : () =>
                setFieldValue('selected_resourse_ids', [
                  ...value?.filter(item => item !== id),
                ])
          : data?.filename
          ? () => setFieldValue('selected_nonsummary_ids', [...value, id])
          : data?.file_name
          ? () => setFieldValue('selected_knowledeNuggets_ids', [...value, id])
          : () => setFieldValue('selected_resourse_ids', [...value, id])
      }
    >
      <Flex align="center" justify="space-evenly">
        <Center w="32px" h="32px" shadow="base" rounded="12px" bg="white">
          <Image src={Icon} rounded />
        </Center>
        <Text
          px="2"
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="700"
          fontSize={["12px", "14px", "14px"]}
          w={'90%'}
          noOfLines={1}
        >
          {name}
        </Text>
        <Spacer />
        {selected && (
          <BsCheckCircle fontSize="20px" color="#B7791F" bg="yello4.400" />
        )}
      </Flex>
    </Box>
  );
}

export default SelectItem;
