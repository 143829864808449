import React, { useEffect, useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import AddToProject from './ProjectComps/AddToProject';
import NoProjects from './ProjectComps/NoProjects';
import { useLocation } from 'react-router-dom';
import EditTag from '../../Homepage/Components/HiveUploadContainer/EditTag';
import Delete from '../../LessonSummary/HeaderComps/Delete';
import axios from 'axios';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
function ResourceDrawer({
  type,
  id,
  projecId,
  isproject,
  projectData,
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  elementDataRes,
  elementDataNon,
  elementDataKn,
  upateprojectData,
  updatehivedata,
  setUpdatehivedata,
  setUpdateprojectData,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
}) {
  const {
    isOpen: isAddToProjectOpen,
    onOpen: onAddToProjectOpen,
    onClose: onAddToProjectClose,
  } = useDisclosure();
  const {
    isOpen: isNoProjectsOpen,
    onOpen: onNoProjectsOpen,
    onClose: onNoProjectsClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isOptionDrawerOpen,
    onOpen: onOptionDrawerOpen,
    onClose: onOptionDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteinsideProjectOpen,
    onOpen: onDeleteinsideProjectOpen,
    onClose: onDeleteinsideProjectClose,
  } = useDisclosure();

  const location = useLocation();
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');

  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [updatehivedata, updateKnowledgeNuggetData]);

  const { REACT_APP_EH_BACKEND } = process.env;
  let userLocalData = JSON.parse(localStorage.user);
  const headers = {
    headers: {
      Authorization: 'Bearer ' + localStorage.login_token,
    },
  };

  const handleDeletenonsummery = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivesingledata/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deletenonsummerydataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdatehivedata(!updatehivedata);
  };

  const handleDeleteKN = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${userLocalData._id}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deleteKNdataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);
  };

  const handleDeleteResource = async () => {
    // delete function when confirm delete button is clicked for resources
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/resources/delete/${userLocalData._id}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deleteResourcedataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdatehivedata(!updatehivedata);
  };

  const handleremovefromproject = async () => {
    // remove project from projects list
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/projectdataremovesingle/${userLocalData._id}/${type}/${projecId}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(err => {
        return err;
      });
    setUpdatehivedata(!updatehivedata);
  };

  return (
    <Box>
      <Button
        onClick={onOptionDrawerOpen}
        rounded="12px"
        p="5px"
        _active={{ border: '1px solid #ECC94B', bgColor: 'gray.200' }}
      >
        <HiOutlineDotsVertical />
      </Button>
      <Drawer
        isOpen={isOptionDrawerOpen}
        placement="bottom"
        onClose={onOptionDrawerClose}
      >
        <DrawerContent marginBottom="0" borderRadius="8px 8px 0px 0px">
          <DrawerBody boxShadow="0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)">
            {location.pathname === '/homepage' ? (
              <>
                <Flex align="start" flexDirection="column" justify="center">
                  <Button
                    isFullWidth
                    variant={'ghost'}
                    onClick={() => {
                      if (projectData.length > 0) {
                        onAddToProjectOpen();
                      } else {
                        onNoProjectsOpen();
                      }
                    }}
                  >
                    <Flex
                      dir="row"
                      align="center"
                      width="full"
                      ml={2}
                      my={2}
                      py={2}
                      borderRadius="md"
                      cursor="pointer"
                    >
                      <AiOutlineFolderOpen
                        size={24}
                        style={{ color: '#718096', marginLeft: '10px' }}
                      />
                      <Text
                        ml={2}
                        fontWeight={'normal'}
                        fontSize="16px"
                        color="black"
                      >
                        Add to Project
                      </Text>
                    </Flex>
                  </Button>
                  {/* edit tags */}
                  <Button
                    disabled={elementDataRes ? true : false}
                    isFullWidth
                    variant={'ghost'}
                    onClick={onEditOpen}
                  >
                    <Flex
                      dir="row"
                      align="center"
                      width="full"
                      ml={2}
                      my={2}
                      py={2}
                      borderRadius="md"
                      cursor="pointer"
                    >
                      <BiPencil
                        size={24}
                        style={{ color: '#718096', marginLeft: '10px' }}
                      />
                      <Text
                        ml={2}
                        fontWeight={'normal'}
                        fontSize="16px"
                        color="black"
                      >
                        Edit tags
                      </Text>
                    </Flex>
                  </Button>
                  {/* delete option */}
                  <Button isFullWidth variant={'ghost'} onClick={onDeleteOpen}>
                    <Flex
                      dir="row"
                      align="center"
                      width="full"
                      ml={2}
                      my={2}
                      py={2}
                      borderRadius="md"
                      onClick={onDeleteOpen}
                      cursor="pointer"
                    >
                      <RiDeleteBinLine
                        size={24}
                        style={{ color: 'red', marginLeft: '10px' }}
                      />
                      <Text
                        ml={2}
                        fontWeight={'normal'}
                        fontSize="16px"
                        color="black"
                      >
                        Delete
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Flex align="start" flexDirection="column" justify="center">
                  <Button
                    isFullWidth
                    variant={'ghost'}
                    onClick={onDeleteinsideProjectOpen}
                  >
                    <Flex
                      dir="row"
                      align="center"
                      width="full"
                      ml={2}
                      my={2}
                      py={2}
                      borderRadius="md"
                      cursor="pointer"
                    >
                      <AiOutlineFolderOpen
                        size={24}
                        style={{ color: '#718096', marginLeft: '10px' }}
                      />
                      <Text
                        ml={2}
                        fontWeight={'normal'}
                        fontSize="16px"
                        color="black"
                      >
                        Remove from Project
                      </Text>
                    </Flex>
                  </Button>
                  {/* edit */}
                  <Button
                    disabled={elementDataRes ? true : false}
                    isFullWidth
                    variant={'ghost'}
                    onClick={onEditOpen}
                  >
                    <Flex
                      dir="row"
                      align="center"
                      width="full"
                      ml={2}
                      my={2}
                      py={2}
                      borderRadius="md"
                      cursor="pointer"
                    >
                      <BiPencil
                        size={24}
                        style={{ color: '#718096', marginLeft: '10px' }}
                      />
                      <Text
                        ml={2}
                        fontWeight={'normal'}
                        fontSize="16px"
                        color="black"
                      >
                        Edit tags
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
        {onAddToProjectOpen && (
          <AddToProject
            type={type}
            id={id}
            update={updatehivedata}
            setUpdate={setUpdatehivedata}
            isOpen={isAddToProjectOpen}
            onClose={onAddToProjectClose}
          />
        )}
        {onEditOpen && (
          <EditTag
            isOpen={isEditOpen}
            onClose={onEditClose}
            itemID={id}
            update={updatehivedata}
            setUpdate={setUpdatehivedata}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            nonhiveData={elementDataNon ? elementDataNon : elementDataKn}
            resourceData={resourceData}
            nonsummaryhiveData={nonsummaryhiveData}
            knowledgeNuggetData={knowledgeNuggetData}
            isproject={isproject}
            type={type}
          />
        )}
        {onDeleteOpen && (
          <Delete
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            content={
              elementDataRes
                ? elementDataRes.resource_name
                : elementDataKn
                ? elementDataKn.knowledge_nugget_name
                : elementDataNon?.filename
            }
            onDelete={
              elementDataKn
                ? handleDeleteKN
                : elementDataRes
                ? handleDeleteResource
                : handleDeletenonsummery
            }
            isproject={isproject}
            type={type}
          />
        )}
        {onDeleteinsideProjectOpen && (
          <Delete
            isOpen={isDeleteinsideProjectOpen}
            onClose={onDeleteinsideProjectClose}
            content={
              elementDataRes
                ? elementDataRes.resource_name
                : elementDataKn
                ? elementDataKn.knowledge_nugget_name
                : elementDataNon?.filename
            }
            onDelete={handleremovefromproject}
            isproject={isproject}
          />
        )}
        {
          <NoProjects
            isOpen={isNoProjectsOpen}
            onClose={onNoProjectsClose}
            resourceData={resourceData}
            nonsummaryhiveData={nonsummaryhiveData}
            upateprojectData={upateprojectData}
            setUpdateprojectData={setUpdateprojectData}
          />
        }
      </Drawer>
    </Box>
  );
}

export default ResourceDrawer;
