import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Image,
  Img,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import KnBtn from '../../../assests/Icons/Base.png';
import KNLibdoc from '../../../assests/Icons/KnowledgeLibrary/KNLib-elem.png';
import KNLibTextBook from '../../../assests/Icons/KnowledgeLibrary/ResBook-elem.png';
import KNLibPodecst from '../../../assests/Icons/KnowledgeLibrary/podecast-elem.png';
import KNLibArticle from '../../../assests/Icons/KnowledgeLibrary/Article-elem.png';
import KNLibAccademic from '../../../assests/Icons/KnowledgeLibrary/Accademic-elem.png';
import { AiFillStar } from 'react-icons/ai';

function TableAccodianBody({
  searchValue,
  props,
  haveknowledgeData,
  filteredKnowledgeData,
  indexOfFirstPost,
  indexOfLastPost,
  addToMyKnowledgeNuggets,
}) {
  let ratings;

  return (
    <>
      <Box>
        <Accordion
          allowToggle
          borderBottomLeftRadius={'2xl'}
          borderBottomRightRadius={'2xl'}
          overflow={'auto'}
          w="full"
        >
          {haveknowledgeData &&
            filteredKnowledgeData
              ?.filter(item => {
                if (
                  item?.metadata?.title
                    ?.toLowerCase()
                    .includes(searchValue?.toLowerCase())
                ) {
                  return item;
                } else {
                  return null;
                }
              })
              .slice(indexOfFirstPost, indexOfLastPost)
              .map((knowledge, index) => (
                <AccordionItem
                  w="100%"
                  py="5"
                  bg="white"
                  borderTopLeftRadius={{
                    base: index === 0 ? '2xl' : '0',
                    md: '0',
                  }}
                  borderTopRightRadius={{
                    base: index === 0 ? '2xl' : '0',
                    md: '0',
                  }}
                  borderBottomLeftRadius={
                    index === 7 ||
                    index + 1 === filteredKnowledgeData.length % 8
                      ? '2xl'
                      : '0'
                  }
                  borderBottomRightRadius={
                    index === 7 ||
                    index + 1 === filteredKnowledgeData.length % 8
                      ? '2xl'
                      : '0'
                  }
                  key={index}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        p="0"
                        bg="white"
                        _expanded={{ bg: 'white', outline: 'none' }}
                        w={'full'}
                      >
                        <Flex align="center" justify={'space-between'} w="100%">
                          {/* <Box>
                            <Flex justify={'space-between'}> */}
                          <Image
                            src={
                              knowledge.type === 'course'
                                ? KNLibTextBook
                                : knowledge.type === 'Article'
                                ? KNLibArticle
                                : knowledge.type === 'Podcast'
                                ? KNLibPodecst
                                : knowledge.type === 'Accademic'
                                ? KNLibAccademic
                                : KNLibdoc
                            }
                            alt={knowledge.type}
                            mx="3"
                          />
                          <Box
                            px="3"
                            // w={[
                            //   isExpanded ? '90%' : '180px',
                            //   isExpanded ? '90%' : '200px',
                            //   isExpanded ? '80%' : '280px',
                            //   isExpanded ? '100%' : '460px',
                            // ]}
                            textAlign="left"
                            w="200%"
                            // overflow="auto"
                          >
                            <Text
                              // maxWidth={[
                              //   isExpanded ? '90%' : '180px',
                              //   isExpanded ? '90%' : '200px',
                              //   isExpanded ? '100%' : '280px',
                              //   isExpanded ? '100%' : '460px',
                              // ]}
                              maxWidth={isExpanded ? '500%' : '100%'}
                              noOfLines={1}
                              fontFamily={'body'}
                              color="gray.600"
                              fontSize="16px"
                              fontWeight={'bold'}
                              h={isExpanded ? '50px' : ''}
                              overflow={isExpanded ? 'auto' : 'hidden'}
                            >
                              {knowledge.metadata.title}
                            </Text>
                          </Box>
                          {isExpanded ? null : (
                            <Box
                              display={{ base: 'none', md: 'flex' }}
                              px="3"
                              textAlign="right"
                              w="100%"
                            >
                              <Text
                                textAlign="center"
                                fontFamily={'body'}
                                maxWidth={{
                                  base: '10%',
                                  sm: '30%',
                                  md: '100%',
                                  lg: '100%',
                                  xl: '100%',
                                }}
                                color="gray.400"
                                fontSize="16px"
                                fontWeight={'normal'}
                                noOfLines={1}
                              >
                                {knowledge.metadata.authors}
                              </Text>
                            </Box>
                          )}
                          {/* </Flex>
                          </Box> */}
                          {/* <Spacer /> */}
                          <Box
                            ml={{
                              base: isExpanded ? '0' : '0',
                              sm: isExpanded ? '8' : '6',
                              md: isExpanded ? '80' : '60',
                            }}
                            w="100%"
                            margin="0 auto"
                          >
                            <Flex align="center" justifyContent="space-between">
                              <Box pl="3">
                                {/* <Center> */}
                                <BsArrowUpRightSquare
                                  style={{
                                    color: 'purple',
                                    border: '1.5px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    let sourceURL = knowledge.metadata.doi;
                                    window.open(
                                      sourceURL.includes('https://')
                                        ? sourceURL
                                        : ` https://doi.org/${knowledge.metadata.doi}`
                                    );
                                  }}
                                />
                                {/* </Center> */}
                              </Box>

                              <Box pr="2">
                                <Box
                                  variant="unstyled"
                                  size="sm"
                                  mr={2}
                                  onClick={event => {
                                    addToMyKnowledgeNuggets(
                                      event,
                                      knowledge._id,
                                      knowledge.metadata.title,
                                      knowledge.type
                                    );
                                  }}
                                >
                                  <Img
                                    boxSize="8"
                                    objectFit={'cover'}
                                    src={KnBtn}
                                    alt="Knowledge-library-nugget"
                                  />
                                </Box>
                              </Box>
                            </Flex>
                          </Box>
                        </Flex>
                        {/* <AccordionIcon /> */}
                      </AccordionButton>

                      <AccordionPanel
                        py="0"
                        // onClick={() => {
                        //   props.history.push(
                        //     `/knowledge/nugget?knowledge_nugget_id=${knowledge._id}`
                        //   );
                        // }}
                      >
                        <Box>
                          <Flex pt="14px">
                            <Text
                              w="120px"
                              pr="5"
                              color="gray.500"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="700"
                              fontSize="14px"
                            >
                              Author(s)
                            </Text>
                            <Text
                              color="gray.400"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="16px"
                            >
                              {knowledge.metadata.authors}
                            </Text>
                          </Flex>
                          <Flex pt="3">
                            <Text
                              w="120px"
                              pr="5"
                              color="gray.500"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="700"
                              fontSize="14px"
                            >
                              Date added
                            </Text>
                            <Text
                              color="gray.400"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="16px"
                            >
                              {new Date(
                                knowledge.created_at
                              ).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              })}
                            </Text>
                          </Flex>

                          <Flex pt="3">
                            <Text
                              w="120px"
                              pr="5"
                              color="gray.500"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="700"
                              fontSize="14px"
                            >
                              Rating
                            </Text>
                            <Box>
                              <Text display="none">{(ratings = 0)}</Text>
                              {knowledge?.ratings?.map(ratValue => {
                                ratings += Number(ratValue?.rating || 0);
                                return null;
                              })}

                              <Text display="none">
                                {' '}
                                {(ratings /= knowledge?.ratings?.length || 0)}
                              </Text>

                              {Array(Math.trunc(Number(ratings || 0))) // Variable ratings
                                .fill('')
                                .map((_, i) => (
                                  <Icon
                                    as={AiFillStar}
                                    color="yellow.400"
                                    fontSize="16px"
                                    border="1.5px"
                                    borderColor="gray.400"
                                    mr="1"
                                    key={i}
                                  />
                                ))}
                              {Array(5 - Math.trunc(Number(ratings || 0)))
                                .fill('')
                                .map((_, i) => (
                                  <Icon
                                    as={AiFillStar}
                                    color="gray.200"
                                    fontSize="16px"
                                    border="1.5px"
                                    borderColor={'gray.400'}
                                    mr="1"
                                    key={i}
                                  />
                                ))}
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
        </Accordion>
      </Box>
    </>
  );
}

export default TableAccodianBody;
