import { Box, Center, Input, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineUpload } from 'react-icons/ai';

function Dragdrop(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      props.setFieldValue('files', acceptedFiles);
      props.dropFile(acceptedFiles);
    },
    [props]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Box
      border="1px solid #E2E8F0"
      rounded="16px"
      p="4"
      mb="6"
      align="center"
      {...getRootProps()}
    >
      <Input {...getInputProps()} />
      <Center
        w="40px"
        h="40px"
        rounded="6px"
        border="1px solid #E2E8F0"
        bg="gray.200"
      >
        <AiOutlineUpload />
      </Center>
      <Text
        pt="4"
        pb="1"
        color="gray.900"
        fontFamily="Inter"
        fontStyle="normal"
        fontWeight="700"
        fontSize="14px"
      >
        Click to upload
        <Text as="span" fontWeight="400" color="gray.600">
          &nbsp;or drag and drop
        </Text>
      </Text>
      <Text
        color="gray.600"
        fontFamily="Inter"
        fontStyle="normal"
        fontWeight="400"
        fontSize="14px"
      >
        All document formats and images (JPG, PNG)
      </Text>
    </Box>
  );
}

export default Dragdrop;
