import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import Axios from 'axios';
import InputTagReccurent from './InputTagRecurrent';
import axios from 'axios';

function EditTag({
  isOpen,
  onClose,
  itemID, //element id
  setUpdate,
  update,
  nonhiveData, //tags on the hive element
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  isproject, //boolean to check if its from an project
  type,
}) {
  const [userInfo, setUserInfo] = useState();
  const ex_tags = ['Design', 'Strategy', 'Human Centered Design'];
  const [editupdate, setEditupdate] = useState(false);
  const [suggestionTag, setSuggestionTag] = useState();
  const userTags = [];

  const suggestTags = [];
  const cancelRef = useRef();
  let userLocalData = JSON.parse(localStorage.user);

  // get knowledgge library array and resource array of the relevent user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/auth/${userLocalData._id}/info`,
    })
      .then(userResponse => {
        setUserInfo(userResponse.data);
      })
      .catch(error => {
        return error;
      });
  }, [userLocalData._id, editupdate]);

  nonhiveData?.tags?.map((tag, _) => {
    if (
      (type === 'knowledgeNugget' && nonhiveData?.type === 'Academic paper') ||
      (type === 'knowledgeNugget' && nonhiveData?.type === 'web scrapings')
    ) {
      // if tag belongs to the knowledge nugget or resource object with key as tag_name
      userTags.push(tag?.tag_name);
    }
    if (type === 'nonsummary') {
      // when tags are stored as elements in an array
      userTags.push(tag);
    }
    return null;
  });

  // get the tags from the knowledge library nugget
  userInfo?.user_KN_Lib
    .find(element => element.id === itemID)
    ?.tags?.map((tag, _) => {
      userTags.push(tag.tag_name);
      return null;
    });

  isproject
    ? // if in project, the results are fetched to the users as an query so it need to go through
      // the results and get the tags from the results
      nonsummaryhiveData &&
      nonsummaryhiveData?.map(item => {
        item.nonsummaryitem.tags.map(tag => {
          suggestTags.push(tag);
          return null;
        });
        return null;
      })
    : // if not in project, the results are fetched to the users as an query so it need to go through
      nonsummaryhiveData &&
      nonsummaryhiveData?.map(item => {
        item.tags.map(tag => {
          suggestTags.push(tag);
          return null;
        });
        return null;
      });

  isproject
    ? // if in project, the results are fetched to the users as an query so it need to go through
      // the results and get the tags from the results
      knowledgeNuggetData?.map(item => {
        item.knowledgeNugget.tags.map(tag => {
          suggestTags.push(tag.tag_name);
          return null;
        });
        return null;
      })
    : // if not in project, the results are fetched to the users as an query so it need to go through
      knowledgeNuggetData?.map(item => {
        item?.tags?.map(tag => {
          suggestTags.push(tag.tag_name);
          return null;
        });
        return null;
      });

  resourceData &&
    resourceData?.map(item => {
      item.resource.tags.map(tag => {
        suggestTags.push(tag.tag_name);
        return null;
      });
      return null;
    });

  // shuffle data so random data appear everytime
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const shuffledUserTags = shuffle(suggestTags)?.slice(0, 3);
  const TagData =
    resourceData || nonsummaryhiveData || knowledgeNuggetData
      ? shuffledUserTags
      : ex_tags;

  const handlethis = e => {
    e.preventDefault();
  };

  const initialValues = {
    tags: [],
  };
  const validationSchema = yup.object({
    tags: yup.mixed().required('tags required'),
  });
  const onSubmit = async (values, actions) => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };
    await Axios.put(
      `${REACT_APP_EH_BACKEND}/nonsummaryhive/${itemID}`,
      values,
      headers
    )
      .then(res => {
        if (res.status === 200) {
          actions.setSubmitting(false);
          setSuggestionTag(null);
          setEditupdate(!editupdate);
          actions.resetForm();
          onClose();
          isOpen = false;
          setUpdate(!update);
        } else {
          actions.setSubmitting(false);
          actions.setStatus({ msg: 'error' });
        }
      })
      .catch(err => {
        return err;
      });
  };

  // edit tags on the KN elements
  const editKNtags = async values => {
    const { REACT_APP_EH_BACKEND } = process.env;

    const submitTagArr = [];

    values.map(tag_data => {
      submitTagArr.push({
        tag_name: tag_data,
      });

      return null;
    });

    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/tags/${itemID}/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      data: {
        tags: submitTagArr,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setSuggestionTag(null);
          setEditupdate(!editupdate);
          onClose();
          isOpen = false;
          setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);
        }
      })
      .catch(err => {
        return err;
      });
  };

  const checkEdit = formik => {
    if (type === 'knowledgeNugget') {
      editKNtags(formik.values.tags);
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        {/* update code on model here */}
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight={'bold'} color="gray.900">
              Edit tags
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={3}>
              <Box>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {formik => (
                    <Form autoComplete="off" onSubmit={handlethis}>
                      <FormControl
                        isInvalid={formik.errors.tags && formik.touched.tags}
                      >
                        <FormLabel htmlFor="tags" my={2}>
                          <Text
                            fontSize="sm"
                            fontWeight="normal"
                            color="gray.900"
                            fontFamily={'body'}
                          >
                            Tags
                          </Text>
                        </FormLabel>

                        <InputTagReccurent
                          setFieldValue={formik.setFieldValue}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          values={userTags}
                          errors={formik.errors.tags}
                          setSuggestionTag={setSuggestionTag}
                          suggestionTag={suggestionTag}
                          update={editupdate}
                        />

                        <FormErrorMessage>
                          <ErrorMessage name="tags" />
                        </FormErrorMessage>
                      </FormControl>
                      <Box display={'flex'} flexDir="row" gap={2} my={2}>
                        <Text fontFamily={'body'}>Suggested</Text>
                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          gap={2}
                          overflow="hidden"
                          display="flex"
                        >
                          {TagData.map((tag, index) => (
                            <GridItem key={index}>
                              <Box onClick={() => setSuggestionTag(tag)}>
                                <Badge
                                  variant={'subtle'}
                                  borderRadius="lg"
                                  colorScheme={'gray'}
                                  _hover={{
                                    cursor: 'pointer',
                                    bgColor: 'gray.200',
                                  }}
                                >
                                  <Text
                                    fontSize="14px"
                                    fontWeight="normal"
                                    fontFamily={'body'}
                                    color={'gray.600'}
                                    p={0.6}
                                  >
                                    {tag}
                                  </Text>
                                </Badge>
                              </Box>
                            </GridItem>
                          ))}
                        </Grid>
                      </Box>
                      <Box display={'flex'} justifyContent="center" my={3}>
                        <Button
                          type="button"
                          ref={cancelRef}
                          colorScheme="yellow"
                          disabled={
                            formik.values.tags.length === 0 ||
                            formik.isSubmitting
                              ? true
                              : false
                          }
                          onClick={() => {
                            checkEdit(formik);
                          }}
                          isLoading={formik.isSubmitting}
                        >
                          <Text
                            fontWeight="bold"
                            fontSize="18px"
                            color="gray.900"
                            fontFamily={'body'}
                          >
                            Save changes
                          </Text>
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default EditTag;
