import { Controls, useReactFlow } from 'react-flow-renderer';
import React from 'react';
import { Box, ChakraProvider, Flex, Image, Text } from '@chakra-ui/react';
import extendTheme from '../../extendTheme';
import Plus from '../../assests/Icons/Zoom/PlusCircle.png';
import Minus from '../../assests/Icons/Zoom/MinusCircle.png';

function ZoomController() {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <ChakraProvider theme={extendTheme}>
      <Box position="fixed" zIndex={4} right="385px" top="189px">
        <Controls
          showZoom={false}
          showFitView={false}
          showInteractive={false}
          style={{ position: 'absolute', background: '#fff' }}
        >
          <Box bg="#e2e8f0" rounded="full" position="absolute" top="0px">
            <Flex align="center">
              <Box
                p="12px"
                position="absolute"
                cursor="pointer"
                _hover={{ background: '#fff' }}
                rounded="full"
                onClick={() => {
                  zoomOut();
                }}
              >
                <Image w="20px" src={Minus} alt="zoom out" />
              </Box>
              <Text
                position="relative"
                left="45px"
                mr="30px"
                w="60px"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                color="#1A202C"
                textAlign="center"
                zIndex="3"
                cursor="pointer"
              ></Text>
              <Box
                p="12px"
                cursor="pointer"
                _hover={{ background: '#fff' }}
                rounded="full"
                onClick={() => {
                  zoomIn();
                }}
              >
                <Image w="30px" src={Plus} alt="zoom in" />
              </Box>
            </Flex>
          </Box>
        </Controls>
      </Box>
    </ChakraProvider>
  );
}

export default ZoomController;
