import {
  Button,
  Center,
  Image,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import KNLibdoc from '../../../assests/Icons/KnowledgeLibrary/KNLib-elem.png';
import KNLibTextBook from '../../../assests/Icons/KnowledgeLibrary/ResBook-elem.png';
import KNLibPodecst from '../../../assests/Icons/KnowledgeLibrary/podecast-elem.png';
import KNLibArticle from '../../../assests/Icons/KnowledgeLibrary/Article-elem.png';
import KNLibAccademic from '../../../assests/Icons/KnowledgeLibrary/Accademic-elem.png';

function TableBody({
  searchValue,
  haveknowledgeData,
  filteredKnowledgeData,
  indexOfFirstPost,
  indexOfLastPost,
  addToMyKnowledgeNuggets,
}) {
  return (
    <>
      <Tbody bg="white" w={'full'} my="6">
        {haveknowledgeData &&
          filteredKnowledgeData
            ?.filter(item => {
              if (
                item?.metadata?.title
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase())
              ) {
                return item;
              } else {
                return null;
              }
            })
            .slice(indexOfFirstPost, indexOfLastPost)
            .map((knowledge, index) => (
              <Tr key={index} borderBottom="1px solid #E2E8F0">
                <Td textAlign="center" borderBottom="none">
                  <Center>
                    <Button variant={'unstyled'} _hover={{ cursor: 'text' }}>
                      <Image
                        src={
                          knowledge.type === 'course'
                            ? KNLibTextBook
                            : knowledge.type === 'Article'
                            ? KNLibArticle
                            : knowledge.type === 'Podcast'
                            ? KNLibPodecst
                            : knowledge.type === 'Accademic'
                            ? KNLibAccademic
                            : KNLibdoc
                        }
                        alt={knowledge.type}
                      />
                    </Button>
                  </Center>
                </Td>
                <Td
                  px="3"
                  py="5"
                  w={['205px', '255px', '280px', '460px']}
                  overflow="hidden"
                  borderBottom="none"
                >
                  <Tooltip
                    hasArrow
                    label={knowledge.metadata.title}
                    bg="gray.800"
                    placement="top"
                  >
                    <Text
                      w={['205px', '255px', '280px', '460px']}
                      fontFamily={'body'}
                      color="gray.600"
                      fontSize="16px"
                      fontWeight={'bold'}
                    >
                      {knowledge.metadata.title}
                    </Text>
                  </Tooltip>
                </Td>
                <Td px="3" py="5" borderBottom="none" mt="7px">
                  <Tooltip
                    hasArrow
                    label={knowledge.metadata.authors}
                    bg="gray.800"
                    placement="top"
                  >
                    <Text
                      fontFamily={'body'}
                      color="gray.400"
                      fontSize="16px"
                      fontWeight={'normal'}
                    >
                      {knowledge.metadata.authors}
                    </Text>
                  </Tooltip>
                </Td>
                <Td px="3" py="5" borderBottom="none">
                  <Text
                    fontFamily={'body'}
                    color="gray.400"
                    fontSize="16px"
                    fontWeight={'normal'}
                  >
                    {new Date(knowledge.created_at).toLocaleDateString(
                      'en-US',
                      {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }
                    )}
                  </Text>
                </Td>
                <Td px="3" py="5" borderBottom="none">
                  <Button
                    colorScheme="yellow"
                    fontWeight="700"
                    color="gray.500"
                    size="sm"
                    rounded="4px"
                    fontFamily="Inter"
                    fontSize="16px"
                    px={6}
                    py={5}
                    onClick={event => {
                      addToMyKnowledgeNuggets(
                        event,
                        knowledge._id,
                        knowledge.metadata.title,
                        knowledge.type
                      );
                    }}
                  >
                    View Summary
                  </Button>
                </Td>
              </Tr>
            ))}
      </Tbody>
    </>
  );
}

export default TableBody;
