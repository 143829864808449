import React, { useEffect, useState } from 'react';
import { useToast, useBreakpointValue } from '@chakra-ui/react';
import { user } from '../utils';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import FirstTimeUser from '../Components/Homepage/FirstTimeUser';
import ReturningUser from '../Components/Homepage/ReturningUser';
import Loading from '../Components/Loading/Loading';
import { useIntercom } from 'react-use-intercom';
import gtag from 'ga-gtag';

const HomePage = props => {
  const [userInfo, setUserInfo] = useState();
  const [resourceData, setResourceData] = useState();
  const [storageUsed, setStorageUsed] = useState(0);
  const [nonsummaryhiveData, setnonsummaryhiveData] = useState();
  const [moduleData, setModuleData] = useState();
  const [projectData, setProjectData] = useState();
  const [haveProjectdata, setHaveProjectData] = useState(false);
  const [haveResourceData, setHaveResourceData] = useState();
  const [haveNonsummeryData, setHaveNonsummeryData] = useState();
  const [knowledgeNuggetData, setKnowledgeNuggetData] = useState();
  const [knowledgeLibraryData, setKnowledgeLibraryData] = useState();
  const [haveKnowledgeNuggetData, setHaveKnowledgeNuggetData] = useState();
  const [userData, setUserData] = useState();
  const [updatehivedata, setUpdatehivedata] = useState(false);
  const [upateprojectData, setUpdateprojectData] = useState(false);
  const [updateKnowledgeNuggetData, setUpdateKnowledgeNuggetData] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [update, setUpdate] = useState(false);
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  let history = useHistory();
  let userLocalData = JSON.parse(localStorage.user);
  const { boot, shutdown, startTour } = useIntercom();

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);
  // get knowledgge library array and resource array of the relevent user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/auth/${userLocalData._id}/info`,
    })
      .then(userResponse => {
        setUserInfo(userResponse.data);
      })
      .catch(error => {
        return error;
      });
  }, [userLocalData._id, updatehivedata]);

  const handleClaimResource = values => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
    let resId;

    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/resourceclaims/${userLocalData._id}/${values.resource_claim_id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        alertHandler({
          status: resourceResponse.status,
          message: resourceResponse.message,
        });
        setUpdate(!update);
        if (resourceResponse.status === 200) {
          gtag('config', REACT_APP_GA_MEASUREMENT_ID);
          resId = resourceResponse.data.resource_id;
          gtag('event', 'claim_course', {
            course_id: `${resourceResponse.data.resource_id}`,
            userid: `${userLocalData._id}`,
            full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
            verified: `${userLocalData.isVerified}`,
          });
          alertHandler({
            status: resourceResponse.data.status,
            message: resourceResponse.data.message,
          });
          setUpdate(!update);
          userLocalData?.resource_id.push({
            id: resourceResponse.data.resource_id,
          });
          user(userLocalData);
          setUserData(userLocalData);

          if (resourceResponse.data.resource_id !== undefined) {
            history.push(
              `/resource/overview?resource_id=${resourceResponse.data.resource_id}`
            );
          }
        }
      })
      .catch(loginError => {
        gtag('event', 'exception', {
          process: `claiming course of id: ${resId} by ${userLocalData.firstName} ${userLocalData.lastName} with user id: "${userLocalData._id}"`,
          description: loginError,
          fatal: false,
        });
        return loginError;
      });
  };

  // get users resources
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledgedataRoutes/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    }).then((response)=>{
      setResourceData(response.data.resources);
      setModuleData(response.data.modules);
      setnonsummaryhiveData(response.data.nonSummeryhiveData);
      setKnowledgeNuggetData(response.data.knowledgenuggetdata);
      setKnowledgeLibraryData(response.data.knowledgeLibraryData);
      setProjectData(response.data.projectData);
      setLoading(false);
    });
  }, [userLocalData._id, userData, updatehivedata, updateKnowledgeNuggetData]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id, updatehivedata]);

  // useEffect(() => {
  //   const { REACT_APP_EH_BACKEND } = process.env;
  //   axios({
  //     method: 'GET',
  //     url: `${REACT_APP_EH_BACKEND}/resources/list/${userLocalData._id}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //   })
  //     .then(resourceResponse => {
  //       setResourceData(resourceResponse.data);

  //       if (resourceResponse.data?.length > 0) {
  //         const moduleResourceObj = {};

  //         resourceResponse.data.map((resource, index) => {
  //           moduleResourceObj[index] = resource.resource._id;
  //           return null;
  //         });
  //         const queryString2 = Object.keys(moduleResourceObj)
  //           .map(key => 'resource_id=' + moduleResourceObj[key])
  //           .join('&');
  //         axios({
  //           method: 'GET',
  //           url: `${REACT_APP_EH_BACKEND}/modules?${queryString2}`,
  //           headers: {
  //             Authorization: 'Bearer ' + localStorage.login_token,
  //           },
  //         })
  //           .then(moduleResponse => {
  //             setModuleData(moduleResponse.data);
  //           })
  //           .catch(loginError => {
  //             return loginError;
  //           });
  //       } else {
  //         setModuleData([]);
  //       }
  //     })
  //     .catch(loginError => {
  //       return loginError;
  //     });
  // }, [userLocalData._id, userData, updatehivedata]);

  useEffect(() => {
    if (resourceData && resourceData.length > 0) {
      setHaveResourceData(true);
    } else {
      setHaveResourceData(false);
    }
  }, [resourceData]);

  //get non summary hive data
  // useEffect(() => {
  //   const { REACT_APP_EH_BACKEND } = process.env;
  //   axios({
  //     method: 'GET',
  //     url: `${REACT_APP_EH_BACKEND}/nonsummaryhive/${userLocalData._id}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //   })
  //     .then(nonSummaryHiveResponse => {
  //       setnonsummaryhiveData(nonSummaryHiveResponse.data);
  //       setLoading(false);
  //     })
  //     .catch(nonSummaryHiveError => {
  //       return nonSummaryHiveError;
  //     });
  // }, [userLocalData._id, updatehivedata]);

  // update boolean if user have nonsummery data
  useEffect(() => {
    if (nonsummaryhiveData && nonsummaryhiveData.length > 0) {
      setHaveNonsummeryData(true);
    } else {
      setHaveNonsummeryData(false);
    }
  }, [nonsummaryhiveData]);

  //get knowledge nugget data
  // useEffect(() => {
  //   const { REACT_APP_EH_BACKEND } = process.env;
  //   axios({
  //     method: 'GET',
  //     url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${userLocalData._id}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //   })
  //     .then(knowledgeNuggetResponse => {
  //       setKnowledgeNuggetData(knowledgeNuggetResponse.data);
  //       setLoading(false);
  //     })
  //     .catch(knowledgeNuggetError => {
  //       return knowledgeNuggetError;
  //     });
  // }, [userLocalData._id, updateKnowledgeNuggetData]);

  // useEffect(() => {
  //   const { REACT_APP_EH_BACKEND } = process.env;
  //   axios({
  //     method: 'GET',
  //     url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/library/${userLocalData._id}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //   })
  //     .then(knowledgeLibraryResponse => {
  //       setKnowledgeLibraryData(knowledgeLibraryResponse.data);
  //       setLoading(false);
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // }, [userLocalData._id, updateKnowledgeNuggetData]);

  useEffect(() => {
    if (
      (knowledgeNuggetData && knowledgeNuggetData.length > 0) ||
      (knowledgeLibraryData && knowledgeLibraryData.length > 0)
    ) {
      setHaveKnowledgeNuggetData(true);
    } else {
      setHaveKnowledgeNuggetData(false);
    }
  }, [knowledgeLibraryData, knowledgeNuggetData]);

  // get projectData
  // useEffect(() => {
  //   const { REACT_APP_EH_BACKEND } = process.env;
  //   axios({
  //     method: 'GET',
  //     url: `${REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //   })
  //     .then(projectResponse => {
  //       setProjectData(projectResponse.data);
  //     })
  //     .catch(projectError => {
  //       return projectError;
  //     });
  // }, [userLocalData._id, upateprojectData]);
  // update boolean if user have project data
  useEffect(() => {
    if (projectData && projectData?.length > 0) {
      setHaveProjectData(true);
    } else {
      setHaveProjectData(false);
    }
  }, [projectData]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {haveResourceData ||
          haveNonsummeryData ||
          haveKnowledgeNuggetData ||
          haveProjectdata ? (
            <ReturningUser
              haveResourceData={haveResourceData}
              haveNonsummeryData={haveNonsummeryData}
              haveKnowledgeNuggetData={haveKnowledgeNuggetData}
              haveProjectdata={haveProjectdata}
              onClaimResource={handleClaimResource}
              knowledgeNuggetData={knowledgeNuggetData}
              knowledgeLibraryData={knowledgeLibraryData}
              resourceData={resourceData}
              moduleData={moduleData}
              nonsummaryhiveData={nonsummaryhiveData}
              updatehivedata={updatehivedata}
              setUpdatehivedata={setUpdatehivedata}
              projectData={projectData}
              upateprojectData={upateprojectData}
              setUpdateprojectData={setUpdateprojectData}
              updateKnowledgeNuggetData={updateKnowledgeNuggetData}
              setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
              storageUsed={storageUsed}
              startTour={startTour}
              userInfo={userInfo}
            />
          ) : (
            <FirstTimeUser
              onClaimResource={handleClaimResource}
              updatehivedata={updatehivedata}
              setUpdatehivedata={setUpdatehivedata}
              storageUsed={storageUsed}
              startTour={startTour}
            />
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
